import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { isCancelError } from '@/modules/api/helpers';
import { checkForShapeCollisions } from '@/modules/common/helpers/boundingBox';
import { useDebouncedCallback } from '@/modules/common/hooks';
import { isAngledHighwayShape, isObstacleShape, isWallShape } from '@/modules/common/types/guards';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { allShapesSelector } from '@/store/recoil/shapes';
import { allObstaclesSelector } from '@/store/recoil/shapes/obstacle';
import { allWallsSelector } from '@/store/recoil/shapes/wall';
import { useFloorPlanService } from '@modules/floorplanService';
import { useGateStore } from '../stores';
import { useLoadingStore } from '../stores/useLoadingStore';

export const useArtefacts = () => {
  const { partialFloorPlanGeneration } = useFloorPlanService();
  const showNotificationFn = useSetRecoilState(showNotification);
  const { t } = useTranslation(['interface', 'common']);

  const initialize = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const shapes = await snapshot.getPromise(allShapesSelector);
        const shapeIds = shapes.map((shape) => shape.id);

        update(shapeIds);
      },
    [partialFloorPlanGeneration],
  );

  const generatedArtefacts = useCallback(
    async (shapeIds: string[], abortController: AbortController) => {
      try {
        return await partialFloorPlanGeneration(
          {
            exportSettings: {
              splines: 'NO_EXPORT',
              unifiedLayout: 'NO_EXPORT',
              collision: false,
              tStackExport: false,
              includeFloorPlanArtefactsInOutput: true,
              floorPlanAdjustment: {
                transformationDeltaX: 0,
                transformationDeltaY: 0,
                transformationAngle: 0,
              },
            },
            abortController,
          },
          shapeIds,
        );
      } catch (e) {
        if (!isCancelError(e)) {
          throw e;
        }
      }
    },
    [partialFloorPlanGeneration],
  );

  const update = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeIds: string[]) => {
        const vehicleShapes = useGateStore.getState().getVehicleShapes(shapeIds)
        const obstalces = useGateStore.getState().getObstacles(shapeIds)     
        const allShapes = await snapshot.getPromise(allShapesSelector)
        const allObstacles = await snapshot.getPromise(allObstaclesSelector)
        const allWalls = await snapshot.getPromise(allWallsSelector)
        
        // does any obstacle in shapeIds collide with any shape outside of shapeIds?
        checkForShapeCollisions(
          [...obstalces], 
          allShapes.filter((item) => !vehicleShapes.has(item) && !isObstacleShape(item) && !isAngledHighwayShape(item) && !isWallShape(item))
        ).forEach(vehicleShapes.add, vehicleShapes)

        if (!vehicleShapes.size) return;

        // does any vehicle shapes in shapeIds collide with any obstacles outside of shapeIds?
        checkForShapeCollisions(
          [...vehicleShapes].filter((item) => !isObstacleShape(item) && !isAngledHighwayShape(item) && !isWallShape(item)), 
          [...allObstacles, ...allWalls].filter((item) => !obstalces.has(item))
        ).forEach(obstalces.add, obstalces)

        const vehicleShapeIds = [...vehicleShapes].map((item) => item.id);
        const obstacleIds = [...obstalces].map(item => item.id)

        const abortController = new AbortController();
        useLoadingStore.getState().startLoading(vehicleShapeIds, abortController);
        try {
          const abortController = new AbortController();
          useLoadingStore.getState().startLoading(vehicleShapeIds, abortController);

          const artefacts = await generatedArtefacts([...vehicleShapeIds, ...obstacleIds], abortController);
          if (artefacts) {
            useGateStore.getState().update([...vehicleShapes, ...obstalces], artefacts);
          }
        } catch (e) {
          useGateStore.getState().updateError([...vehicleShapes]);
          showNotificationFn({
            type: NOTIFICATION_TYPES.ERROR,
            message: t('errors:partial_floorplan_generation.unable_to_generate'),
          });
          console.error(e);
        } finally {
          useLoadingStore.getState().stopLoading(vehicleShapeIds);
        }
      },
    [generatedArtefacts, showNotificationFn, t],
  );

  const updateDebounced = useDebouncedCallback(update, 200);

  const remove = useCallback((shapeIds: string[]) => {
    useLoadingStore.getState().abortLoading(shapeIds);
  }, []);

  const showLoader = useCallback((shapeIds: string[]) => {
    useLoadingStore.getState().showLoader(shapeIds);
  }, []);

  return {
    initialize,
    update,
    updateDebounced,
    remove,
    showLoader,
  };
};
