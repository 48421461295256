import { ExportOutput } from '@components/NavigationBar/ExportOutput';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { ErrorIcon, FileAdd, MinusIcon } from '@/assets/icons';
import { FloorplanServiceOption, Status } from '../enum';
import { useAutomaticValidation } from '../hooks';
import { fpsFilesSelector, fpsWarningsSelector, validationStatus } from '../store';
import { Panel } from './Panel';
import { theme } from '@/modules/common/components/theme';

export const ValidationPanel = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const files = useRecoilValue(fpsFilesSelector);
  const warnings = useRecoilValue(fpsWarningsSelector);
  const status = useRecoilValue(validationStatus);
  const [seconds, setSeconds] = useState(3);
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation(['interface', 'common']);
  const { validate } = useAutomaticValidation();

  const progressStyle = {
    backgroundColor: theme.palette.primary.main,
    height: '5px',
    width: status === Status.WaitingToExecute ? '100%' : '0%',
    transition: status === Status.WaitingToExecute ? '3s' : '0s',
  };

  useEffect(() => {
    if (status === Status.WaitingToExecute) {
      reset();
      startTimer();
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const startTimer = useCallback(() => {
    setSeconds(3);
    setIsActive(true);
  }, []);

  const reset = useCallback(() => {
    setSeconds(3);
    setIsActive(false);
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        let nextValue = seconds - 1;
        if (nextValue < 0) nextValue = 0;
        setSeconds((seconds) => nextValue);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const handlePanelClick = useCallback(() => {
    if (files === null) {
      validate(FloorplanServiceOption.Validate);
    }

    setOpen((prevOpen) => !prevOpen);
  }, [files, validate]);

  const handleClickAway = useCallback((event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }, []);

  const getCurrentIcon = useCallback(() => {
    if (status === Status.WaitingToExecute) return null;
    if (status === Status.WaitingForResponse)
      return <CircularProgress size={15} sx={{ marginRight: '0.25em' }} />;
    if (status === Status.Error) return <ErrorIcon width={24} height={24} />;
    if (files !== null) return <FileAdd />;
    return <MinusIcon />;
  }, [files, status]);

  const getPopperContent = useCallback(() => {
    if (status === Status.WaitingToExecute)
      return (
        <Box>
          <Panel variant='text' content='...' />
        </Box>
      );
    if (status === Status.WaitingForResponse)
      return (
        <Box>
          <Panel variant='loadingAnimation' content='' />
        </Box>
      );
    if (status === Status.Error)
      return (
        <Box>
          <Panel
            variant='text'
            content={t('interface:export.message_error', 'There was a problem generating output')}
          />
        </Box>
      );
    if (files !== null)
      return (
        <Box>
          <ExportOutput output={files} />
        </Box>
      );
    return (
      <Box>
        <Panel variant='text' content='No files available' />
      </Box>
    );
  }, [files, status, t]);

  const getLabel = useCallback(() => {
    if (status === Status.WaitingToExecute)
      return t(`interface:export.validation_panel_label.waiting_to_execute`, {
        seconds: Math.ceil(seconds),
      });
    if (status === Status.WaitingForResponse)
      return t(`interface:export.validation_panel_label.waiting_for_response`);
    if (status === Status.Error) return t(`interface:export.validation_panel_label.desgin_failed`);
    if (files !== null && warnings.length > 0)
      return t(`interface:export.validation_panel_label.desgin_failed`);
    if (files !== null) return t(`interface:export.validation_panel_label.desgin_passed`);
    return t('common:validation', 'Validation');
  }, [files, seconds, status, t, warnings.length]);

  return (
    <Stack ref={anchorRef} sx={{ width: '200px' }}>
      <Button
        aria-label='Validation'
        variant='text'
        onClick={handlePanelClick}
        startIcon={getCurrentIcon()}
        sx={{
          border: 0, color: theme.palette.neutral.dark,
          '&:hover': {
            backgroundColor: theme.palette.shades.light,
            color: theme.palette.neutral.darker
          }
        }}
      >
        {getLabel()}
      </Button>
      <div style={{ backgroundColor: theme.palette.neutral.lighter }}>
        <div style={progressStyle} />
      </div>
      <Popper
        sx={{
          zIndex: 1,
          top: '6px'
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper style={{ maxHeight: 500, overflow: 'auto' }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                {getPopperContent()}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};
