import { Group as GroupElement } from 'konva/lib/Group';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { domReferencesAtom, WorkspaceDomReferences } from '../atom';

export const processLayerRefSelector = selector<GroupElement>({
  key: 'processLayerRefSelector',
  get: ({ get }) => get<WorkspaceDomReferences>(domReferencesAtom).processLayer,
  set: ({ get, set }, newValue: GroupElement) => {
    set(domReferencesAtom, { ...get(domReferencesAtom), processLayer: newValue });
  },
  dangerouslyAllowMutability: true,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
