import { AngledHighwayShape } from '@modules/angledHighways/types';
import { SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import shapeAtom from '@recoil/shape/atom';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { AngledHighwayProxy } from './AngledHighwayProxy';
import { AngledHighwayRenderer } from './AngledHighwayRenderer';

type AngledHighwayProps = {
  id: string;
  saveFloorPlan: () => Promise<void>;
};

const AngledHighwayComponent = ({ id, saveFloorPlan }: AngledHighwayProps) => {
  const highway = useRecoilValue(shapeAtom(id)) as AngledHighwayShape;

  if (!highway.id) return null;

  return (
    <>
      <AngledHighwayRenderer
        points={highway.properties.controlPoints}
        width={highway.parameters.width * SHAPE_TO_CANVAS_SCALE}
        gap={highway.parameters.gap * SHAPE_TO_CANVAS_SCALE}
        margin={highway.parameters.margin * SHAPE_TO_CANVAS_SCALE}
      />
      <AngledHighwayProxy
        id={id}
        points={highway.properties.controlPoints}
        width={highway.parameters.width}
        laneDirection={highway.parameters.laneDirection}
        saveFloorPlan={saveFloorPlan}
      />
    </>
  );
};

export const AngledHighway = memo(AngledHighwayComponent);
