import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { Project, projectAtom } from './atom';

export const projectState = selector<Project>({
  key: 'project/state',
  get: ({ get }) => get(projectAtom),
  set: ({ set }, value: Project) => set(projectAtom, value),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
