import { useRef } from 'react';
import { useRecoilCallback } from 'recoil';
import { Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import { getOrderedControlPoints } from '@/modules/common/helpers/shapes';
import { useNewShape } from '@/modules/common/hooks/useNewShape';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { processTwoEndPointIdsState } from '@/modules/processTwoEndPoint/store';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { WallShape } from '@/store/recoil/shape';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { wallIdsSelector } from '@/store/recoil/shapes/wall';
import { drawingIdSelector } from '@/store/recoil/workspace';
import {
  angledHighwayIdsState
} from '@modules/angledHighways/store';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { ControlPoint, ShapeType } from '@modules/common/types/shapes';
import { useAutoSave } from '@modules/floorplan';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';
import shapeAtom from '@recoil/shape/atom';

export const usePointsDrawing = () => {
  const { newShape } = useNewShape();
  const { save } = useAutoSave();
  const idRef = useRef(null);

  const updateInterimEndPointPos = useRecoilCallback(
    () =>
      (pos: Vector2) => {
        useWorkspaceStore.getState().setInterimEndPointPos(pos)
      },
    [],
  );

  const getWidth = (type: ShapeType) => {
    switch (type) {
      case ShapeType.WALL:
        return getUserPreference(UserPreferenceName.BUILDING_WALL_THICKNESS)
      case ShapeType.PROCESS_TWO_EP:
        return getUserPreference(UserPreferenceName.PROCESS_WIDTH)
      default:
        return getUserPreference(UserPreferenceName.HIGHWAY_WIDTH)
    }
  }

  const initDrawing = useRecoilCallback(
    ({ set, reset }) =>
      async (startPos: Vector2, type: (ShapeType.WALL | ShapeType.HIGHWAY_ANGLED | ShapeType.PROCESS_TWO_EP)) => {
        // reset current shape in edit mode if any
        useWorkspaceStore.getState().reset()
        reset(selectedShapesIdsState);

        const interimStartControlPoint: ControlPoint = {
          id: uuid(),
          prev: null,
          next: null,
          position: startPos,
        };

        idRef.current = uuid();

        set(drawingIdSelector, idRef.current)

        // initialize shape drawing in store
        useWorkspaceStore.getState().setActivePointsDrawingState({
          id: idRef.current,
          type,
          controlPoints: [interimStartControlPoint],
          interimStartControlPoint,
          interimEndPointPos: null,
          width: getWidth(type)
        });
      },
    [],
  );

  const persistDrawing = useRecoilCallback(
    ({ snapshot, set }) =>
      async (secondControlPointPos: Vector2) => {
        const drawingState = useWorkspaceStore.getState().activePointsDrawingState;

        // create new (second) control point
        const secondControlPointId = uuid();
        const secondControlPoint: ControlPoint = {
          id: secondControlPointId,
          next: null,
          prev: drawingState.interimStartControlPoint.id,
          position: secondControlPointPos,
        };

        // create highway based on the first 2 drawn control points
        const controlPoints = [
          { ...drawingState.interimStartControlPoint, next: secondControlPointId },
          secondControlPoint,
        ];

        const shape = (await newShape(
          drawingState.type,
          false,
        )) as (WallShape | AngledHighwayShape | ProcessTwoEPShape);

        set(shapeAtom(shape.id), {
          ...shape,
          properties: {
            ...shape.properties,
            controlPoints,
          },
        });

        if (drawingState.type === ShapeType.HIGHWAY_ANGLED)
          set(angledHighwayIdsState, (prev) => [...new Set([...prev, shape.id])]);
        else if (drawingState.type === ShapeType.WALL)
          set(wallIdsSelector, (prev) => [...new Set([...prev, shape.id])]);
        else
          set(processTwoEndPointIdsState, (prev) => [...new Set([...prev, shape.id])]);

        // update drawing state
        useWorkspaceStore.getState().setControlPoints(controlPoints);
        save();
      },
    [save],
  );

  const appendControlPoint = useRecoilCallback(
    ({ snapshot, set }) =>
      async (newControlPointPos: Vector2) => {
        const drawingState = useWorkspaceStore.getState().activePointsDrawingState;
        const newOrderedControlPoints = getOrderedControlPoints(drawingState.controlPoints);
        const newControlPointId = uuid();

        // copy current tail
        const currentTail = { ...newOrderedControlPoints[newOrderedControlPoints.length - 1] };

        // patch current tail's copy to point to new tail
        currentTail.next = newControlPointId;
        newOrderedControlPoints[newOrderedControlPoints.length - 1] = currentTail;

        // build new tail
        const newControlPoint: ControlPoint = {
          id: newControlPointId,
          prev: currentTail.id,
          next: null,
          position: newControlPointPos,
        };

        // and finally add new tail
        newOrderedControlPoints.push(newControlPoint);

        // update established highway
        set(shapeAtom(drawingState.id), (current: (WallShape | AngledHighwayShape | ProcessTwoEPShape)) => {
          const updatedItem: (WallShape | AngledHighwayShape | ProcessTwoEPShape) = {
            ...current,
            properties: {
              ...current.properties,
              controlPoints: newOrderedControlPoints,
            },
          };

          return updatedItem;
        });

        // update drawing state
        useWorkspaceStore.getState().setControlPoints(newOrderedControlPoints)

        save();
      },
    [save],
  );

  // TODO: note. This is waiting for event handler refactoring into seperate component per tool. Haviong only 1 mounted at the same time
  // // stop drawing when hook unmounts
  // useEffect(
  //   () => () => {
  //     stopDrawing();
  //   },
  //   [stopDrawing],
  // );

  return {
    updateInterimEndPointPos,
    initDrawing,
    persistDrawing,
    appendControlPoint,
  };
};
