import { addVehicleArea } from '@/modules/floorplanService/helpers/mapping/addVehicleArea';
import { boundingBoxToFpsRectangle } from '@/modules/floorplanService/helpers/mapping/utils';
import { addCheckPointGenerationSettings } from './addCheckPointGenerationSettings';
import { addCheckPointIdGenerators } from './addIdGenerators';
import { encodeIdWithVehicleId } from './idEncoder';

export const createHighway = (
  vehicleSpec,
  checkPointIdGenerators,
  shapeName,
  id,
  type,
  laneDirection,
  margin,
  gap,
  routingPointGroupMinGap,
  routingPointMarginToCrossing,
  properties,
  workspaceBoundingBox
) => {
  const shapeId = encodeIdWithVehicleId(id, vehicleSpec.databaseId)
  const name = encodeIdWithVehicleId(shapeName, vehicleSpec.databaseId)
  const checkPointIdGenerator = `${name}-CpIdGen`;

  const rectangle = boundingBoxToFpsRectangle(properties, properties.r, workspaceBoundingBox);
  addVehicleArea(vehicleSpec, shapeId, type, rectangle);

  addCheckPointGenerationSettings(
    vehicleSpec,
    shapeId,
    checkPointIdGenerator,
    gap,
    margin,
    routingPointGroupMinGap,
    routingPointMarginToCrossing,
    laneDirection,
    rectangle.length,
    rectangle.width,
    rectangle.angleInDegrees,
  );
  addCheckPointIdGenerators(checkPointIdGenerators, checkPointIdGenerator, name);
};
