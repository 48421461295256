import { Vector2, Vector3 } from 'three';

import { BoundingBox } from '@/helpers/types';
import { convertPointToBottomLeftPosition } from '@/modules/artefacts/helpers/convert';
import { LineSegment } from '@modules/angledHighways/types';
import { Connection, ConnectionBase } from '@modules/common/types/connections';
import { getShapeId } from '@modules/connections/common/connectionId';
import { Polygon } from './types';

/**
 * Sorts connections based on their ids
 */
export const sortConnections = <T extends ConnectionBase>(connections: T[]) =>
  connections.sort((c1, c2) => (c1.id > c2.id ? -1 : 1));

/**
 * Splits connections into related and unrelated to the given collection of shape ids
 */
export const getRelatedAndUnrelatedConnectionIds = <T extends ConnectionBase>(
  connections: T[],
  shapeIds: string[],
) => {
  const relatedConnections: T[] = [];
  const unrelatedConnections: T[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const connection of connections) {
    if (
      shapeIds.some(
        (shapeId) => connection.from.startsWith(shapeId) || connection.to.startsWith(shapeId),
      ) // use startsWith instead of === because angledhighway connection has suffix for connected segment
    ) {
      relatedConnections.push(connection);
    } else {
      unrelatedConnections.push(connection);
    }
  }

  return {
    relatedConnections,
    unrelatedConnections,
  };
};

/**
 * Filters out unrelated connections
 */
export const filterRelatedConnections = <T extends ConnectionBase>(
  connections: T[],
  shapeIds: string[],
) => {
  const ids = new Set(shapeIds);
  return connections.filter(
    (item) => ids.has(getShapeId(item.from)) || ids.has(getShapeId(item.to)),
  );
};

/**
 * Finds a related connection
 */
export const findRelatedConnection = <T extends ConnectionBase>(
  connections: T[],
  fromId: string,
  toId: string,
) =>
  connections.find(
    (item) =>
      (item.from === fromId && item.to === toId) || (item.to === fromId && item.from === toId),
  );

export const lineSegmentIntersect = (
  line1: LineSegment,
  line2: LineSegment,
  allowOutsideIntersection = false,
): Vector2 | undefined =>
  lineIntersect(
    line1.start.x,
    line1.start.y,
    line1.end.x,
    line1.end.y,
    line2.start.x,
    line2.start.y,
    line2.end.x,
    line2.end.y,
    allowOutsideIntersection,
  );

/**
 * Implementation taken from https://stackoverflow.com/questions/13937782/calculating-the-point-of-intersection-of-two-lines
 */
const lineIntersect = (
  x1,
  y1,
  x2,
  y2,
  x3,
  y3,
  x4,
  y4,
  allowOutsideIntersection = false,
): Vector2 | undefined => {
  const denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  if (denom == 0) {
    return undefined;
  }
  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;

  if (allowOutsideIntersection || (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1))
    return new Vector2(x1 + ua * (x2 - x1), y1 + ua * (y2 - y1));

  return undefined;
};

export const findClosestSegmentToPoint = (object: Polygon, point: Vector2): LineSegment => {
  let minDistance = Infinity;
  let closestSegment: LineSegment;
  const p = new Vector3(point.x, point.y);

  for (let i = 0; i < object.length - 1; i++) {
    const p0 = new Vector3(object[i].x, object[i].y);
    const p1 = new Vector3(object[i + 1].x, object[i + 1].y);
    const p1_p0 = p1.clone().sub(p0);
    const p_p0 = p.clone().sub(p0);
    const distance = p1_p0.cross(p_p0).length();
    if (distance < minDistance) {
      minDistance = distance;
      closestSegment = { start: object[i], end: object[i + 1] };
    }
  }
  return closestSegment;
};

export const midpoint = (p1: Vector2, p2: Vector2) =>
  new Vector2((p1.x + p2.x) / 2, (p1.y + p2.y) / 2);

export const connectionPointBetweenPolygons = (
  objectA: Polygon,
  objectB: Polygon,
): Vector2 | null => {
  const intersections = [];
  for (let i = 0; i < objectA.length - 1; i++) {
    for (let j = 0; j < objectB.length - 1; j++) {
      const line1: LineSegment = { start: objectA[i], end: objectA[i + 1] };
      const line2: LineSegment = { start: objectB[j], end: objectB[j + 1] };
      const point = lineSegmentIntersect(line1, line2);
      if (point) {
        intersections.push(point);
      }
    }
  }

  if (!intersections.length) {
    return null;
  }

  // average intersection point
  const min = new Vector2(Infinity, Infinity);
  const max = new Vector2(-Infinity, -Infinity);
  intersections.forEach((inter) => {
    min.x = Math.min(min.x, inter.x);
    min.y = Math.min(min.y, inter.y);
    max.x = Math.max(max.x, inter.x);
    max.y = Math.max(max.y, inter.y);
  });

  return new Vector2().addVectors(max, min).multiplyScalar(0.5);
};

export const convertToBottomLeftCoordinateSystem = (connections: readonly Connection[], boundingBox: BoundingBox) => connections.map((item) => toBottomLeftOrigin(item, boundingBox));

const toBottomLeftOrigin = (connection: Connection, workspaceBoundingBox: BoundingBox): Connection => {
  const pos = convertPointToBottomLeftPosition(connection.position, workspaceBoundingBox);
  return {
    ...connection,
    position: new Vector2(pos.x, pos.y)
  }
};
