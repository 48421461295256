import { ShapeType } from '@modules/common/types/shapes';
import { ToolState } from '@modules/common/types/tools';
import { atom } from 'recoil';

export type ToolAtom = ToolState | ShapeType | 'cursor' | 'scale' | 'dwg';

const toolAtom = atom<ToolAtom>({
  key: 'tool',
  default: null,
});

export default toolAtom;
