import { t } from 'i18next';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { isCancelError } from '@/modules/api/helpers';
import { API_DEBOUNCE_MS } from '@modules/floorplanService/constants';
import { FloorplanServiceOption, Status } from '@modules/floorplanService/enum';
import { createExportSettings } from '@modules/floorplanService/helpers/floorplanServiceHelpers';
import { fpsFilesSelector, fpsWarningsSelector, validationStatus } from '@modules/floorplanService/store';
import { FileGenerationOutput } from '@modules/floorplanService/types';
import { useIsFloorplanServiceRequirementsMet } from '@modules/floorplanValidation/clientSide/hooks';
import { NOTIFICATION_TYPES, showNotification } from '@recoil/notification';
import { useFloorPlanService } from './useFloorplanService';

export function useAutomaticValidation() {
  const [abortController, setAbortController] = useState<AbortController>();
  const { generateOutputFiles } = useFloorPlanService();
  const { isFloorplanServiceRequirementsMet } = useIsFloorplanServiceRequirementsMet();

  const callFloorplanService = useRecoilCallback(
    ({snapshot, set}) => 
      async (option: FloorplanServiceOption) => {
        const status = await snapshot.getPromise(validationStatus)
        if (status === Status.WaitingForResponse) {
          return;
        }
        set(validationStatus, Status.WaitingForResponse)
        abortController?.abort();

        const exportSettings = createExportSettings(option)
        if (exportSettings == null) return 

        const controller = new AbortController();
        setAbortController(controller);

        await generateOutputFiles({ exportSettings, abortController: controller })
        .then(async (output: FileGenerationOutput) => {
          set(validationStatus, Status.Done)
          set(fpsWarningsSelector, output.warnings)
          set(fpsFilesSelector, {files: output.files, zipFile: output.zipFile, outputUri: output.outputUri})
        })
        .catch((e) => {
          if (!isCancelError(e)) {
            set(validationStatus, Status.Error)
            console.error(e);
            console.error(e instanceof Error ? e.cause : '');
          }
        });
      },
    [abortController, generateOutputFiles],
  );

  const validate = useRecoilCallback(({set}) => 
    async (option: FloorplanServiceOption): Promise<void> => {
      const valid = await isFloorplanServiceRequirementsMet();
      if (!valid) {
        console.log('Floorplan not valid for FPS');
        set(showNotification, {
          type: NOTIFICATION_TYPES.WARNING,
          message: t(
            'errors:validation.missing_load_positions',
            'At least one Intake, Delivery or Storage area is requiered',
          ),
        })
        return;
      }
      set(validationStatus, Status.Idle)
      set(validationStatus, Status.WaitingToExecute)
      
      return new Promise((resolve) => {
        setTimeout(async () => {
          await callFloorplanService(option);
          resolve();
        }, API_DEBOUNCE_MS);
      });
    },
    [callFloorplanService, isFloorplanServiceRequirementsMet],
  );

  return {
    validate,
  };
}
