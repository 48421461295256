import { isAngledHighwayShape, isHighwayShape } from '@/modules/common/types/guards';
import { Crossing } from '@modules/common/types/connections';
import { AdditionalData } from '@modules/common/types/floorPlan';
import { DTShape } from '@recoil/shape';
import { crossingPositionsToCrossings, findCrossingPositions } from './crossings';

/**
 * Prepares crossings state to be loaded into the app
 */
export const prepareToLoad = (additionalData: AdditionalData): Crossing[] => {
  const shapes = additionalData.shapes.map(
    (shape): DTShape => ({
      ...shape,
      isDrawing: false,
      isTransforming: false,
      isLoading: false,
      disabled: false,
    }),
  );

  const crossings: Crossing[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < shapes.length; ++i) {
    const fromShape = shapes[i];
    if ((!isHighwayShape(fromShape) && !isAngledHighwayShape(fromShape)) || !fromShape?.properties)
      continue;
    for (let j = i + 1; j < shapes.length; ++j) {
      const toShape = shapes[j];
      if ((!isHighwayShape(toShape) && !isAngledHighwayShape(toShape)) || !toShape?.properties)
        continue;
      const computedCrossings = findCrossingPositions(fromShape, toShape);
      crossings.push(...crossingPositionsToCrossings(computedCrossings));
    }
  }
  return crossings;
};
