import { Divider, MenuItem, Stack, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, ForkliftIcon } from '@/assets/icons';
import { LoopOutlined, EditOutlined, ViewInArOutlined } from '@mui/icons-material';
import { moreMenuTheme } from '@/modules/common/components/theme';

import { DashboardMenuItem } from './DashboardMenuItem';
import { Simulation, SimulationStatus } from '../helpers/types';
import { Menu } from './Menu';

type Props = {
  onViewClick: () => void;
  simulation: Simulation;
  onEditClick?: () => void;
  onRerunClick?: () => void;
  onDeleteClick?: () => void;
};

/**
 * Top buttons for single simulations type
 */
export const TopButtons = ({
  simulation,
  onEditClick,
  onRerunClick,
  onViewClick,
  onDeleteClick,
}: Props) => {
  const { t } = useTranslation('interface');
  const showViewBtn =
    simulation.status === SimulationStatus.COMPLETED ||
    (simulation.status === SimulationStatus.FAILED && simulation.simulationRunId);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'flex-end',
        gap: 1,
      }}
    >
      <ThemeProvider theme={moreMenuTheme}>
        <Menu>
          {showViewBtn && (
            <div>
              <MenuItem disabled>
                <ForkliftIcon />
                {t('simulation.run_card.commissioning.label')}
              </MenuItem>
              <MenuItem onClick={onViewClick}>
                <ViewInArOutlined />
                {t('simulation.run_card.view_button.label')}
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
            </div>
          )}
          <DashboardMenuItem simulation={simulation} />
          {(onRerunClick || onEditClick) && <Divider sx={{ my: 0.5 }} />}
          {onRerunClick && (
            <MenuItem
              onClick={onRerunClick}
              disabled={
                simulation.status !== SimulationStatus.COMPLETED &&
                simulation.status !== SimulationStatus.PARTIALLY_COMPLETED
              }
            >
              <LoopOutlined />
              {t('simulation.run_card.rerun_button.label')}
            </MenuItem>
          )}
          {onEditClick && (
            <MenuItem
              onClick={onEditClick}
              disabled={simulation.status === SimulationStatus.DELETING}
            >
              <EditOutlined />
              {t('simulation.run_card.edit_button.label')}
            </MenuItem>
          )}
          {onDeleteClick && (
            <MenuItem
              onClick={onDeleteClick}
              disabled={simulation.status == SimulationStatus.DELETING}
            >
              <DeleteIcon />
              {t('simulation.run_card.delete_button.tooltip')}
            </MenuItem>
          )}
        </Menu>
      </ThemeProvider>
    </Stack>
  );
};
