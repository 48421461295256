import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import { areLinesParallel } from '@/modules/workspace/helpers/shape';
import {
  isAngledHighwayShape,
  isAreaShape,
  isHighwayShape,
  isPositionShape,
  isProcessAreaTwoEp,
  isWallShape,
} from '@modules/common/types/guards';
import { AreaParkingDirection, DTShape } from '@modules/common/types/shapes';
import { AddAreaMappingFromAreaToHighway } from './addAreaMappingFromAreaToHighway';
import { addAreaMappingFromHighwayToOtherShapes } from './addAreaMappingFromHighwayToOtherShapes';
import { decodeShapeId, encodeIdWithVehicleId, getSegmentIndex } from './idEncoder';
import { AreaMapperTypeEnum, ConnectionDirection, Mapping } from './types';
import {
  areHighwaysLaneDirectionsAligned,
  areHighwaysNextToEachOther,
  getAngledHighwaySegment,
  isGetawayConnection,
} from './utils';

export const DistantConnectionMapping = (
  vehicleSpec,
  highwayId,
  highwayConnections,
  highwayLine,
  laneDirection,
  connections,
  shapes: DTShape[],
) => {
  const mappings = []

  highwayConnections.forEach((connection) => {
    const otherShape = shapes.filter(
      (shape) => decodeShapeId(shape.id) === decodeShapeId(connection.targetName),
    );
    const targetId = encodeIdWithVehicleId(connection.targetName, vehicleSpec.databaseId);

    if (vehicleSpec.areas.some((area) => area.name === targetId) && !isWallShape(otherShape[0])) {

      // TODO: process two ep. connection support
      if (isProcessAreaTwoEp(otherShape[0])) return null;

      const otherHighwayLine = isAngledHighwayShape(otherShape[0])
        ? getAngledHighwaySegment(otherShape[0], getSegmentIndex(connection.targetName).toString())
        : boundingBoxToLine(otherShape[0].properties);

      let connectionRotation = connection.rot;
      if (connectionRotation > 180) connectionRotation = 360 - connectionRotation;
      if (connectionRotation === 0) connectionRotation = 180;

      if (isAreaShape(otherShape[0]) || isPositionShape(otherShape[0])) {
        const getaway = isGetawayConnection(
          otherShape[0].parameters.direction,
          highwayLine,
          otherShape[0].id,
          connections,
        );
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          if (isAreaShape(otherShape[0])) {
            mappings.push({
              discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS,
              areaReferencesToMap: [{ name: targetId }],
              epBackwardParking: otherShape[0].parameters.parkingDirection === AreaParkingDirection.BACKWARD,
              usePivots: connection.usePivots,
            });
          }
        }
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromSource
        ) {
          AddAreaMappingFromAreaToHighway(
            vehicleSpec.gateMappingSettings.areaMappings,
            targetId,
            highwayId,
            getaway,
            connection.usePivots,
          );
        }
      } else if (
        (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) &&
        areHighwaysNextToEachOther(highwayLine, otherHighwayLine.points, connectionRotation)
      ) {
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_ALL_NEAREST_CHECK_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            usePivots: connection.usePivots,
            isUTurn: !areHighwaysLaneDirectionsAligned(laneDirection, otherShape[0].parameters.laneDirection)
          });
        }
      } else if (
        (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) &&
        areLinesParallel(highwayLine, otherHighwayLine.points)
      ) {
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_TWO_NEAREST_CHECK_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            isUTurn: false,
            usePivots: connection.usePivots,
          })
        }
      } else if (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) {
        mappings.push({ 
          discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING,
          areaReferencesToMap: [{ name: targetId }],
        })
      }
    }
  });

  const groupedMappings = Object.values(mappings.reduce<Record<string, Mapping>>((r, e) => {
    const key = `${e.discType}|${e.epBackwardParking}|${e.isUTurn}|${e.usePivots}`;
    if (!r[key]) { 
      r[key] = e
    }
    else {
      r[key].areaReferencesToMap.push(e.areaReferencesToMap[0]);
    }
    return r;
  }, {}))
  
  groupedMappings.forEach(group => {
    addAreaMappingFromHighwayToOtherShapes(
      vehicleSpec.gateMappingSettings.areaMappings,
      highwayId,
      (group.discType === AreaMapperTypeEnum.MAP_ALL_NEAREST_CHECK_POINTS) ? group : null,
      (group.discType === AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING) ? group : null,
      (group.discType === AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS) ? group : null,
      (group.discType === AreaMapperTypeEnum.MAP_TWO_NEAREST_CHECK_POINTS) ? group : null,
      null,
    );
  });
};
