import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { drawingIdSelector } from '@/store/recoil/workspace';
import { angledHighwayIdsState } from '@modules/angledHighways/store';
import { invisibleElementsSelector } from '@modules/layers';
import { AngledHighway } from './AngledHighway';

type Props = {
  saveFloorPlan: () => Promise<void>;
};

const AngledHighwaysComponent = ({ saveFloorPlan }: Props) => {
  const angledHighwayIds = useRecoilValue(angledHighwayIdsState);
  const drawingId = useRecoilValue(drawingIdSelector);
  const invisibleObjects = useRecoilValue(invisibleElementsSelector);

  return (
    <>
      {angledHighwayIds.length > 0 &&
        angledHighwayIds
          .filter((id) => id !== drawingId && !invisibleObjects.has(id))
          .map((id) => <AngledHighway key={id} id={id} saveFloorPlan={saveFloorPlan} />)}
    </>
  );
};

export const AngledHighways = memo(AngledHighwaysComponent);
