import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

import { projectAtom } from './atom';

export const nameSelector = selector({
  key: 'project/name',
  get: ({ get }) => get(projectAtom).name,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
