import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { processWidthState } from '@/components/PropertiesPanel/store/process';
import { PROCESS_MIN_WIDTH } from '@/modules/common/constants/shapes';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { unitConverterSelector, unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useConnections } from '@modules/connections';
import { useAutoSave } from '@modules/floorplan';
import { UserPreferenceName } from '@modules/userPreferences';
import { CONTEXT, contextState } from '@recoil/input';
import SelectedShapesIdsState from '@recoil/shapes/selected/selectedShapesIdsState';

function ProcessWidth() {
  const { updateConnections } = useConnections();
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation('interface');
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { save } = useAutoSave();
  const [width, setWidth] = useRecoilState(processWidthState);

  const onChange = useRecoilCallback(
    ({ snapshot }) =>
      async (value: number) => {
        try {
          const valueInMillimeter = await snapshot.getPromise(
            unitConverterSelector(value as number),
          );
          if (valueInMillimeter < PROCESS_MIN_WIDTH) {
            throw new Error(`The minimum width for this shape type is ${PROCESS_MIN_WIDTH}`);
          }
          setWidth(value);
          await updateConnections(await snapshot.getPromise(SelectedShapesIdsState));
          save();
          updateUserPreference(UserPreferenceName.PROCESS_WIDTH, value);
        } catch (e) {
          return { error: e.message };
        }
      },
    [setWidth, updateUserPreference, save, updateConnections],
  );

  return (
    <NumberInput
      value={useRecoilValue(unitValueSelector(width))}
      unit={t(`interface:settings.units.${unit}`, unit)}
      sx={{ width: '100%' }}
      disabled={mode !== WorkspaceMode.EDITABLE}
      onChange={onChange}
      onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
    />
  );
}

export default ProcessWidth;
