import { isProcessAreaOneEp } from '@/modules/common/types/guards';
import { getProcessIdsWithUnequalInboundOutboundFlows } from "@/modules/floorplanValidation/clientSide/helpers";
import { layoutFlowsSelector } from "@/modules/flows/store/layout";
import { RECOIL_SELECTOR_CACHE_POLICY } from "@/store/recoil/common";
import { allShapesSelector } from "@/store/recoil/shapes";
import { selector } from "recoil";

export const unequalProcessFlowsSelector = selector<string[]>({
  key: 'prevalidation/unequalProcessFlows',
  get: ({ get }) => {
    const processAreas = get(allShapesSelector).filter(isProcessAreaOneEp);
    const layoutFlows = get(layoutFlowsSelector);
    return getProcessIdsWithUnequalInboundOutboundFlows(processAreas, layoutFlows);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
