import { retreiveCPIdFromCPAnchorId } from '@modules/angledHighways';
import { ElementName, findParentNode, isRightClick } from '@modules/workspace/helpers/konva';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { relatedControlPointIdState, relatedShapeIdsState } from '@recoil/workspace/contextMenu';
import { relatedComponentIdsState } from '@recoil/workspace/contextMenu/state/relatedComponentIdsState';
import Konva from 'konva';
import { useRecoilCallback } from 'recoil';
import { useContextMenu } from './useContextMenu';

export const useContextMenuCallbacks = () => {
  const { setPosition } = useContextMenu();

  const stageContextMenuHandler = useRecoilCallback(
    ({ set, snapshot }) =>
      async (e: Konva.KonvaEventObject<PointerEvent>) => {
        // prevent default konva context menu
        e.evt.preventDefault();

        if (isRightClick(e)) {
          const shapeIdUnderMouse = e.target.id();
          const shapeName = e.target.name();
          const selectedShapesIds = await snapshot.getPromise(selectedShapesIdsState);

          // context related shapes (areas, positions, highways, etc). give prio to current selection, else use underlying shape if any
          if (selectedShapesIds.length > 0) {
            set(relatedShapeIdsState, selectedShapesIds);
          } else if (
            [ElementName.SHAPE_PROXY, ElementName.POINTS_SHAPE_PROXY_LINE].some(
              (name) => name === shapeName,
            )
          ) {
            set(relatedShapeIdsState, [shapeIdUnderMouse]);
          }

          if (shapeName === ElementName.HIGHWAY_CONTROL_POINT) {
            // set related highway control point
            set(relatedControlPointIdState, retreiveCPIdFromCPAnchorId(shapeIdUnderMouse));
          } else {
            // set related connection if any
            const parentNode = findParentNode(e.target, ElementName.CONTEXT_NODE_NAME);
            if (parentNode) {
              const id = parentNode.id();
              if (id) set(relatedComponentIdsState, [id]);
            }
          }

          setPosition({
            x: e.evt.clientX,
            y: e.evt.clientY,
          });
        }
      },
    [],
  );

  return {
    stageContextMenuHandler,
  };
};
