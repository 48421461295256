import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { useNavigation } from '@/modules/common/hooks';
import { useFloorPlanGroupApi, useProjectApi } from '@modules/api/hooks';
import { useLoadProject } from '@/modules/floorplan';

import { floorPlanGroupSelector, floorPlanGroupIdsSelector } from '../store/floorPlanGroup';
import { isProjectLoadingState } from '../store';
import { useNotificationsContext, NotificationsContext } from '../../notifications';

export const useFloorplanAdmin = () => {
  const { projectId } = useParams();
  const { fetchAll: fetchAllGroups } = useFloorPlanGroupApi();
  const { fetch: fetchProject } = useProjectApi();
  const [isProjectLoading, setProjectIsLoading] = useRecoilState(isProjectLoadingState);
  const { load: loadProject } = useLoadProject();
  const { goToPortal } = useNavigation();
  useNotificationsContext(NotificationsContext.FLOORPLAN_ADMIN);

  const getFloorPlans = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const [project, floorPlanGroups] = await Promise.all([
            fetchProject(projectId),
            fetchAllGroups(projectId),
          ]);

          loadProject(project);

          floorPlanGroups.forEach((item) => set(floorPlanGroupSelector(item.id), item));
          set(
            floorPlanGroupIdsSelector,
            floorPlanGroups.map((item) => item.id),
          );
        } catch (e) {
          console.error(e);
          goToPortal(true);
        }

        setProjectIsLoading(false);
      },
    [projectId, fetchAllGroups, fetchProject, setProjectIsLoading, loadProject, goToPortal],
  );

  useEffect(() => {
    getFloorPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFloorPlans]);

  return {
    isProjectLoading,
  };
};
