import { useEffect, useState } from 'react';

export const useImageRef = (referenceImage) => {
  const [imgRef, setImgRef] = useState<CanvasImageSource | undefined>(undefined);

  useEffect(() => {
    if (referenceImage.file !== null) {
      const imageObj = new Image();
      imageObj.onload = () => {
        setImgRef(imageObj);
      };
      imageObj.src = referenceImage.file;
    } else {
      setImgRef(undefined);
    }
  }, [referenceImage]);

  return {
    imgRef,
  };
};
