import { isArea, isPosition } from '@modules/common/helpers/shapes';
import { ShapeType } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selectedShapesState } from '@recoil/shapes/selected';
import { selector } from 'recoil';

const hasOnlyAreas = (shapes: any[]) => shapes.every((shape) => isArea(shape.type));
const hasOnlyHighways = (shapes: any[]) => shapes.every((shape) => shape.type === ShapeType.HIGHWAY);
const hasOnlyAngledHighways = (shapes: any[]) => shapes.every((shape) => shape.type === ShapeType.HIGHWAY_ANGLED);
const hasOnlyProcessTwoEp = (shapes: any[]) => shapes.every((shape) => shape.type === ShapeType.PROCESS_TWO_EP);
const hasOnlyWalls = (shapes: any[]) => shapes.every((shape) => shape.type === ShapeType.WALL);
const hasOnlyObstacles = (shapes: any[]) =>
  shapes.every((shape) => shape.type === ShapeType.OBSTACLE);
const hasOnlyPositions = (shapes: any[]) => shapes.every((shape) => isPosition(shape.type));
export const hasSomePositions = (shapes: any[]) => shapes.some((shape) => isPosition(shape.type));

export enum PanelDisplayMode {
  AREA = 0,
  HIGHWAYS = 1,
  WALLS = 2,
  OBSTACLES = 3,
  ELEMENTS = 4,
  NONE = 5,
  POSITIONS = 6,
  ANGLED_HIGHWAYS = 7,
  PROCESS_TWO_EP = 8,
}

export const panelModeState = selector<PanelDisplayMode>({
  key: 'propertiesPanelMode',
  get: ({ get }) => {
    const shapes = get(selectedShapesState);

    if (!shapes.length) {
      return PanelDisplayMode.NONE;
    }

    if (hasOnlyAreas(shapes)) {
      return PanelDisplayMode.AREA;
    }

    if (hasOnlyHighways(shapes)) {
      return PanelDisplayMode.HIGHWAYS;
    }

    if (hasOnlyWalls(shapes)) {
      return PanelDisplayMode.WALLS;
    }

    if (hasOnlyObstacles(shapes)) {
      return PanelDisplayMode.OBSTACLES;
    }

    if (hasOnlyPositions(shapes)) {
      return PanelDisplayMode.POSITIONS;
    }

    if (hasOnlyAngledHighways(shapes)) {
      return PanelDisplayMode.ANGLED_HIGHWAYS;
    }

    if (hasOnlyProcessTwoEp(shapes)) {
      return PanelDisplayMode.PROCESS_TWO_EP;
    }

    return PanelDisplayMode.ELEMENTS;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
