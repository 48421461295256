import { Layer as LayerElement } from 'konva/lib/Layer';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { domReferencesAtom, WorkspaceDomReferences } from '../atom';

export const utilityLayerRefSelector = selector<LayerElement>({
  key: 'utilLayerRefSelector',
  get: ({ get }) => get<WorkspaceDomReferences>(domReferencesAtom).utilLayer,
  set: ({ get, set }, newValue: LayerElement) => {
    set(domReferencesAtom, { ...get(domReferencesAtom), utilLayer: newValue });
  },
  dangerouslyAllowMutability: true,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
