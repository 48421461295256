import { useSimulationDraftCallbacks } from '@/modules/simulation/hooks';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isValidToRunSelector } from '../../../store/draft';

export const Buttons = () => {
  const { prepareAndRun } = useSimulationDraftCallbacks();
  const { t } = useTranslation('interface');
  const isValidToRun = useRecoilValue(isValidToRunSelector);

  return (
    <Stack sx={{ px: 2 }}>
      <Button variant='outlined' disabled={!isValidToRun} onClick={prepareAndRun}>
        {t('simulation.simulation_edit.header.run_button.label')}
      </Button>
    </Stack>

  );
};
