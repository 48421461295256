import Box from '@mui/material/Box';

import { DashboardTopBar } from '@common/components/DashboardTopBar';
import { TOP_BAR_HEIGHT_PX } from '@common/components/TopBar';
import { LoadingContainer } from '@common/components/LoadingContainer';

import FloorplanCreator from './FloorplanCreator';
import FloorplanDuplicator from './FloorplanDuplicator';
import { FloorplanLayout } from './FloorplanLayout';
import { FloorplanContextMenu } from './contextMenu/FloorplanContextMenu';
import { useFloorplanAdmin } from './hooks';
import FloorplanDeleter from './FloorplanDeleter';

const loadingContainerStyle = { height: '100vh', overflowY: 'scroll' };
const boxStyle = { pt: TOP_BAR_HEIGHT_PX, height: '100%', zIndex: 1 };

export function FloorplanAdmin() {
  const { isProjectLoading } = useFloorplanAdmin();

  return (
    <LoadingContainer
      isLoading={isProjectLoading}
      minDuration={1000}
      delay={0}
      sx={loadingContainerStyle}
    >
      <DashboardTopBar />

      <Box sx={boxStyle}>
        <FloorplanLayout />
        <FloorplanCreator />
        <FloorplanDeleter />
        <FloorplanDuplicator />
      </Box>

      <FloorplanContextMenu />
    </LoadingContainer>
  );
}
