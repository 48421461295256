export enum Unit {
  MILLIMETERS = 'mm',
  CENTIMETERS = 'cm',
  METERS = 'm',
}

export enum Theme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export type Size = {
  width: number;
  height: number;
};

export enum LoadingType {
  OPAQUE = 'OPAQUE',
  TRANSPARENT = 'TRANSPARENT',
}

export enum WorkspaceMode {
  EDITABLE = 'EDITABLE',
  READONLY = 'READONLY',
  READONLY_SELECTION = 'READONLY_SELECTION',
}

export enum AppMode {
  DigitalTwin = 'DIGITALTWIN',
  FloorplanAdmin = 'FLOORPLAN_ADMIN',
}

export type Result<T> = { status: 'ok'; value: T } | { status: 'error'; error: string };
