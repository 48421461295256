import './styling';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as ResizeObserverModule from 'resize-observer-polyfill';

import { App } from './App';
import './i18n';

import { msalConfig } from '@/modules/authentication/authConfig';

const instance = new PublicClientApplication(msalConfig);

(global as any).ResizeObserver = ResizeObserverModule.default;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
