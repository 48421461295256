import { Area } from '@/components/Workspace/Area';
import { shapeGroupBoundingBoxSelector, shapeGroupState } from '@/modules/shapeGroups';
import { memo, useCallback, useEffect } from 'react';
import { Group, Rect } from 'react-konva';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { invisibleElementsSelector } from '../../layers';
import { ElementName } from '../../workspace/helpers/konva';
import { hoveredShapeGroupIdState } from '../atom';

type ShapeGroupProps = {
  groupId: string;
};

const ShapeGroupRenderComponent = ({ groupId }: ShapeGroupProps) => {
  const group = useRecoilValue(shapeGroupState(groupId));
  const setShapeGroupUnderMouseId = useSetRecoilState(hoveredShapeGroupIdState);
  const invisibleObjects = useRecoilValue(invisibleElementsSelector);

  const onMouseEnter = useCallback(() => {
    setShapeGroupUnderMouseId(groupId);
  }, [groupId, setShapeGroupUnderMouseId]);

  const onMouseLeave = useCallback(() => {
    setShapeGroupUnderMouseId(null);
  }, [setShapeGroupUnderMouseId]);

  useEffect(
    () => () => {
      setShapeGroupUnderMouseId((current) => (current === groupId ? null : current));
    },
    [setShapeGroupUnderMouseId, groupId],
  );
  // TODO: locking is currently broken for shapes within a group
  const groupBoundingBox = useRecoilValue(shapeGroupBoundingBoxSelector(groupId));
  const childrenToRender = group?.children
    ? group.children
        .filter((item) => !invisibleObjects.has(item))
        .map((shapeId) => <Area id={shapeId} key={shapeId} />)
    : [];

  if (childrenToRender.length === 0) {
    return null;
  }

  return (
    <>
      <Group
        id={groupId}
        name={ElementName.SHAPE_GROUP_CONTAINER}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {childrenToRender}
      </Group>

      <Rect
        x={groupBoundingBox.x / 10}
        y={groupBoundingBox.y / 10}
        width={groupBoundingBox.width / 10}
        height={groupBoundingBox.height / 10}
        listening={false}
        strokeWidth={2}
        stroke='#00000040'
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
      />
    </>
  );
};

export const ShapeGroupRender = memo(ShapeGroupRenderComponent);
