import { AreaMapperTypeEnum } from "./types";

export const AddAreaMappingFromAreaToHighway = (areaMappings, areaId, highwayId, getaway: Boolean, usePivots: Boolean) => {
  areaMappings.push({
    sequence: areaMappings.length + 1,
    mapTwins: null,
    mapNeighbors: null,
    areaReference: {
      name: areaId,
    },
    mapAllNearestCps: null,
    mapCpsAtCrossing: null,
    mapCpsToEps: null,
    mapCpsToEpsAtCrossing: null,
    mapEpNeighbors: null,
    mapEpsMultiDeep: null,
    mapEpsToGetawayCps: getaway
      ? {
          discType: AreaMapperTypeEnum.MAP_END_POINTS_TO_LOCAL_CHECK_POINTS,
          areaReferencesToMap: [
            {
              name: highwayId,
            },
          ],
          usePivots,
        }
      : null,
    mapEpsToLocalCps: getaway
      ? null
      : {
          discType: AreaMapperTypeEnum.MAP_END_POINTS_TO_LOCAL_CHECK_POINTS,
          areaReferencesToMap: [
            {
              name: highwayId,
            },
          ],
          usePivots,
        },
    mapHighwayToServiceRoadCps: null,
    mapTwoNearestCps: null,
  });
};