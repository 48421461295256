import { MutableRefObject, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@assets/icons/CloseIcon';
import { Box, ClickAwayListener, IconButton, Paper, Popper, Stack, SxProps } from '@mui/material';
import { Theme } from '@modules/common/types/general';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference } from '@/modules/userPreferences/hooks';

import { StyledTooltip } from '../StyledTooltip';

type Props = {
  anchor: MutableRefObject<HTMLElement>;
  children: ReactNode;
  header: ReactNode;
  open: boolean;
  sx?: SxProps;

  onClose?: () => void;
};

export const PropertiesPopup = ({
  anchor,
  children,
  header,
  open,
  onClose = () => undefined,
  sx,
}: Props) => {
  const { t } = useTranslation('common');
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  return (
    <Popper
      anchorEl={anchor.current}
      open={open}
      placement='left-start'
      sx={{
        zIndex: 2,
        ...sx,
      }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 24],
          },
        },
      ]}
      className={theme === Theme.DARK ? 'dark' : ''}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper sx={{ '&:hover': { cursor: 'default' }, padding: 2 }}>
          <Stack gap={1}>
            <Stack direction='row' alignItems='center' gap={1} justifyContent='space-between'>
              <Box>{header}</Box>
              <StyledTooltip title={t('close')}>
                <IconButton onClick={onClose} sx={{ alignSelf: 'start' }}>
                  <CloseIcon />
                </IconButton>
              </StyledTooltip>
            </Stack>
            <Box>{children}</Box>
          </Stack>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};
