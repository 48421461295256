import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { timeAgo } from '@modules/common/helpers/date';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { StatusChip } from '@common/components/StatusChip';

import { versionSelector } from '../store/versions';
import { TopButtons } from './TopButtons';
import { selectedSelector } from '../store/versions/selectedSelector';

type Props = {
  id: string;
};

export const VersionCard = ({ id }: Props) => {
  const { t } = useTranslation('interface');
  const [isHover, setIsHover] = useState<boolean>();
  const { version, updatedAt, latest, current, readonly } = useRecoilValue(versionSelector(id));
  const [isSelected, setIsSelected] = useRecoilState(selectedSelector(id));

  const handleSelect = useCallback(() => {
    setIsSelected(!isSelected);
  }, [setIsSelected, isSelected])

  const updatedText = useMemo(() => timeAgo(updatedAt), [updatedAt]);

  return (
    <Stack
      sx={{
        alignItems: 'flex-start',
        border: isSelected ? '2px solid' : 'none',
        borderColor: isSelected ? 'primary.main' : 'unset',
        borderBottom: isSelected ? '2px solid' : '1px solid',
        borderBottomColor: isSelected ? 'primary.main' : 'neutral.lighter',
        padding: '8px 16px 16px',
        gap: 1,
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      component='article'
      className={isSelected ? 'selectedBox' : ''}
    >
      <Stack
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Stack sx={{ flexBasis: '100%', alignItems: 'center', flexDirection: 'row', height: '100%' }} gap={1} onClick={handleSelect}>
          {latest && (
            <StatusChip status={t('versioning.version_card.latest')} color='success.main' />
          )}
          {current && (
            <StatusChip status={t('versioning.version_card.current')} color='info.main' />
          )}
          {readonly && (
            <StatusChip status={t('versioning.version_card.readonly')} color='neutral.main' />
          )}
        </Stack>
        <Stack
          sx={{ alignItems: 'center', flexDirection: 'row', gap: 2, height: '32px', flexBasis: '50px', justifyContent: 'center' }}
        >
          {!current && isHover && <TopButtons id={id} />}
        </Stack>
      </Stack>
      <Stack sx={{ alignItems: 'center', flexDirection: 'row', gap: 2, width: '100%' }}>
        <Typography
          align='left'
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            flexGrow: 1,
          }}
          onClick={handleSelect}
        >
          {t('versioning.version_card.version', { version })}
        </Typography>
      </Stack>
      <Typography variant='caption' fontSize={10} onClick={handleSelect}>
        <Box component='span' color='neutral.darker'>
          {t('versioning.version_card.last_updated', { date: updatedText })}
        </Box>{' '}
      </Typography>
    </Stack>
  );
};
