import { Vector2d } from 'konva/lib/types';
import { Box2, MathUtils, Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import { merge } from '@/helpers/utils';
import {
  getBoxAttachPointRelativeTo,
  getOrderedControlPoints,
} from '@/modules/common/helpers/shapes';
import { wideLineSegmentToOrientedBoundingBox } from '@/modules/common/helpers/boundingBox';
import { ControlPoint, PotentialControlPointType } from '@/modules/common/types/shapes';
import { addCopy } from '@/store/recoil/floorplan/helper';
import { WallShape } from '@/store/recoil/shape';
import { BoundingBox } from '@helpers/types';
import { AngledHighwayShapePersisted } from '@modules/common/types/floorPlan';
import { midpoint } from '@modules/connections/common/helpers';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import {
  getMiddlePoint,
  getVectorRotatedAroundPoint,
  isFloatingPointEqual,
  rotateVector,
} from '../common/helpers/math';
import { CONTROL_POINT_ANCHOR_ID_PREFIX, POTENTIAL_CP_ANCHOR_ID_PREFIX } from './constants';
import { AngledHighwayShape, LineSegment, PointHoldingObj, WideLineSegment } from './types';

export const genCPAnchorId = (controlPointId: string) =>
  `${CONTROL_POINT_ANCHOR_ID_PREFIX}${controlPointId}`;

export const retreiveCPIdFromCPAnchorId = (anchorId: string) => anchorId.split('_')[1];

export const genPotentialCPAnchorId = (prevCPId: string, nextCPId: string) =>
  `${POTENTIAL_CP_ANCHOR_ID_PREFIX}${prevCPId}_${nextCPId}`;

export const retreiveNeighbourIdsFromPotentialCPAnchorId = (potentialAnchorId: string) => {
  const [, prevCPId, nextCPId] = potentialAnchorId.split('_');

  return {
    prevCPId,
    nextCPId,
  };
};

export const getFlatPointsArrayFromControlPoints = (controlPoints: ControlPoint[]): number[] =>
  controlPoints.flatMap((controlPoint) => [controlPoint.position.x, controlPoint.position.y]);

export const accurateAngledHighwayBoundingBox = (
  controlPoints: Vector2[],
  width: number,
): BoundingBox => {
  const box = new Box2();
  box.setFromPoints(controlPoints);

  const firstPoint = controlPoints[0].clone();
  const lastPoint = controlPoints[controlPoints.length - 1].clone();
  let firstLine = new Vector2();
  let lastLine = new Vector2();

  const halfWidth = width / 2;
  if (controlPoints.length > 2) {
    firstLine = controlPoints[1].clone().sub(firstPoint).normalize().multiplyScalar(halfWidth);
    lastLine = lastPoint
      .clone()
      .sub(controlPoints[controlPoints.length - 2])
      .normalize()
      .multiplyScalar(halfWidth);
  } else if (controlPoints.length === 2) {
    firstLine = lastPoint.clone().sub(firstPoint).normalize().multiplyScalar(halfWidth);
    lastLine = firstLine.clone();
  } else {
    console.error('Error in AngledHighwayProxy.');
    return;
  }
  const firstLine_p = rotateVector(firstLine, 90);
  const lastLine_p = rotateVector(lastLine, 90);

  // min.x
  let newMinX = box.min.x;
  if (isFloatingPointEqual(firstPoint.x, box.min.x)) {
    newMinX -= Math.abs(firstLine_p.x);
  } else if (isFloatingPointEqual(lastPoint.x, box.min.x)) {
    newMinX -= Math.abs(lastLine_p.x);
  } else {
    newMinX -= halfWidth;
  }

  // min.y
  let newMinY = box.min.y;
  if (isFloatingPointEqual(firstPoint.y, box.min.y)) {
    newMinY -= Math.abs(firstLine_p.y);
  } else if (isFloatingPointEqual(lastPoint.y, box.min.y)) {
    newMinY -= Math.abs(lastLine_p.y);
  } else {
    newMinY -= halfWidth;
  }
  box.expandByPoint(new Vector2(newMinX, newMinY));

  // max.x
  let newMaxX = box.max.x;
  if (isFloatingPointEqual(firstPoint.x, box.max.x)) {
    newMaxX += Math.abs(firstLine_p.x);
  } else if (isFloatingPointEqual(lastPoint.x, box.max.x)) {
    newMaxX += Math.abs(lastLine_p.x);
  } else {
    newMaxX += halfWidth;
  }

  // max.y
  let newMaxY = box.max.y;
  if (isFloatingPointEqual(firstPoint.y, box.max.y)) {
    newMaxY += Math.abs(firstLine_p.y);
  } else if (isFloatingPointEqual(lastPoint.y, box.max.y)) {
    newMaxY += Math.abs(lastLine_p.y);
  } else {
    newMaxY += halfWidth;
  }
  box.expandByPoint(new Vector2(newMaxX, newMaxY));

  const size = new Vector2();
  box.getSize(size);

  return {
    x: box.min.x,
    y: box.min.y,
    width: size.x,
    height: size.y,
  };
};

export const boxContainsBox = (supposedOuterBox: BoundingBox, box: BoundingBox) =>
  supposedOuterBox.x <= box.x &&
  supposedOuterBox.y <= box.y &&
  supposedOuterBox.x + supposedOuterBox.width >= box.x + box.width &&
  supposedOuterBox.y + supposedOuterBox.height >= box.y + box.height;

export const getAngledHighwayDuplicate = (
  originShape: AngledHighwayShape | WallShape,
  offset: Vector2,
  overrides: any = {},
): AngledHighwayShape | WallShape => {
  const newShapeControlPoints = [];

  let prevCPId = null;
  let newCPId = null;
  let nextCPId = null;

  const controlPointsAmount = originShape.properties.controlPoints.length;
  getOrderedControlPoints(originShape.properties.controlPoints).forEach((originCP, index) => {
    newCPId = nextCPId || uuid();

    const isLastCP = controlPointsAmount - 1 === index;
    nextCPId = isLastCP ? null : uuid();

    const newCP: ControlPoint = {
      id: newCPId,
      prev: prevCPId,
      next: nextCPId,
      position: new Vector2(originCP.position.x, originCP.position.y).add(offset),
    };

    prevCPId = newCPId;

    newShapeControlPoints.push(newCP);
  });

  return merge(
    {
      ...originShape,
      name: addCopy(originShape),
      id: uuid(),
      properties: {
        ...originShape.properties,
        controlPoints: newShapeControlPoints,
      },
    },
    overrides,
  );
};

export const getPositionsFromFlatPointsArray = (flatPointsArray: number[]): Vector2[] => {
  const positions = [];

  for (let i = 0; i < flatPointsArray.length; i += 2) {
    positions.push(new Vector2(flatPointsArray[i], flatPointsArray[i + 1]));
  }

  return positions;
};

export const getLineSegmentsFromPoints = (points: Vector2[]) => {
  const lineSegments: LineSegment[] = [];
  const pointsAmount = points.length;

  for (let i = 0; i < pointsAmount - 1; i++) {
    lineSegments.push({ start: points[i], end: points[i + 1] });
  }

  return lineSegments;
};

export const getBoundingBoxBoundaries = (bb: BoundingBox): LineSegment[] => [
  {
    start: new Vector2(bb.x, bb.y),
    end: new Vector2(bb.x + bb.width, bb.y),
  },
  {
    start: new Vector2(bb.x + bb.width, bb.y),
    end: new Vector2(bb.x + bb.width, bb.y + bb.height),
  },
  {
    start: new Vector2(bb.x + bb.width, bb.y + bb.height),
    end: new Vector2(bb.x, bb.y + bb.height),
  },
  {
    start: new Vector2(bb.x, bb.y + bb.height),
    end: new Vector2(bb.x, bb.y),
  },
];

export const getLineSegmentIntersection = (
  lineSegmentA: LineSegment,
  lineSegmentB: LineSegment,
): Vector2 | null => {
  const vA = new Vector2().subVectors(lineSegmentA.end, lineSegmentA.start);
  const vB = new Vector2().subVectors(lineSegmentB.end, lineSegmentB.start);
  const delta = lineSegmentA.start.clone().sub(lineSegmentB.start);

  const s = (-vA.y * delta.x + vA.x * delta.y) / (-vB.x * vA.y + vA.x * vB.y);
  const t = (+vB.x * delta.y - vB.y * delta.x) / (-vB.x * vA.y + vA.x * vB.y);

  if (!(s >= 0 && s <= 1 && t >= 0 && t <= 1)) return null;

  return lineSegmentA.start.add(vA.multiplyScalar(t));
};

export const findClosestPoint = <T extends PointHoldingObj>(
  points: T[],
  targetPoint: Vector2,
): T => {
  if (points.length === 0) return;

  let currentClosestDistance = points[0].position.distanceTo(targetPoint);

  return points.reduce((result, p) => {
    const distance = p.position.distanceTo(targetPoint);

    if (distance < currentClosestDistance) {
      currentClosestDistance = distance;
      return p;
    }

    return result;
  }, points[0]);
};

export const getHighwaySegmentCenterPoints = (
  controlPoints: ControlPoint[],
): PotentialControlPointType[] => {
  const centerPoints: PotentialControlPointType[] = [];
  const pointsAmount = controlPoints.length;

  for (let i = 0; i < pointsAmount - 1; i++) {
    const cP1 = controlPoints[i];
    const cP2 = controlPoints[i + 1];

    centerPoints.push({
      prevCPId: cP1.id,
      nextCPId: cP2.id,
      position: getMiddlePoint(cP1.position, cP2.position),
    });
  }

  return centerPoints;
};

export const snapToClosestAxis = (respectiveOrigin: Vector2, point: Vector2) => {
  const { x: originX, y: originY } = respectiveOrigin;
  const tempClampedPos = point.clone();
  const diff = point.clone().sub(respectiveOrigin);

  if (Math.abs(diff.y) < Math.abs(diff.x)) tempClampedPos.setY(originY);
  else tempClampedPos.setX(originX);

  return tempClampedPos;
};

export const getSegmentIdContainingPoint = (
  highway: AngledHighwayShape,
  point: Vector2,
): number => {
  const controlPoints = highway?.properties?.controlPoints;

  if (!controlPoints) return -1;

  const { width } = highway.parameters;
  const highwayLineSegments = getLineSegmentsFromPoints(controlPoints.map((item) => item.position));

  for (let i = 0; i < highwayLineSegments.length; ++i) {
    const { start, end } = highwayLineSegments[i];
    const segmentEndFromStart = end.clone().sub(start);
    const angleInDegrees = MathUtils.radToDeg(
      Math.atan2(segmentEndFromStart.y, segmentEndFromStart.x),
    );

    // rotate segment and point so the segment is on the x-axis
    const axisAlignedSegmentVector = getVectorRotatedAroundPoint(
      segmentEndFromStart,
      new Vector2(0, 0),
      -angleInDegrees,
    );
    const respectivePoint = getVectorRotatedAroundPoint(
      point.clone().sub(start),
      new Vector2(0, 0),
      -angleInDegrees,
    );

    if (
      respectivePoint.y <= width / 2 &&
      respectivePoint.y >= -(width / 2) &&
      respectivePoint.x >= 0 &&
      respectivePoint.x <= axisAlignedSegmentVector.x
    ) {
      return i;
    }
  }

  return -1;
};

export const getSegmentContainingPoint = (
  highway: AngledHighwayShape,
  point: Vector2d,
): WideLineSegment | null => {
  const segmentId = getSegmentIdContainingPoint(
    highway,
    new Vector2(point.x, point.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE),
  );

  if (segmentId === -1) {
    return null;
  }

  return getSegment(highway, segmentId);
};

export const getSegment = (
  highway: AngledHighwayShapePersisted,
  segmentId: number,
): WideLineSegment | null => getSegments(highway)[segmentId] ?? null;

export const getSegments = ({
  properties,
  parameters,
}: AngledHighwayShapePersisted): WideLineSegment[] => {
  const lines: WideLineSegment[] = [];

  for (let j = 0; j < properties.controlPoints.length - 1; j++) {
    lines.push({
      points: {
        start: properties.controlPoints[j].position,
        end: properties.controlPoints[j + 1].position,
      },
      width: parameters.width,
    });
  }

  return lines;
};

export const getSegmentAttachPoint = (segment: WideLineSegment): Vector2 =>
  midpoint(segment.points.start, segment.points.end).multiplyScalar(SHAPE_TO_CANVAS_SCALE);

export const getSegmentAttachPointRelativeTo = (
  segment: WideLineSegment,
  relativeTo: Vector2,
): Vector2 => {
  const obb = wideLineSegmentToOrientedBoundingBox(segment);
  const attachPoint = getBoxAttachPointRelativeTo(obb, relativeTo);

  return attachPoint;
};
