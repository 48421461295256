import { SelectInput } from '@/modules/common/components/inputs';
import { unique } from '@modules/common/helpers/array';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '@modules/floorplan';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { useFlow } from '@modules/flows';
import { useShapeGroup } from '@modules/shapeGroups';
import { formControlClasses, inputClasses, selectClasses } from '@mui/material';
import { Stack } from '@mui/system';
import { NOTIFICATION_TYPES, showNotification } from '@recoil/notification';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { enabledVehiclesSelector } from '@/modules/vehicles';
import { areaVehicleIds } from '../store/area';
import { useArtefacts } from '@/modules/artefacts';

const containerSx = {
  // Wrap value-string to new line, don't truncate.
  [`& .${selectClasses.multiple}`]: {
    whiteSpace: 'normal!important',
  },

  // because value-string is wrapped, correct the height and padding of the options container.
  [`& .${formControlClasses.root} .${inputClasses.root}`]: {
    height: 'auto',
    padding: '4px 12px!important',
  },
};

export const VehicleSelect = () => {
  const { t } = useTranslation();
  const showNotificationFn = useSetRecoilState(showNotification);
  const enabledVehicles = useRecoilValue(enabledVehiclesSelector);
  const [selectedVehicleIds, setSelectedVehicleIds] = useRecoilState(areaVehicleIds);
  const { saveFloorPlan } = useFloorPlanState();
  const mode = useRecoilValue(modeSelector);
  const { getRelatedFlowIdsOfShapes } = useFlow();
  const { findShapeGroupId, getAllShapeIds } = useShapeGroup();
  const { runPreValidation } = useRunPreValidation();
  const { updateDebounced: updateArtefacts } = useArtefacts();

  const handleChange = useRecoilCallback(
    ({ snapshot }) =>
      async (newSelected: string) => {
        const selectedShapeIds = await snapshot.getPromise(selectedShapesIdsState);

        const groupIds = await Promise.all(selectedShapeIds.map((item) => findShapeGroupId(item)));
        const uniqueGroupIds = unique(groupIds.filter(Boolean));
        const shapeIdsInGroups = await getAllShapeIds(uniqueGroupIds);

        if (
          uniqueGroupIds.length > 1 ||
          (uniqueGroupIds.length === 1 && shapeIdsInGroups.size !== selectedShapeIds.length)
        ) {
          showNotificationFn({
            type: NOTIFICATION_TYPES.ERROR,
            message: t(
              'errors:grouping.change_vehicle_type',
              'Please ungroup shapes before changing vehicle types',
            ),
          });
          return;
        }

        const flows = await getRelatedFlowIdsOfShapes(selectedShapeIds);
        if (flows.length > 0) {
          showNotificationFn({
            type: NOTIFICATION_TYPES.ERROR,
            message: t(
              'errors:flow.vehicle_type_change',
              'Please remove any related flows before changing vehicle types',
            ),
          });
          return;
        }

        setSelectedVehicleIds([newSelected]);
        await saveFloorPlan();
        runPreValidation([
          PreValidationAspect.DISCONNECTED_AREA_IDS,
          PreValidationAspect.CONNECTION_LACKING_ROADS,
          PreValidationAspect.FLOWLESS_AREAS,
          PreValidationAspect.DISCONNECTED_FLOW_STOPS,
          PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
        ]);
        updateArtefacts(selectedShapeIds);
      },
    [
      saveFloorPlan,
      setSelectedVehicleIds,
      getRelatedFlowIdsOfShapes,
      showNotificationFn,
      findShapeGroupId,
      updateArtefacts,
    ],
  );

  return (
    <Stack sx={containerSx}>
      <SelectInput
        disabled={mode !== WorkspaceMode.EDITABLE}
        options={enabledVehicles?.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        onChange={handleChange}
        value={selectedVehicleIds}
      />
    </Stack>
  );
};
