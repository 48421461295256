import { useRecoilCallback } from 'recoil';

export const useTypeGuard = () => {
  const validateKeys = useRecoilCallback(
    () =>
      <T extends object>(value: T, requiredKeys: string[]) => {
        if (!value) {
          return false;
        }

        return requiredKeys.every((key) => value.hasOwnProperty(key));
      },
    [],
  );

  return { validateKeys };
};
