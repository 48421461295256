import { ToolMenuItem } from '@/store/recoil/tool/types';
import { ShapeType } from '@/modules/common/types/shapes';
import { KeyCodeType } from '@/store/recoil/input/constants';

export const findDescendantTools = (items: ToolMenuItem[]): ShapeType[] =>
  items.flatMap((item) => {
    if (item.type === 'menu') return findDescendantTools(item.items);

    return item.id;
  });

export const findDescendantToolKeyCodes = (items: ToolMenuItem[]): KeyCodeType[] =>
  items.flatMap((item) => {
    if (item.type === 'menu') return findDescendantToolKeyCodes(item.items);

    return item.keycode;
  });
