import { ShapeType } from '@modules/common/types/shapes';

export const AREA_MINIMUM_SIZE = 1000;
export const OBSTACLE_MINIMUM_SIZE = 50;
export const WALL_MINIMUM_LENGTH = 50;

export function getMinimumSizeByShapeType(type: ShapeType) {
  const minSize =
    type === ShapeType.WALL
      ? WALL_MINIMUM_LENGTH
      : type === ShapeType.OBSTACLE
      ? OBSTACLE_MINIMUM_SIZE
      : AREA_MINIMUM_SIZE;

  return minSize;
}
