import { GapHorizontalIcon } from '@/assets/icons';
import { useArtefacts } from '@/modules/artefacts';
import { isAreaShape } from '@/modules/common/types/guards';
import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { useUnit } from '@modules/common/hooks';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { ShapeType } from '@modules/common/types/shapes';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences } from '@modules/userPreferences/hooks';
import InputAdornment from '@mui/material/InputAdornment';
import { CONTEXT, contextState } from '@recoil/input';
import { selectedShapesState } from '@recoil/shapes/selected';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { areasGap } from '../store/area';

function Gap() {
  const { saveFloorPlan } = useFloorPlanState();
  const [gap, setGap] = useRecoilState(areasGap);
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation('interface');
  const setContext = useSetRecoilState(contextState);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { toBaseUnit } = useUnit();
  const mode = useRecoilValue(modeSelector);
  const selectedShapes = useRecoilValue(selectedShapesState);
  const containsRacking = selectedShapes.some(
    (item) => isAreaShape(item) && item.parameters.storageType === 'rack',
  );
  const { updateDebounced: updateArtefacts } = useArtefacts();

  const onGapChange = useRecoilCallback(
    ({ snapshot }) =>
      async (value: number) => {
        setGap(value);
        saveFloorPlan();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        updateArtefacts(selectedShapes.map((item) => item.id));

        switch (selectedShapes[0].type) {
          case ShapeType.INTAKE:
          case ShapeType.INTAKE_POSITION:
            updateUserPreference(UserPreferenceName.INTAKE_GAP, await toBaseUnit(value));
            break;
          case ShapeType.DELIVERY:
          case ShapeType.DELIVERY_POSITION:
            updateUserPreference(UserPreferenceName.DELIVERY_GAP, await toBaseUnit(value));
            break;
          case ShapeType.STORAGE:
          case ShapeType.STORAGE_POSITION:
            updateUserPreference(UserPreferenceName.STORAGE_GAP, await toBaseUnit(value));
            break;
          case ShapeType.PROCESS_ONE_EP:
          case ShapeType.PROCESS_ONE_EP_POSITION:
            updateUserPreference(UserPreferenceName.PROCESS_GAP, await toBaseUnit(value));
            break;
          case ShapeType.CHARGING:
          case ShapeType.CHARGING_POSITION:
            updateUserPreference(UserPreferenceName.CHARGING_GAP, await toBaseUnit(value));
            break;
          case ShapeType.PARKING:
          case ShapeType.PARKING_POSITION:
            updateUserPreference(UserPreferenceName.PARKING_GAP, await toBaseUnit(value));
            break;
          default:
        }
      },
    [updateUserPreference, updateUserPreference, setGap, updateArtefacts],
  );

  return (
    <NumberInput
      value={useRecoilValue(unitValueSelector(gap))}
      unit={t(`interface:settings.units.${unit}`, unit)}
      inputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <GapHorizontalIcon sx={{ mt: -1 }} />
          </InputAdornment>
        ),
      }}
      sx={{ width: '100%' }}
      disabled={containsRacking || mode !== WorkspaceMode.EDITABLE}
      onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
      onChange={onGapChange}
    />
  );
}

export default Gap;
