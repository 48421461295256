import { DEG2RAD } from 'three/src/math/MathUtils';

import { calculateShapesBoundingBox } from '@/modules/common/helpers/shapes';
import { isAngledHighwayShape, isProcessAreaTwoEp, isWallShape } from '@/modules/common/types/guards';
import { REFERENCE_TYPE, ReferenceImageSettings } from '@/modules/referenceImage/types';
import { MAX_REFERENCE_IMAGE_SIZE } from '@/modules/visualization/consts';
import { createReferenceImage } from '@/modules/visualization/helpers/createReferenceImage';
import { createWallShape } from '@/modules/visualization/helpers/createWallShape';
import {
  buildSafetyZonePayload,
  hasSafetyZone,
  safetyZoneRectangleToPolyline,
} from '@/modules/visualization/helpers/safetyZones';
import { BoundingBox } from '@helpers/types';
import { LoadCarrierType } from '@modules/common/types/floorPlan';
import { DTShape } from '@modules/common/types/shapes';
import { boundingBoxToFpsRectangle } from '@modules/floorplanService/helpers/mapping/utils';
import { Vector2 } from 'three';
import { VisualizationObject } from '../types';
import { createFloor } from './createFloor';
import { createPolylineObject } from './createPolylineObject';
import { createRectangleShape } from './createRectangleShape';

export const calculateFleetTrackerWorkspaceBox = (shapes: DTShape[], padding: number) => {
  const workspaceBox = calculateShapesBoundingBox(shapes);
  workspaceBox.width += padding * 2;
  workspaceBox.height += padding * 2;
  workspaceBox.x -= padding;
  workspaceBox.y -= padding;

  return workspaceBox;
};

export const createVisualizationObjects = (
  shapes: DTShape[],
  loadCarriers: LoadCarrierType[],
  padding: number,
  reference?: ReferenceImageSettings
) => {
  // find extent of workspace for converting top-left canvas origin to right-hand coordinate system
  const workspaceBox = calculateFleetTrackerWorkspaceBox(shapes, padding);

  const objects: VisualizationObject[] = [];

  // draw reference image in fleettracker
  if (reference && reference.type === REFERENCE_TYPE.IMAGE && reference.file && reference.file.includes('data:image/png') && reference.file.length < MAX_REFERENCE_IMAGE_SIZE) {
    const referenceImage = createReferenceImage(workspaceBox, {x: reference.x, y:reference.y, width: reference.width, height:reference.height }, reference.file)
    objects.push(referenceImage);
  }

  // draw floor in fleettracker
  const floor = createFloor(workspaceBox);
  
  objects.push(floor);

  // convert all shapes in floorplan to visualization object and send to fleettracker
  shapes.forEach((s) => {
    if (isAngledHighwayShape(s) || isProcessAreaTwoEp(s)) {
      const vo = createPolylineObject(s, workspaceBox);
      objects.push(vo);
    } else if (isWallShape(s)) {
      objects.push(...createWallShape(s, workspaceBox));
    } else {
      let fpRectangle = boundingBoxToFpsRectangle(s.properties, s.properties.r, workspaceBox);
      const angleToFpsOrigin = Math.atan(s.properties.height / 2 / (s.properties.width / 2));
      const distanceToFpsOrigin = Math.sqrt(
        (s.properties.height / 2) ** 2 + (s.properties.width / 2) ** 2,
      );

      const objectBox: BoundingBox = {
        x:
          fpRectangle.centerX -
          Math.cos(-s.properties.r * DEG2RAD - angleToFpsOrigin) * distanceToFpsOrigin,
        y:
          fpRectangle.centerY +
          Math.sin(-s.properties.r * DEG2RAD - angleToFpsOrigin) * distanceToFpsOrigin,
        width: s.properties.width,
        height: s.properties.height,
      };

      // draw safetyzone
      if (hasSafetyZone(s.type)) {
        const safetyLine = safetyZoneRectangleToPolyline(
          new Vector2(fpRectangle.centerX, fpRectangle.centerY),
          objectBox.width,
          objectBox.height,
          s.properties.r,
        );
        const payload = buildSafetyZonePayload(`safety-${s.name}`, safetyLine);
        objects.push(payload);
      }
      // draw objects
      const payload = createRectangleShape(s, objectBox, loadCarriers);

      objects.push(payload);
    }
  });

  return objects;
};
