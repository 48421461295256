import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { Crossing } from '@/modules/common/types/connections';
import { HighwayShape } from '@/store/recoil/shape';
import { Vector2 } from 'three';
import { encodeIdWithVehicleId } from '../idEncoder';
import { VehicleSpec } from '../types';
import { addCrossingCpCutout } from './addCrossingCpCutout';
import { addCrossingGateMapping } from './addCrossingGateMapping';

const POINT_ON_LINE_THRESHOLD = 10;

type CrossingSegment = {
  fromHighwayId: string;
  fromSegmentId?: number;
  toHighwayId: string;
  toSegmentId?: number;
};

export type CheckPointAtCrossing = {
  fromCheckPointId: string;
  toCheckPointIds: string[];
};

export const mapHighwayCrossings = (
  vehicleSpecs: VehicleSpec[],
  crossings: Crossing[],
  shapeHighways: HighwayShape[],
  angledHighways: AngledHighwayShape[],
) => {
  const crossingSegments = getCrossingSegments(crossings, shapeHighways, angledHighways);
  vehicleSpecs.forEach((vehicleSpec) => {
    const vehicleDatabaseId = vehicleSpec.databaseId;
    const checkPointAtCrossings = convertCrossingSegmentsToCheckpointCrossing(
      crossingSegments,
      vehicleDatabaseId,
    );
    addCrossingGateMapping(vehicleSpec, checkPointAtCrossings);
    addCrossingCpCutout(vehicleSpec, checkPointAtCrossings);
  });
};

const convertCrossingSegmentsToCheckpointCrossing = (
  crossingSegments: CrossingSegment[],
  vehicleDatabaseId: number,
): CheckPointAtCrossing[] => {
  const cps: Map<string, string[]> = new Map();
  crossingSegments.forEach((crossing) => {
    const fromCpId = encodeIdWithVehicleId(
      crossing.fromHighwayId,
      vehicleDatabaseId,
      crossing.fromSegmentId,
    );

    if (!cps.has(fromCpId)) {
      cps.set(fromCpId, []);
    }
    const toCpIds = cps.get(fromCpId);
    const toCpId = encodeIdWithVehicleId(
      crossing.toHighwayId,
      vehicleDatabaseId,
      crossing.toSegmentId,
    );
    toCpIds.push(toCpId);
  });
  const checkPointAtCrossings: CheckPointAtCrossing[] = [];
  cps.forEach((toCheckPointIds, fromCheckPointId) => {
    checkPointAtCrossings.push({
      fromCheckPointId,
      toCheckPointIds,
    });
  });
  return checkPointAtCrossings;
};

const getCrossingSegments = (
  crossings: Crossing[],
  shapeHighways: HighwayShape[],
  angledHighways: AngledHighwayShape[],
) => {
  const shapeHighwayMap = new Map<string, HighwayShape>();
  shapeHighways.forEach((highway) => shapeHighwayMap.set(highway.id, highway));
  const angledHighwayMap = new Map<string, AngledHighwayShape>();
  angledHighways.forEach((highway) => angledHighwayMap.set(highway.id, highway));
  const segments: CrossingSegment[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const crossing of crossings) {
    const fromHighwayId = crossing.from;
    let fromSegmentId: number | undefined;
    if (angledHighwayMap.has(fromHighwayId)) {
      fromSegmentId = findClosestSegment(angledHighwayMap.get(fromHighwayId), crossing.position);
      if (fromSegmentId === null) continue;
    }
    const toHighwayId = crossing.to;
    let toSegmentId: number | undefined;
    if (angledHighwayMap.has(toHighwayId)) {
      toSegmentId = findClosestSegment(angledHighwayMap.get(toHighwayId), crossing.position);
      if (toSegmentId === null) continue;
    }

    segments.push({
      fromHighwayId,
      fromSegmentId,
      toHighwayId,
      toSegmentId,
    });
  }
  return segments;
};

const findClosestSegment = (highway: AngledHighwayShape, point: Vector2): number | null => {
  const controlPoints = highway?.properties?.controlPoints;
  if (!controlPoints) return null;
  for (let i = 0; i < controlPoints.length - 1; ++i) {
    const p0 = controlPoints[i].position.clone();
    const p1 = controlPoints[i + 1].position.clone();
    const p1_p0 = p1.clone().sub(p0);
    const v_p0 = point.clone().sub(p0);
    const p1_v = p1.clone().sub(point);

    // point within p1_p0
    // p0 --- v --- p1
    const delta = Math.abs(p1_p0.length() - (v_p0.length() + p1_v.length()));
    if (delta < POINT_ON_LINE_THRESHOLD) return i;

    // point is outside p1_p0 but on the same line
    // p0 --- p1 --- v
    const delta2 = Math.abs(v_p0.length() - (p1_p0.length() + p1_v.length()));
    if (delta2 < POINT_ON_LINE_THRESHOLD) return i;
  }
  return null;
};
