import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CropSquareOutlined,
  ViewInArRounded
} from '@mui/icons-material';
import { Stack, Paper } from '@mui/material';
import { StyledTooltip } from '@helpers/styles';
import { useViewMode, useZoom } from './hooks';
import { ToolbarIconButton } from '@/components/Toolbar3/ToolbarButton';
import { ThemeProvider } from '@mui/material/styles';
import { zoomTheme } from '@/modules/common/components/theme';
import { ZoomButton } from '@/modules/workspace/components';
import CompactView from '@/assets/icons/CompactView';

type RenderButtonOptions = {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  selected?: boolean;
  icon: ReactNode;
};

const renderButton = ({ title, disabled, onClick, selected, icon }: RenderButtonOptions) => {
  const button = (
    <ToolbarIconButton
      disabled={disabled}
      onClick={onClick}
      style={{ borderRadius: 5, alignSelf: 'center', stroke: 'none' }}
      type={(selected ? 'Selected' : 'Deselected') as any}
    >
      {icon}
    </ToolbarIconButton>
  );

  return disabled ? (
    button
  ) : (
    <StyledTooltip title={title} placement='right'>
      <div>{button}</div>
    </StyledTooltip>
  );
};

export function VisualizationToolsetHorizontal() {
  const { viewMode, setViewMode } = useViewMode();
  const { zoomOut, zoomIn, zoomFit } = useZoom();
  const { t } = useTranslation('interface');

  return (
    <ThemeProvider theme={zoomTheme}>
      <Stack spacing={2} direction='row' sx={{ height: '40px' }}>
        <Paper elevation={1} sx={{ borderRadius: '4px' }}>
          <Stack direction='row'>
            {renderButton({
              icon: <CompactView />,
              title: t('visualization.toolbar.orthographic_button.tooltip'),
              selected: viewMode === 'orthographic',
              onClick: () => setViewMode('orthographic'),
            })}
            {renderButton({
              icon: <CropSquareOutlined />,
              title: t('visualization.toolbar.top_button.tooltip'),
              selected: viewMode === 'top',
              onClick: () => setViewMode('top'),
            })}
            {renderButton({
              icon: <ViewInArRounded />,
              title: t('visualization.toolbar.perspective_button.tooltip'),
              selected: viewMode === 'perspective',
              onClick: () => setViewMode('perspective'),
            })}
          </Stack>
        </Paper>
        <ZoomButton
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          zoomFit={zoomFit}
          disableZoomButtons={viewMode === 'top' || viewMode === 'orthographic'}
        />
      </Stack>
    </ThemeProvider>
  );
}
