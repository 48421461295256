import { RequiredElementType } from '@/modules/floorplanValidation/clientSide';
import { useRecoilCallback } from 'recoil';
import { missingRequiredElementsSelector } from '../store';

export const useIsFloorplanServiceRequirementsMet = () => {
  const isFloorplanServiceRequirementsMet = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const missingRequiredElements = await snapshot.getPromise(missingRequiredElementsSelector);
        const elements = missingRequiredElements.map(x => x.element);
        
        // Check if area is present in floorplan
        if (
          elements.length > 0 &&
          elements.includes(RequiredElementType.AREA) 
        )
          return false;

        return true;
      },
    [],
  );

  return {
    isFloorplanServiceRequirementsMet,
  };
};
