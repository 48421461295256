import { zoomTheme } from '@/modules/common/components/theme';
import { IconButton, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ViewerIcon } from '../../../assets/icons';
import { navSelector } from '../../../store/recoil/nav';
import { useNavigation } from '../../common/hooks';
import { Module } from '../../common/types/navigation';

export function VisualizeButton() {
  const { goToModule } = useNavigation();
  const nav = useRecoilValue(navSelector);

  const handleClick = useCallback(() => {
    if (nav === Module.VIEWER) {
      goToModule(Module.LAYOUT);
      return;
    }

    goToModule(Module.VIEWER);
  }, [goToModule, nav]);

  return (
    <ThemeProvider theme={zoomTheme}>
      <Paper
        elevation={1}
        style={{
          width: '45px',
          borderRadius: '4px',
          padding: '5px',
        }}
      >
        <IconButton
          sx={{
            padding: 0,
            height: '100%',
            width: '100%',
            margin: 0,
          }}
          className={nav === Module.VIEWER ? `visualize-selected-btn` : `visualize-btn`}
          onClick={handleClick}
        >
          <ViewerIcon />
        </IconButton>
      </Paper>
    </ThemeProvider>
  );
}
