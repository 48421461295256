import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector, selectorFamily } from 'recoil';

import { LayerNames, Layers } from '@/modules/common/types/layers';
import { selectedShapesState } from '../../../store/recoil/shapes/selected';
import { getLayerGroupOfShapeType, getLayerOfShapeType } from '../../workspace/helpers/shape';
import { invisibleElementsAtom, invisibleNameLabelsAtom, layersAtom, lockedElementsAtom } from './atom';

export type Input = {
  id: string;
  add: boolean;
};

const layersShowSelector = selectorFamily<boolean, LayerNames>({
  key: 'layersShowSelector',
  get:
    (layerName: LayerNames) =>
    ({ get }) => {
      const layers = get<Layers>(layersAtom);
      return layers[layerName].visible;
    },
  set:
    (layerName: LayerNames) =>
    ({ set, get }, visible) => {
      const current = get<Layers>(layersAtom);
      set(layersAtom, {
        ...current,
        [layerName]: { ...current[layerName], visible },
      });
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const layersLockSelector = selectorFamily<boolean, LayerNames>({
  key: 'layersLockSelector',
  get:
    (layerName: LayerNames) =>
    ({ get }) => {
      const layers = get<Layers>(layersAtom);
      return layers[layerName].locked;
    },
  set:
    (layerName: LayerNames) =>
    ({ set, get }, locked) => {
      const current = get<Layers>(layersAtom);
      set(layersAtom, {
        ...current,
        [layerName]: { ...current[layerName], locked },
      });
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleElementsSelector = selector({
  key: 'invisibleElementsSelector',
  get: ({ get }) => get(invisibleElementsAtom),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleElementSelector = selectorFamily<boolean, string>({
  key: 'invisibleElementSelector',
  get:
    (id: string) =>
    ({ get }) =>
      !get(invisibleElementsAtom).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const addInvisibleElementsSelector = selector<string[]>({
  key: 'addInvisibleElementsSelector',
  get: () => null,
  set: ({ set, get }, idsToHide: string[]) => {
    let current = get(invisibleElementsAtom);
    set(invisibleElementsAtom, new Set([...current, ...idsToHide]));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const removeInvisibleElementsSelector = selector<string[]>({
  key: 'removeInvisibleElementsSelector',
  get: () => null,
  set: ({ set, get }, idsToShow: string[]) => {
    const current = get(invisibleElementsAtom);
    const newInvisibleElements = new Set([...current].filter((id) => !idsToShow.includes(id)));
    set(invisibleElementsAtom, newInvisibleElements);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const lockedElementsSelector = selector({
  key: 'lockedElementsSelector',
  get: ({ get }) => get(lockedElementsAtom),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const lockedElementSelector = selectorFamily<boolean, string>({
  key: 'lockedElementSelector',
  get:
    (id: string) =>
    ({ get }) => 
      get(lockedElementsAtom).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const addLockedElementSelector = selector({
  key: 'addLockedElementSelector',
  get: () => null,
  set: ({ set, get }, id: string) => {   
    let current = get(lockedElementsAtom);
    set(lockedElementsAtom, new Set([...current, id]));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const removeLockedElementSelector = selector({
  key: 'removeLockedElementSelector',
  get: () => null,
  set: ({ set, get }, id) => {
    const current = get(lockedElementsAtom);
    const newLockedElements = new Set([...current].filter((elementId) => elementId !== id));
    set(lockedElementsAtom, newLockedElements);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const layerContainsSelectedShapes = selectorFamily<boolean, LayerNames>({
  key: 'layer/containsSelectedShapes',
  get:
    (layerName) =>
    ({ get }) => {
      const containsSelectedShapes = get(selectedShapesState)?.some(
        (shape) => layerName === getLayerOfShapeType(shape.type),
      ) || get(selectedShapesState)?.some(
        (shape) => layerName === getLayerGroupOfShapeType(shape.type),
      ) 

      return !!containsSelectedShapes;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleNameLabelsSelector = selector({
  key: 'invisibleNameLabelsSelector',
  get: ({ get }) => get(invisibleNameLabelsAtom),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleNameLabelSelector = selectorFamily<boolean, string>({
  key: 'invisibleNameLabelSelector',
  get: 
    (id: string) => 
      ({ get }) => 
        !get(invisibleNameLabelsAtom).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const addInvisibleNameLabelsSelector = selector<string[]>({
  key: 'addInvisibleNameLabelsSelector',
  get: () => null,
  set: ({ set, get }, idsToHide: string[]) => {
    const current = get(invisibleNameLabelsAtom);
    set(invisibleNameLabelsAtom, new Set([...current, ...idsToHide]));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const removeInvisibleNameLabelsSelector = selector<string[]>({
  key: 'removeInvisibleNameLabelsSelector',
  get: () => null,
  set: ({ set, get }, idsToShow: string[]) => {
    const current = get(invisibleNameLabelsAtom);
    const newInvisibleNameLabels = new Set([...current].filter((id) => !idsToShow.includes(id)));
    set(invisibleNameLabelsAtom, newInvisibleNameLabels);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const everythingIsVisible = selector({
  key: 'everythingIsVisible',
  get: ({ get }) => {
    if (get(invisibleElementsAtom).size > 0) return false;
    if (get(invisibleNameLabelsAtom).size > 0) return false;
    if (Object.values(LayerNames).some(item => !get(layersShowSelector(item)))) return false;
    return true;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export {
  addInvisibleElementsSelector, addInvisibleNameLabelsSelector, addLockedElementSelector, everythingIsVisible, invisibleElementSelector, invisibleElementsSelector, invisibleNameLabelSelector, invisibleNameLabelsSelector, layerContainsSelectedShapes, layersLockSelector, layersShowSelector, lockedElementSelector, lockedElementsSelector, removeInvisibleElementsSelector, removeInvisibleNameLabelsSelector, removeLockedElementSelector
};

