import {
  isAngledHighwayShapePersisted,
  isHighwayShapePersisted,
} from '@modules/common/types/guards';
import { addAreaMappingFromHighwayToOtherShapes } from '../addAreaMappingFromHighwayToOtherShapes';
import { AreaMapperTypeEnum, AreaReference, VehicleSpec } from '../types';
import { CheckPointAtCrossing } from './mapHighwayCrossings';

type CpsAtCrossing = {
  discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING;
  areaReferencesToMap: AreaReference[];
};

export const CrossingMapping = (vehicleSpec, id, highwayCrossings, shapes) => {
  const mapCpsAtCrossing = {
    discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING,
    areaReferencesToMap: [],
  };
  highwayCrossings.forEach((crossing) => {
    const otherShape = shapes.filter(
      (shape) => shape.id.split('.')[0] === crossing.targetName.split('.')[0],
    );
    const targetId = `${crossing.targetName}-${vehicleSpec.databaseId}`;

    if (isHighwayShapePersisted(otherShape[0]) || isAngledHighwayShapePersisted(otherShape[0])) {
      if (vehicleSpec.areas.some((area) => area.name === targetId)) {
        mapCpsAtCrossing.areaReferencesToMap.push({ name: targetId });
      }
    }
  });

  addAreaMappingFromHighwayToOtherShapes(
    vehicleSpec.gateMappingSettings.areaMappings,
    id,
    null,
    mapCpsAtCrossing,
    null,
    null,
    null,
  );
};

export const addCrossingGateMapping = (
  vehicleSpec: VehicleSpec,
  checkPointAtCrossings: CheckPointAtCrossing[],
) => {
  checkPointAtCrossings.forEach((checkPointAtCrossing) => {
    const mapCpsAtCrossing: CpsAtCrossing = {
      discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING,
      areaReferencesToMap: checkPointAtCrossing.toCheckPointIds.map((id) => ({ name: id })),
    };
    addAreaMappingFromHighwayToOtherShapes(
      vehicleSpec.gateMappingSettings.areaMappings,
      checkPointAtCrossing.fromCheckPointId,
      null,
      mapCpsAtCrossing,
      null,
      null,
      null,
    );
  });
};
