import { useRecoilCallback } from 'recoil';

import { useFileApi, usePIMApi } from '@/modules/api/hooks';
import { knownVehicleDetailsSelector } from '../store';
import { REST, UnifiedVehicle } from '../types';
import { transformRestVehiclesToVehicles } from '../helpers/mappers';
import { FileReferenceResponse, ValidAssetType } from '@/modules/vehicles/types/internal';
import {
  UVT_ASSET_FILE_CONTAINER_DEFAULT,
  KM_MDB_ASSET_FILE_CONTAINER_DEFAULT,
  FLOORPLAN_ASSET_FILE_CONTAINER,
} from '@/modules/vehicles/constants';

export const useVehicleService = () => {
  const pimServiceApi = usePIMApi();
  const { getFile } = useFileApi();

  const getAllUnifiedVehicleTypes = async (): Promise<UnifiedVehicle[]> => {
    try {
      const response = await pimServiceApi.get<REST.UnifiedVehicleType[]>(
        '/v1/unifiedVehicleTypes',
      );
      if (response.status !== 200) {
        console.log(`Failed to fetch vehicles: ${response.statusText}`);
        return [];
      }

      return transformRestVehiclesToVehicles(response.data);
    } catch (e) {
      console.log('Error fetching vehicles: ', e);
      return [];
    }
  };

  const fetchAndUploadAssetToFileApi = async (
    vehicleVariantName: string,
    assetType?: ValidAssetType,
  ): Promise<FileReferenceResponse | null> => {
    try {
      const url = `/v1/unifiedVehicleType/file?vehicleId=${vehicleVariantName}${
        assetType
          ? `&category=${
              assetType === 'uvt'
                ? UVT_ASSET_FILE_CONTAINER_DEFAULT
                : KM_MDB_ASSET_FILE_CONTAINER_DEFAULT
            }`
          : ''
      }`;

      const response = await pimServiceApi.get<FileReferenceResponse>(url);

      if (response.status !== 200) {
        console.error(`Failed to get UUID for FileApi: ${response.statusText}`);
        return null;
      }

      return response.data;
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const getVehicleAsset = async (reference: string): Promise<any | null> => {
    try {
      const response = await getFile(reference, FLOORPLAN_ASSET_FILE_CONTAINER);

      if (response.status !== 200) {
        console.log(`Error fetching unified vehicle type asset: ${response.statusText}`);
        return null;
      }

      return await response.data;
    } catch (e) {
      console.log('Error fetching unified vehicle type asset: ', e);
      return null;
    }
  };

  const getVehicleDetailsById = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const allDetails = await snapshot.getPromise(knownVehicleDetailsSelector);
        return allDetails.find((item) => item.id === id);
      },
    [],
  );

  return {
    getAllUnifiedVehicleTypes,
    getVehicleAsset,
    getVehicleDetailsById,
    fetchAndUploadAssetToFileApi,
  };
};
