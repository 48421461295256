import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { propertiesPanelTheme } from '@/modules/common/components/theme';
import { useNavigation } from '@/modules/common/hooks/useNavigation';
import { Module } from '@/modules/common/types/navigation';
import { navSelector } from '@/store/recoil/nav';
import { LoadingContainer } from '@common/components/LoadingContainer';
import { ObjectTree } from '@thive/fleet-tracker.ui.react';
import { useRecoilValue } from 'recoil';
import { useLoading } from '../../hooks';
import { Change3dModel } from './components/ChangeModel/Change3dModel';
import { CompatibilityFix } from './components/CompatibilityFix';
import { GltfImport } from './components/GltfImport';
import { Pointcloud } from './components/Pointcloud';
import { useTagTree } from './hooks';
import { PropertyPanelContainer } from '@common/components/PropertyPanelContainer';

export function PropertyPanel() {
  const nav = useRecoilValue(navSelector);
  const { goToModule } = useNavigation();
  const { tagTree, toggleTagVisibility } = useTagTree();
  const { isLoaded } = useLoading();
  const { t } = useTranslation('interface');

  const panelTitle =
    nav === Module.VISUALISATION
      ? 'interface:tabs.visualization.label'
      : 'interface:tabs.viewer.label';
  const onCloseClick = () =>
    goToModule(nav === Module.VISUALISATION ? Module.SIMULATION : Module.LAYOUT);

  return (
    <LoadingContainer isLoading={!isLoaded} data-testid='VisualizationPropertyPanel'>
      <PropertyPanelContainer labelKey={panelTitle} onCloseClick={onCloseClick}>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 2 }}>
            <Typography>{t('visualization.trees.tag_tree_accordion.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box maxHeight='500px' overflow='auto'>
              <ObjectTree
                items={tagTree}
                placeholder={t('visualization.trees.search_placeholder')}
                onVisibilityChange={toggleTagVisibility}
                disableSearch
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Change3dModel />
        <Divider />
        <CompatibilityFix />
        <Divider />
        <Accordion defaultExpanded={false} elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 2 }}>
            <Typography>Pointcloud</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 2, py: 1 }}>
            <ThemeProvider theme={propertiesPanelTheme}>
              <Pointcloud />
            </ThemeProvider>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Accordion defaultExpanded={false} elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 2 }}>
            <Typography>GLTF Import</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ py: 1, px: 2 }}>
            <ThemeProvider theme={propertiesPanelTheme}>
              <GltfImport />
            </ThemeProvider>
          </AccordionDetails>
        </Accordion>
      </PropertyPanelContainer>
    </LoadingContainer>
  );
}
