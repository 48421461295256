import { Config } from '@modules/common/types/config';

export const getConfig = (): Config => {
  const config = (window as any).globalConfig;

  return {
    api: {
      floorPlanService: {
        url: config.REACT_APP_FLOOR_PLAN_SERVICE_API,
        scope: config.REACT_APP_DIGITALTWIN_API_SCOPE,
      },
      project: {
        url: config.REACT_APP_PROJECT_API,
        scope: config.REACT_APP_PROJECT_API_SCOPE,
      },
      pimService: {
        url: config.REACT_APP_PIM_SERVICE_API,
        scope: config.REACT_APP_PIM_SERVICE_API_SCOPE,
      },
      simulation: {
        url: config.REACT_APP_SIMULATION_API,
        scope: config.REACT_APP_SIMULATION_API_SCOPE,
      },
      userPreferences: {
        url: config.REACT_APP_USER_PREFERENCES_SERVICE_API,
        scope: config.REACT_APP_USER_PREFERENCES_SERVICE_API_SCOPE,
      },
      viking: {
        url: config.REACT_APP_VIKING_API,
        scope: config.REACT_APP_VIKING_API_SCOPE,
      },
      wifiSimulation: {
        url: config.REACT_APP_WIFI_SIMULATION_API,
        scope: config.REACT_APP_WIFI_SIMULATION_API_SCOPE,
      },
      fileService: {
        url: config.REACT_APP_FILE_SERVICE_API,
        scope: config.REACT_APP_FILE_SERVICE_API_SCOPE,
      },
    },

    app: {
      version: config.REACT_APP_VERSION,
      showDebugMenu: parseBoolean(config.REACT_APP_SHOW_DEBUG_MENU),
      showDebugProjectView: parseBoolean(config.REACT_APP_SHOW_DEBUG_PROJECT_VIEW),
    },

    auth: {
      clientId: config.REACT_APP_OPEN_ID_CLIENT_ID,
      authority: config.REACT_APP_OPEN_ID_AUTHORITY,
    },

    aps: {
      enabled: config.REACT_APP_APS_ENABLED,
      bucketKey: config.REACT_APP_APS_BUCKET_KEY?.toLowerCase(),
      modelDerivativeApi: config.REACT_APP_APS_MODEL_DERIVATIVE_API,
      ossApi: config.REACT_APP_APS_OSS_API,
      authApi: config.REACT_APP_APS_AUTH_API,
      authApiScope: config.REACT_APP_APS_AUTH_API_SCOPE,
    },

    portal: {
      url: config.REACT_APP_PORTAL_URL,
    },

    fleetTracker: {
      gatewayUrl: config.REACT_APP_FLEETTRACKER_GATEWAY,
      assetUrl: config.REACT_APP_FLEETTRACKER_ASSET_URL,
      webglUrl: config.REACT_APP_FLEETTRACKER_WEBGL_URL,
    },
  };
};

const parseBoolean = (value: string | boolean | undefined): boolean =>
  typeof value === 'boolean' ? value : value === 'true';
