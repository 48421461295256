import { ShapeType } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { allAreasSelector } from '@recoil/shapes/area';
import { allPositionsState } from '@recoil/shapes/positions';
import { enabledVehiclesSelector } from '@/modules/vehicles';
import { selector } from 'recoil';
import { prevalidationState } from '../state';
import { getProcessIdsWithUnequalInboundOutboundFlows } from '@/modules/floorplanValidation/clientSide/helpers';
import { layoutFlowsSelector } from '@/modules/flows/store/layout';
import { isProcessAreaOneEp } from '@/modules/common/types/guards';

export const isFloorplanValidSelector = selector({
  key: 'prevalidation/isFloorplanValid',
  get: ({ get }) => {
    const {
      disconnectedAreaIds,
      missingRequiredElements,
      disconnectedFlowStopIds,
      flowlessAreas,
      incorrectlyConnectedShapeToRoadIds,
    } = get(prevalidationState);

    // Missing requiered elements
    if (missingRequiredElements.length > 0) return false;

    // No connected areas
    const areas = get(allAreasSelector);
    const positions = get(allPositionsState);
    const shapes = [...areas, ...positions];
    const flows = get(layoutFlowsSelector);

    if (shapes.length === disconnectedAreaIds.length) return false;

    // Atleast one connected intake/storage, delivery/storage and charging area per vehicle
    const vehicles = get(enabledVehiclesSelector);

    const connectedVehicles = vehicles.map((vehicle) => {
      const connectedIntakeShapes = shapes.filter(
        (x) =>
          (x.type === ShapeType.INTAKE || x.type === ShapeType.INTAKE_POSITION) &&
          !disconnectedAreaIds.includes(x.id) &&
          x.parameters.supportedVehicleIds.includes(vehicle.id),
      );
      const connectedDeliveryShapes = shapes.filter(
        (x) =>
          (x.type === ShapeType.DELIVERY || x.type === ShapeType.DELIVERY_POSITION) &&
          !disconnectedAreaIds.includes(x.id) &&
          x.parameters.supportedVehicleIds.includes(vehicle.id),
      );
      const connectedStorageShapes = shapes.filter(
        (x) =>
          (x.type === ShapeType.STORAGE || x.type === ShapeType.STORAGE_POSITION) &&
          !disconnectedAreaIds.includes(x.id) &&
          x.parameters.supportedVehicleIds.includes(vehicle.id),
      );
      const connectedChargingShapes = shapes.filter(
        (x) =>
          (x.type === ShapeType.CHARGING || x.type === ShapeType.CHARGING_POSITION) &&
          !disconnectedAreaIds.includes(x.id) &&
          x.parameters.supportedVehicleIds.includes(vehicle.id),
      );

      if (
        (connectedIntakeShapes.length === 0 && connectedStorageShapes.length === 0) ||
        (connectedDeliveryShapes.length === 0 && connectedStorageShapes.length === 0) ||
        (connectedIntakeShapes.length === 0 &&
          connectedDeliveryShapes.length === 0 &&
          connectedStorageShapes.length < 2) ||
        connectedChargingShapes.length === 0
      )
        return false;

      return true;
    });

    if (!connectedVehicles.every((x) => x === true)) return false;

    // Intake or delivery shape with flow but with no connection
    if (disconnectedFlowStopIds.length > 0) return false;

    // Intake or delivery shape with flow but no connection in 'direction'
    const intakeShapesWithFlow = shapes.filter(
      (shape) =>
        (shape.type === ShapeType.INTAKE || shape.type === ShapeType.INTAKE_POSITION) &&
        flowlessAreas.find((x) => x.id === shape.id) === undefined,
    );
    const deliveryShapesWithFlow = shapes.filter(
      (shape) =>
        (shape.type === ShapeType.DELIVERY || shape.type === ShapeType.DELIVERY_POSITION) &&
        flowlessAreas.find((x) => x.id === shape.id) === undefined,
    );
    if (
      incorrectlyConnectedShapeToRoadIds.filter(
        (id) =>
          intakeShapesWithFlow.find((intake) => intake.id === id) !== undefined ||
          deliveryShapesWithFlow.find((delivery) => delivery.id === id) !== undefined,
      ).length > 0
    )
      return false;

    if (
      getProcessIdsWithUnequalInboundOutboundFlows(shapes.filter(isProcessAreaOneEp), flows)
        .length > 0
    )
      return false;

    return true;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
