import { AreaAlignment, AreaDistribution, AreaEndpointDirection } from '@modules/common/types/shapes';

export const DEFAULT_RACK = {
  FRAMES_DEEP: 1,
  LEVELS: 5,
  LOADS_PER_SHELF: 1,
  LOAD_HEIGHT: 1400,
  POST_WIDTH: 100,
  POST_DEPTH: 100,
  OVERHANG_FRONT: 0,
  OVERHANG_BACK: 0,
  CLEARANCE_SIDE: 75,
  CLEARANCE_TOP: 75,
  CLEARANCE_IN_BETWEEN: 75,
  BEAM_HEIGHT: 50,
  FIRST_SHELF_HEIGHT: 0,
  AISLE_WIDTH: 1000,
};

export const MIN_RACK = {
  LEVELS: 2,
  FRAMES_DEEP: 1,
  LOADS_PER_SHELF: 1,
  LOAD_HEIGHT: 200,
  POST_WIDTH: 10,
  CLEARANCE_SIDE: 50,
  CLEARANCE_TOP: 50,
  CLEARANCE_IN_BETWEEN: 50,
  BEAM_HEIGHT: 10,
  FIRST_SHELF_HEIGHT: 0,
  AISLE_WIDTH: 0,
};

// NOTE: temp values to override rack area parameters until back-end safely handles otherwise
export const RACK_ALIGNMENT_OVERRIDE = AreaAlignment.CENTER;
// NOTE: the below makes the racking for 3D predicatable and leaves the gap value to be set on the rack itself instead of the area
export const RACK_DISTRIBUTION_OVERRIDE = AreaDistribution.EXTRA_SPACE_OVER_MARGIN;
export const RACK_MIN_GAP_OVERRIDE = 0;

export const DEFAULT_SIDE_LOADING = {
  END_POINT_DIRECTION: AreaEndpointDirection.HORIZONTAL,
  DELTA_X: 0,
  DELTA_Y: 0,
};
export const DEFAULT_LOAD_BOUNDING_BOX_DIMENSIONS = {
  width: 0,
  length: 0,
};
