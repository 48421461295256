import { Vector2 } from 'three';
import { Vector2d } from 'konva/lib/types';

import { DTShape } from '@modules/common/types/shapes';
import { isAngledHighwayShape } from '@modules/common/types/guards';
import { getSegmentIdContainingPoint } from '@modules/angledHighways/helpers';
import { CANVAS_TO_SHAPE_SCALE } from '@/modules/workspace/helpers/konva';

const COMBINED_ID_CONCATINATOR = '.';

export const createCombinedConnectionId = (shape: DTShape, mousePosition: Vector2d) => {
  const segmentId = isAngledHighwayShape(shape)
    ? getSegmentIdContainingPoint(
        shape,
        new Vector2(mousePosition.x, mousePosition.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE),
      )
    : undefined;

  return combineConnectionId(shape.id, segmentId);
};

export const combineConnectionId = (shapeId: string, segmentId?: number) =>
  [shapeId, segmentId].filter((item) => item !== undefined).join(COMBINED_ID_CONCATINATOR);

export const getShapeId = (id: string) => id.split(COMBINED_ID_CONCATINATOR)[0];

export const getSegmentId = (id: string) => {
  const idStr = id.split(COMBINED_ID_CONCATINATOR)[1];
  return idStr !== undefined ? Number(idStr) : undefined;
};
