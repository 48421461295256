import { isArea, isGroupable, supportsVehicleTypes } from '@/modules/common/helpers/shapes';
import { ShapeGroup } from '@/modules/common/types/shapeGroup';
import { ShapeType } from '@/modules/common/types/shapes';
import { useFlow } from '@/modules/flows/hooks/useFlow';
import { formatShapeType } from '@/store/recoil/floorplan/helper';
import { selectedShapesIdsState, selectedShapesState } from '@/store/recoil/shapes/selected';
import { HistoryManager } from '@recoil/history';
import { Node } from 'konva/lib/Node';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';
import { NOTIFICATION_TYPES, showNotification } from '../../../store/recoil/notification';
import { useGetRelatedFlowIdsOfShapeIds } from '../../flows/hooks/useGetRelatedFlowIdsOfShapeIds';
import { allGroupIdsState, selectedGroupIdsState, shapeGroupState } from '../atom';
import { generateNewGroupName } from '../helpers/generateNewGroupName';
import { allGroupsSelector } from '../selectors';
import { AreaShape } from '@recoil/shape';
import { isUnique } from '@modules/common/helpers/array';

const findGroupIdInParent = (thisNode: Node, shapeGroupIds: Set<string>): string => {
  if (!thisNode || thisNode.getType() === 'Stage') return null;
  const thisId = thisNode.id();
  if (shapeGroupIds.has(thisId)) return thisId;
  return findGroupIdInParent(thisNode.getParent(), shapeGroupIds);
};

export const useShapeGroup = () => {
  const { t } = useTranslation();
  const showNotificationFn = useSetRecoilState(showNotification);
  const getRelatedFlowIds = useGetRelatedFlowIdsOfShapeIds();
  const { getRelatedFlowIdsOfGroup, deleteFlows } = useFlow();

  const undoRedoGroup = useRecoilCallback(
    ({ set }) =>
      (shapeGroups: ShapeGroup[]) => {
        updateGroup(shapeGroups);
        set(
          allGroupIdsState,
          shapeGroups.map((shapeGroup) => shapeGroup.id),
        );
      },
    [],
  );

  const findParentShapeGroupId = useRecoilCallback(
    ({ snapshot }) =>
      async (node: Node) => {
        const shapeGroupIds = new Set(await snapshot.getPromise(allGroupIdsState));
        return findGroupIdInParent(node, shapeGroupIds);
      },
    [],
  );

  const findShapeGroupId = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeId: string): Promise<string> => {
        if (!shapeId) return null;
        const shapeGroups = await snapshot.getPromise(allGroupsSelector);
        for (let i = 0; i < shapeGroups.length; ++i) {
          const shapeGroup = shapeGroups[i];
          if (shapeGroup.children.indexOf(shapeId) > -1) return shapeGroup.id;
        }
        return null;
      },
    [],
  );

  const createGroupFromSelectedShapes = useRecoilCallback(
    ({ snapshot, set }) =>
      async (shapeType: ShapeType): Promise<ShapeGroup> => {
        if (!isGroupable(shapeType)) return;
        const selectedShapes = await snapshot.getPromise(selectedShapesState);
        const shapesToGroup = selectedShapes.filter(
          (item) => formatShapeType(item.type) === shapeType,
        );

        const relatedFlows = await getRelatedFlowIds(shapesToGroup.map((shape) => shape.id));
        if (relatedFlows.length > 0) {
          showNotificationFn({
            type: NOTIFICATION_TYPES.ERROR,
            message: t(
              'errors:grouping.grouping_flow_related_shapes',
              'Please remove any related flows before creating a group. Once the group is created, flows can be added to the group.',
            ),
          });
          return;
        }

        if (supportsVehicleTypes(shapeType)) {
          const vehicleTypes = selectedShapes
            .filter((shape) => isArea(shape.type) && supportsVehicleTypes(shape.type))
            .flatMap((shape: AreaShape) => shape.parameters.supportedVehicleIds);

          if (!isUnique(vehicleTypes)) {
            showNotificationFn({
              type: NOTIFICATION_TYPES.ERROR,
              message: t(
                'errors:grouping.common_vehicle_type',
                'Please set a common vehicle type before creating a group.',
              ),
            });
            return;
          }
        }

        const newGroupId = uuid();
        const children = shapesToGroup.map((shape) => shape.id);
        const newGroup: ShapeGroup = {
          id: newGroupId,
          name: generateNewGroupName(shapeType),
          children,
          type: shapeType,
        };
        updateGroup([newGroup]);
        set(selectedGroupIdsState, [newGroupId]);

        const oldGroups = await snapshot.getPromise(allGroupsSelector);
        const groups = [...oldGroups, newGroup];
        HistoryManager.track(`group created`, groups, oldGroups, undoRedoGroup);
        return newGroup;
      },
    [],
  );

  const createGroupFromShapeIds = useRecoilCallback(
    ({ snapshot, set }) =>
      async (newGroupType: ShapeType, shapesIds: string[]): Promise<ShapeGroup> => {
        if (shapesIds.length === 0) {
          return;
        }

        const newGroupId = uuid();
        const newGroup: ShapeGroup = {
          id: newGroupId,
          name: generateNewGroupName(newGroupType),
          children: shapesIds,
          type: newGroupType,
        };
        set(shapeGroupState(newGroupId), newGroup);
        set(allGroupIdsState, (ids) => [newGroupId, ...ids]);

        const oldGroups = await snapshot.getPromise(allGroupsSelector);
        const groups = [...oldGroups, newGroup];
        HistoryManager.track(`group created`, groups, oldGroups, undoRedoGroup);
        return newGroup;
      },
    [],
  );

  const updateGroup = useRecoilCallback(
    ({ set }) =>
      (shapeGroups: ShapeGroup[]) => {
        const newIds: string[] = [];
        shapeGroups.forEach((group) => {
          set(shapeGroupState(group.id), group);
          newIds.push(group.id);
        });
        set(allGroupIdsState, (ids) => [...new Set([...ids, ...newIds])]);
      },
    [],
  );

  const deleteGroups = useRecoilCallback(
    ({ set }) =>
      async (groupIds: string[]) => {
        if (groupIds.length === 0) return;

        // deselect all shapes / groups
        set(selectedShapesIdsState, []);
        set(selectedGroupIdsState, []);

        // delete flow of this group
        let flowIds = await Promise.all(
          groupIds.map((groupId) => getRelatedFlowIdsOfGroup(groupId)),
        );
        let flowIdsFlat = flowIds.flat();
        deleteFlows(flowIdsFlat);

        // update shape group ids collection
        set(allGroupIdsState, (prev) => {
          const ids = new Set(prev);
          groupIds.forEach((id) => ids.delete(id));
          return Array.from(ids);
        });
      },
    [],
  );

  const deleteShapeGroupOfShapeIds = useCallback(
    async (shapeIds: string[]) => {
      const groupIds = new Set<string>();
      const promises = shapeIds.map((shapeId) => findShapeGroupId(shapeId));
      const results = await Promise.all(promises);
      results.forEach((groupId) => {
        if (groupId) groupIds.add(groupId);
      });
      await deleteGroups(Array.from(groupIds));
    },
    [deleteGroups, findShapeGroupId],
  );

  const getAllShapeIds = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeGroupIds: string[]): Promise<Set<string>> => {
        const promises = shapeGroupIds.map((id) => snapshot.getPromise(shapeGroupState(id)));
        const results = await Promise.all(promises);
        const shapeIds = new Set(results.flatMap((shapeGroup) => shapeGroup.children));
        return shapeIds;
      },
    [],
  );

  return {
    createGroupFromSelectedShapes,
    createGroupFromShapeIds,
    updateGroup,
    deleteGroups,
    deleteShapeGroupOfShapeIds,
    findParentShapeGroupId,
    findShapeGroupId,
    getAllShapeIds,
  };
};
