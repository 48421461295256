import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadCarrierSelect } from '@/components/PropertiesPanel/layout/LoadCarrierSelect';
import { LoadCarrierSide } from '@/components/PropertiesPanel/layout/LoadCarrierSide';
import { LoadPlacement } from '@/components/PropertiesPanel/layout/LoadPlacement';
import { VehicleSelect } from '@/components/PropertiesPanel/layout/VehicleSelect';
import OperationTime from '@/components/PropertiesPanel/layout/process/OperationTime';
import ProcessWidth from '@/components/PropertiesPanel/layout/process/ProcessWidth';
import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';

export default function ProcessTwoEndPointPropery() {
  const { t } = useTranslation(['interface']);

  return (
    <Accordion
      title={t('interface:properties.highway.highway_title', 'Highway')}
      titleSx={{ textTransform: 'capitalize' }}
      sx={{ px: 2 }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        <PropertiesLabel i18nkey='common:width'>
          <ProcessWidth />
        </PropertiesLabel>

        <PropertiesLabel i18nkey='interface:properties.area.operation_time'>
          <OperationTime />
        </PropertiesLabel>

        <PropertiesLabel i18nkey='interface:properties.area.load_placement.label'>
          <LoadPlacement />
        </PropertiesLabel>

        <PropertiesLabel i18nkey='interface:properties.area.vehicle'>
          <VehicleSelect />
        </PropertiesLabel>

        <PropertiesLabel i18nkey='interface:properties.area.load_carrier_select.label'>
          <LoadCarrierSelect />
        </PropertiesLabel>

        <PropertiesLabel i18nkey='interface:properties.area.load_carrier_orientation.label'>
          <LoadCarrierSide />
        </PropertiesLabel>
      </Stack>
    </Accordion>
  );
}
