import { groupsAccordionTheme } from '@/modules/common/components/theme';
import { Accordion } from '@common/components/Accordion';
import { Stack, ThemeProvider } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { allGroupIdsState } from '../atom';
import { GroupItem } from './GroupItem';
import { allGroupsAmountSelector } from '../selectors';
import { InfoText } from '../../common/components/InfoText';

const GroupsPropertiesComponent = () => {
  const { t } = useTranslation('interface');
  const amountOfGroups = useRecoilValue(allGroupsAmountSelector);

  return (
    <Accordion
      title={t('interface:groups.header', 'Groups', { count: amountOfGroups })}
      unmountOnExit
      sx={{px: 2}}
    >
      <LayerPropertiesContent />
    </Accordion>
  );
};

const LayerPropertiesContent = () => {
  const { t } = useTranslation('interface');
  const groupIds = useRecoilValue(allGroupIdsState);

  return (
    <ThemeProvider theme={groupsAccordionTheme}>
      <Stack direction='column' spacing={0}>
        {groupIds.length === 0 && (
          <InfoText
            header={t('interface:groups.no_groups_info.header')}
            subHeader={t('interface:groups.no_groups_info.subheader')}
          />
        )}
        {groupIds.map((id) => (
          <GroupItem key={id} id={id} />
        ))}
      </Stack>
    </ThemeProvider>
  );
};

export const GroupsProperties = memo(GroupsPropertiesComponent);
