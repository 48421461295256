import { FlowStopType, LayoutFlow } from '@/modules/flows/types';
import { capitalizeFirstLetter } from '@helpers/string';
import { isPosition } from '@modules/common/helpers/shapes';
import { ShapeType } from '@modules/common/types/shapes';

/**
 * Migrate from flows without ShapeGroup to flows with ShapeGroup
 * FROM flow.intakeFlowStop: string
 * TO   flow.intakeFlowStop: { id: string, type: FlowStopType }
 * @param oldFlows
 * @returns
 */
export function reconcileFlowIncompatibilities(oldFlows: any): LayoutFlow[] {
  if (!oldFlows || oldFlows.length === 0) return [];
  const newFlows: LayoutFlow[] = [];

  for (let i = 0; i < oldFlows.length; ++i) {
    const newFlow: LayoutFlow = { ...oldFlows[i] };
    if (!oldFlows[i].intakeFlowStop.id) {
      const intakeId = oldFlows[i].intakeFlowStop as unknown as string;
      newFlow.intakeFlowStop = {
        id: intakeId,
        type: FlowStopType.AREA,
      };
    }
    if (!oldFlows[i].deliveryFlowStop.id) {
      const deliveryId = oldFlows[i].deliveryFlowStop as unknown as string;
      newFlow.deliveryFlowStop = {
        id: deliveryId,
        type: FlowStopType.AREA,
      };
    }
    newFlows.push(newFlow);
  }
  return newFlows;
}

export function setShapesDefaultNaming(shapes) {
  const indexes = {};
  Object.keys(ShapeType).forEach((e) => {
    indexes[ShapeType[e]] = 0;
  });

  shapes.forEach((shape) => {
    const { name } = shape;
    if (name.includes(' ')) {
      const splitName = name.split(' ');
      const type = formatShapeType(shape.type);
      if (splitName.length === 2 && Number.isInteger(parseInt(splitName[1]))) {
        const value = parseInt(splitName[1]);
        if (indexes.hasOwnProperty(type) && value > indexes[type]) {
          indexes[type] = value;
        }
      }
    }
  });

  Object.keys(indexes).forEach((e) => {
    sessionStorage.setItem(e, indexes[e] + 1);
  });
}

export function addCopy(shape) {
  const type = formatShapeType(shape.type);
  const index = parseInt(sessionStorage.getItem(type) ?? '1');
  sessionStorage.setItem(type, (index + 1).toString());
  return `${capitalizeFirstLetter(type)} ${index}`;
}

export function formatShapeType(type) {
  if (isPosition(type) || type === ShapeType.HIGHWAY_ANGLED) return type.split('_')[0];
  return type;
}
