import { BoundingBox } from '@/helpers/types';
import { WideLineSegment } from '@/modules/angledHighways/types';
import { wideLineSegmentToOrientedBoundingBox } from '@/modules/common/helpers/boundingBox';
import { isPosition } from '@/modules/common/helpers/shapes';
import { ObstacleShapePersisted, WallShapePersisted } from '@/modules/common/types/floorPlan';
import { ShapeType } from '@/modules/common/types/shapes';
import { addVehicleArea } from '@/modules/floorplanService/helpers/mapping/addVehicleArea';
import { addEndPointGenerationSettings } from './addEndPointGenerationSettings';
import { addIdGenerator, addMultiIdGenerator } from './addIdGenerators';
import { encodeIdWithVehicleId } from './idEncoder';
import {
  areaStackingMode,
  areaTypeToEndPointGenerationSettingsType,
  areaTypeToFPAreaType,
  boundingBoxToFpsRectangle,
  calcFpsLoadPositionAngle,
  endPointDiscType,
  fpsEndPointAngle,
  getFpsLoadCarriersBoundingBox,
  lineToFpsRectangle,
  positionTypeToFixedEndPointGenerationType,
} from './utils';
import { StorageType as StorageTypeType } from '@modules/common/types/storage';
import { addEndPointNeighborsMappingWithinArea } from './addEndPointNeighborsMappingWithinArea';

export const createObstacle = (
  obstacles,
  cutOutReferences,
  shape: ObstacleShapePersisted,
  workspaceBoundingBox: BoundingBox,
  material,
) => { 
  const rectangle = boundingBoxToFpsRectangle(shape.properties, shape.properties.r, workspaceBoundingBox);
  obstacles.push({
    name: shape.id,
    material,
    rectangle,
  });
  cutOutReferences.push({ name: shape.id, properties: shape.properties });
};

export const createWall = (
  obstacles,
  cutOutReferences,
  shape: WallShapePersisted,
  workspaceBoundingBox: BoundingBox,
  material,
) => {
  const { properties, parameters } = shape;
  const numberOfSegments = properties.controlPoints.length - 1;
  for (let i = 0; i < numberOfSegments; i++) {
    const line: WideLineSegment = {
      points: {
        start: properties.controlPoints[i].position,
        end: properties.controlPoints[i + 1].position,
      },
      width: parameters.width
    };
    const name = `${shape.id}.${i}`
    const rectangle = lineToFpsRectangle(line, workspaceBoundingBox);
    obstacles.push({
      name,
      material,
      rectangle,
    });
    cutOutReferences.push({ name, properties: wideLineSegmentToOrientedBoundingBox(line) });
  }
};

export const createArea = (
  vehicleSpec,
  idGenerators,
  shape,
  vehicleSize,
  loadTypes,
  workspaceBoundingBox,
) => {
  const shapeName = encodeIdWithVehicleId(shape.name, vehicleSpec.databaseId);
  const lpIdGenerator = `${shapeName}-LpIdGen`;
  const secondLpIdGenerator = `2_${shapeName}-LpIdGen`;
  const epIdGenerator = `${shapeName}-EpIdGen`;
  const shapeId = encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId);

  const { supportedLoadCarriersIds, loadCarrierOrientation, storageType } = shape.parameters;
  const { direction } = shape.parameters;
  const supportedLoadCarrierTypes = loadTypes.filter(({ id }) =>
    supportedLoadCarriersIds?.includes(id),
  );
  const { width: largestLoadCarrierWidth, length: largestLoadCarrierLength } =
    getFpsLoadCarriersBoundingBox(supportedLoadCarrierTypes);

  const endpointDirection =
    shape.parameters.storageProperty === undefined || shape.parameters.storageProperty === null
      ? null
      : shape.parameters.storageProperty.endpointDirection;

  let { type } = shape;
  if (isPosition(shape.type)) type = positionTypeToFixedEndPointGenerationType(type).toLowerCase();

  // NOTE: if rectangle longest side is along y-axis, make rectangle reflect it by adding 90 degrees to its angle property
  const rectangle = boundingBoxToFpsRectangle(
    shape.properties,
    shape.properties.r,
    workspaceBoundingBox,
  );
  const isHorizontal = shape.properties.width >= shape.properties.height;

  const stackingMode = areaStackingMode(
    areaTypeToFPAreaType(shape.type),
    shape.properties,
    direction,
    endpointDirection,
  );

  addVehicleArea(vehicleSpec, shapeId, shape.type, rectangle, stackingMode);

  const loadLengthIsLongestSide = largestLoadCarrierLength > largestLoadCarrierWidth;
  let loadPositionAngleRelativeToEndPoint = calcFpsLoadPositionAngle(
    loadCarrierOrientation,
    loadLengthIsLongestSide,
  );

  const endPointAngleRelativeToArea = fpsEndPointAngle(direction, isHorizontal);

  addEndPointGenerationSettings(
    vehicleSpec,
    shapeId,
    shape,
    epIdGenerator,
    lpIdGenerator,
    secondLpIdGenerator,
    areaTypeToEndPointGenerationSettingsType(type, shape.parameters?.loadPlacement),
    endPointDiscType(shape.type, shape.parameters.storageType),
    vehicleSize,
    supportedLoadCarrierTypes,
    largestLoadCarrierWidth,
    largestLoadCarrierLength,
    loadPositionAngleRelativeToEndPoint,
    direction,
    endPointAngleRelativeToArea,
  );
  addIdGenerator(idGenerators, epIdGenerator, shapeName);

  if (
    type !== ShapeType.CHARGING &&
    type !== ShapeType.PARKING &&
    type !== ShapeType.CHARGING_POSITION &&
    type !== ShapeType.PARKING_POSITION
  ) {
    if (storageType === StorageTypeType.RACK) {
      addIdGenerator(idGenerators, lpIdGenerator, shapeName);
    } else if (storageType === StorageTypeType.TWOSIDEDRACK) {
      addMultiIdGenerator(idGenerators, lpIdGenerator, shapeName);
      addMultiIdGenerator(idGenerators, secondLpIdGenerator, `2_${shapeName}`);
    } else {
      addIdGenerator(idGenerators, lpIdGenerator, shapeName);
    }
  }

  if (shape.parameters.storageType === StorageTypeType.RACK ||
    shape.parameters.storageType === StorageTypeType.TWOSIDEDRACK) {
    // TODO skip end point neighbor mapping when vehicle position is perpendicular
    // addEndPointNeighborsMappingWithinArea(vehicleSpec.gateMappingSettings.areaMappings, shapeId);
  }
};
