import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { allAngledHighwaysSelector } from '@/modules/angledHighways';
import { durationFromHours, durationToHours } from '@/modules/common/helpers/date';
import { FloorplanServiceOption } from '@/modules/floorplanService/enum';
import { simulationFlowsSelector } from '@/modules/flows/store/simulation/selector';
import { checkpointSetsSelector } from '@/modules/simulation/store/draft/checkpointSetsSelector';
import { dependentFlowsSelector } from '@/modules/simulation/store/draft/dependentFlowsSelector';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { allHighwaysSelector } from '@/store/recoil/shapes/highway';
import { range, updateItem } from '@modules/common/helpers/array';
import { hasUnsavedChangesSelector, useVersioning } from '@modules/floorplan';
import { createExportSettings } from '@modules/floorplanService/helpers/floorplanServiceHelpers';
import { useFloorPlanService } from '@modules/floorplanService/hooks';
import { SIMULATION_DURATION_MIN, VEHICLE_MIN } from '@modules/simulation/helpers/constants';
import {
  CheckpointSet,
  Simulation,
  SimulationDraft,
  SimulationStatus,
  SimulationType,
} from '@modules/simulation/helpers/types';
import {
  currentSimulationIdState,
  currentSimulationState,
  durationSelector,
  flowsSelector,
} from '@modules/simulation/store/draft';
import { isLoadingSelector } from '@modules/simulation/store/module';
import { simulationIdsSelector, simulationSelector } from '@modules/simulation/store/simulations';
import { showNotification } from '@recoil/notification';
import { NOTIFICATION_TYPES } from '@recoil/notification/atom';
import { allVehiclesState, vehicleCapacitySelector } from '@/modules/vehicles';
import { convertSimulationFlowToDraftFlow } from '../helpers/simulation';
import { useSimulationApi } from './useSimulationApi';

export const useSimulationDraftCallbacks = () => {
  const { getFloorPlanDefinition } = useFloorPlanService();
  const { bumpVersion } = useVersioning();
  const { runGroup: runRemote } = useSimulationApi();
  const { t } = useTranslation('interface');
  const showNotificationFn = useSetRecoilState(showNotification);
  const { updateUserPreference } = useUpdateUserPreferences();

  const closeSimulationEditPanel = useRecoilCallback(
    ({ set }) =>
      () => {
        set(currentSimulationIdState, null);
        set(currentSimulationState, null);
      },
    [],
  );

  const updateDraftSimulation = useRecoilCallback(
    ({ set, snapshot }) =>
      async (config: Partial<SimulationDraft> | ((state: SimulationDraft) => SimulationDraft)) => {
        let simulation = await snapshot.getPromise(currentSimulationState);

        if (typeof config === 'function') {
          simulation = config(simulation);
        } else {
          simulation = { ...simulation, ...config };
        }

        set(currentSimulationState, simulation);
      },
    [],
  );

  const updateDraftSimulationFlows = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const simulationFlows = await snapshot.getPromise(simulationFlowsSelector);
        const hours = Math.floor(durationToHours(await snapshot.getPromise(durationSelector)));
        const draftFlows = convertSimulationFlowToDraftFlow(hours, simulationFlows);
        set(flowsSelector, draftFlows);
      },
    [],
  );

  /**
   * filter out dependent flows where not both flows are in the simulation
   */
  const updateDraftSimulationDependentFlows = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const dependentFlows = await snapshot.getPromise(dependentFlowsSelector);
        const flowNames = (await snapshot.getPromise(flowsSelector)).map((flow) => flow.name);
        const usedDependentFlows = dependentFlows.filter(
          (dependentFlow) =>
            flowNames.includes(dependentFlow.flowFromName) &&
            flowNames.includes(dependentFlow.flowToName),
        );
        set(dependentFlowsSelector, usedDependentFlows);
      },
    [],
  );

  const updateDraftSimulationCheckpointSets = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const highways = await snapshot.getPromise(allHighwaysSelector);
        const angledHighways = await snapshot.getPromise(allAngledHighwaysSelector);

        const checkpointSets: CheckpointSet[] = highways
          .filter((road) => road.parameters.vehicleLimit > -1)
          .map((road) => ({
            maxVehiclesInSet: road.parameters.vehicleLimit,
            highways: [road.name],
          }));

        const angledCheckpointSets: CheckpointSet[] = angledHighways
          .filter((road) => road.parameters.vehicleLimit > -1)
          .map((road) => {
            const { properties, parameters } = road;
            const numberOfSegments = properties.controlPoints.length - 1;
            const segmentNames = [];
            for (let i = 0; i < numberOfSegments; i++) {
              segmentNames.push(`${road.name}.${i}`);
            }

            return {
              maxVehiclesInSet: parameters.vehicleLimit,
              highways: segmentNames,
            };
          });

        set(checkpointSetsSelector, [...checkpointSets, ...angledCheckpointSets]);
      },
    [],
  );

  const replaceFakeSimulation = useRecoilCallback(
    ({ set, reset }) =>
      (fakeSimulation: Simulation, simulation: Simulation) => {
        set(simulationSelector(simulation.id), {
          ...simulation,
          details: {
            ...simulation.details,
            floorPlanVersion: fakeSimulation.details.floorPlanVersion,
          },
        });
        reset(simulationSelector(fakeSimulation.id));
        set(simulationIdsSelector, (state) =>
          updateItem(state, simulation.id, (item) => item == fakeSimulation.id),
        );
      },
    [],
  );

  const removeFakeSimulation = useRecoilCallback(
    ({ set, reset }) =>
      (fakeSimulation: Simulation) => {
        reset(simulationSelector(fakeSimulation.id));
        set(simulationIdsSelector, (state) => state.filter((item) => item !== fakeSimulation.id));
      },
    [],
  );

  const run = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        try {
          set(isLoadingSelector, true);
          const draft = await snapshot.getPromise(currentSimulationState);
          let definition = null;

          if (!draft.generatedFloorPlanId) {
            definition = await getFloorPlanDefinition(
              createExportSettings(FloorplanServiceOption.All),
            );
          }

          if (await snapshot.getPromise(hasUnsavedChangesSelector)) {
            await bumpVersion();
          }

          const fakeSimulation = {
            id: uuid(),
            ...draft,
            type:
              draft.vehicleTypes.at(0).range.length > 1
                ? SimulationType.GROUP
                : SimulationType.SINGLE,
            created: new Date(),
            errors: [],
            status: SimulationStatus.SAVING,
            progress: 0,
            isSelected: false,
            details: {
              vehicleTypes: draft.vehicleTypes.map((item) => ({
                name: item.name,
                range: item.range,
              })),
              floorPlanVersion: draft.version,
            },
          };

          set(simulationSelector(fakeSimulation.id), fakeSimulation);
          set(simulationIdsSelector, (state) => [fakeSimulation.id, ...state]);

          runRemote(draft.floorPlanId, {
            ...draft,
            dynamicSplines: true,
            definition,
          })
            .then((runningSimulation) => replaceFakeSimulation(fakeSimulation, runningSimulation))
            .catch((error) => {
              console.log(error);
              removeFakeSimulation(fakeSimulation);
            });

          closeSimulationEditPanel();
        } catch (error) {
          console.error(error);
        }
      },
    [
      runRemote,
      t,
      bumpVersion,
      closeSimulationEditPanel,
      updateDraftSimulationFlows,
      replaceFakeSimulation,
      removeFakeSimulation,
    ],
  );

  const prepareAndRun = useCallback(async () => {
    await updateDraftSimulationFlows();
    await updateDraftSimulationDependentFlows();
    await updateDraftSimulationCheckpointSets();
    await run();
  }, [
    updateDraftSimulationFlows,
    updateDraftSimulationDependentFlows,
    updateDraftSimulationCheckpointSets,
    run,
  ]);

  const updateDuration = useRecoilCallback(
    ({ set }) =>
      async (hours: number) => {
        if (hours >= SIMULATION_DURATION_MIN) {
          set(durationSelector, durationFromHours(hours));
          updateUserPreference(UserPreferenceName.SIMULATION_DURATION, hours);
        }
      },
    [updateUserPreference],
  );

  const updateVehicleRange = useCallback(
    (name: string, from: number, to: number) => {
      updateDraftSimulation((state) => ({
        ...state,
        vehicleTypes: updateItem(
          state.vehicleTypes,
          { range: range(from, to) },
          (item) => item.name === name,
        ),
      }));
    },
    [updateDraftSimulation],
  );

  const updateVehicleCount = useCallback(
    (name: string, count: number) => {
      if (count >= VEHICLE_MIN) {
        updateVehicleRange(name, count, count);
      }
    },
    [updateVehicleRange],
  );

  const updateVehicleLoadTime = useCallback(
    (name: string, value: number) => {
      updateDraftSimulation((state) => ({
        ...state,
        vehicleTypes: updateItem(
          state.vehicleTypes,
          { loadTime: value },
          (item) => item.name === name,
        ),
      }));
      updateUserPreference(UserPreferenceName.SIMULATION_LOAD_TIME, value);
    },
    [updateDraftSimulation, updateUserPreference],
  );

  const updateVehicleUnloadTime = useCallback(
    (name: string, value: number) => {
      updateDraftSimulation((state) => ({
        ...state,
        vehicleTypes: updateItem(
          state.vehicleTypes,
          { unloadTime: value },
          (item) => item.name === name,
        ),
      }));
      updateUserPreference(UserPreferenceName.SIMULATION_UNLOAD_TIME, value);
    },
    [updateDraftSimulation, updateUserPreference],
  );

  const toggleIsVehicleRange = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const { vehicleTypes } = await snapshot.getPromise(currentSimulationState);
        const allVehicles = await snapshot.getPromise(allVehiclesState);

        const vehicleTypesWithRanges = await Promise.all(
          vehicleTypes.map(async (vehicle) => {
            const { id } = allVehicles.find((item) => item.name === vehicle.name);
            const capacity = await snapshot.getPromise(vehicleCapacitySelector(id));

            if (capacity === 0) {
              showNotificationFn({
                type: NOTIFICATION_TYPES.WARNING,
                message: t(
                  'errors:simulation.no_capacity',
                  'No parking or charging space available for vehicle',
                ),
              });
            } else if (capacity === 1) {
              showNotificationFn({
                type: NOTIFICATION_TYPES.WARNING,
                message: t(
                  'errors:simulation.range_not_available',
                  'Cant use range, only one vehicle position available',
                ),
              });
            }

            if (vehicle.range.length > 1 || capacity <= 1) {
              return {
                ...vehicle,
                range: [1],
              };
            }

            return {
              ...vehicle,
              range: Array.from({ length: Math.min(capacity, 5) }, (_, i) => i + 1),
            };
          }),
        );

        set(currentSimulationState, (state) => ({
          ...state,
          vehicleTypes: vehicleTypesWithRanges,
        }));
      },
    [],
  );

  return {
    prepareAndRun,
    closeSimulationEditPanel,
    updateDraftSimulation,
    updateDuration,
    updateVehicleCount,
    updateVehicleRange,
    updateVehicleLoadTime,
    updateVehicleUnloadTime,
    toggleIsVehicleRange,
  };
};
