import { ReactNode, Ref, SyntheticEvent, forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';

import { InfoTooltip, Props as InfoTooltipProps } from '@common/components/InfoTooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  children: ReactNode;
  title?: ReactNode;
  titleKey?: string;
  titleSx?: SxProps;
  InfoTooltipProps?: Omit<InfoTooltipProps, 'children'>;
  sx?: SxProps;
  onChange?: (expanded: boolean) => void;
  fontSize?: string;
  beforeIcon?: any;
  afterIcon?: any;
  expanded?: boolean;
  defaultExpanded?: boolean;
  className?: string;
  unmountOnExit?: boolean;
};

export const Accordion = forwardRef(
  (
    {
      children,
      sx,
      title,
      titleKey,
      InfoTooltipProps,
      onChange,
      titleSx,
      fontSize = '14px',
      afterIcon,
      beforeIcon,
      expanded,
      defaultExpanded,
      className,
      unmountOnExit = false,
    }: Props,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();
    const summary = (
      <Stack direction='row' justifyContent='left' gap='12px' alignItems='center' width='100%'>
        {beforeIcon}
        <Typography sx={titleSx} component='span'>
          {titleKey ? t(titleKey) : title}
        </Typography>
        {afterIcon}
      </Stack>
    );

    const onInnerChange = useCallback(
      (e: SyntheticEvent, expanded: boolean) => {
        onChange?.(expanded);
      },
      [onChange],
    );

    return (
      <MuiAccordion
        ref={ref}
        sx={sx}
        defaultExpanded={defaultExpanded}
        expanded={expanded}
        onChange={onInnerChange}
        className={className}
        TransitionProps={{ unmountOnExit }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ '& .MuiTypography-root': { fontSize } }}
        >
          {InfoTooltipProps ? <InfoTooltip {...InfoTooltipProps}>{summary}</InfoTooltip> : summary}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MuiAccordion>
    );
  },
);
