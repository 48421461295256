import { useRecoilValue } from 'recoil';
import useImage from 'use-image';
import shapeState, { AreaShape } from '@recoil/shape';
import { Group, Image } from 'react-konva';
import { areaDirectionToAngle } from '@/store/recoil/workspace';
import { areaIconAutoScaleAttr } from './consts';
import { SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';
import { useError } from './useError';
import { AreaError } from '@/modules/common/types/shapes';

export type Props = {
  id: string;
};

export const Icons = ({ id }: Props) => {
  const [arrowImage] = useImage('/arrow-down.svg');
  const shape = useRecoilValue(shapeState(id)) as AreaShape;
  const currentError = useError(id);

  if (
    !shape ||
    !arrowImage ||
    (currentError && currentError !== AreaError.PartialGenerationFailed)
  ) {
    return null;
  }

  return (
    <Group
      x={shape.properties.x * SHAPE_TO_CANVAS_SCALE}
      y={shape.properties.y * SHAPE_TO_CANVAS_SCALE}
      rotation={-shape.properties.r}
    >
      <Image
        image={arrowImage}
        x={(shape.properties.width * SHAPE_TO_CANVAS_SCALE) / 2}
        y={(shape.properties.height * SHAPE_TO_CANVAS_SCALE) / 2}
        rotation={areaDirectionToAngle(shape.parameters.direction)}
        offsetX={arrowImage.width / 2}
        offsetY={arrowImage.height / 2}
        listening={false}
        strokeEnabled={false}
        {...areaIconAutoScaleAttr}
      />
    </Group>
  );
};
