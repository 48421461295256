import { selector } from 'recoil';

import { PROCESS_MIN_WIDTH } from '@/modules/common/constants/shapes';
import { unitConverterSelector } from '@/store/recoil/workspace';
import {
  findParameterValue
} from '@modules/common/helpers/shapes';
import { isProcessAreaTwoEp } from '@modules/common/types/guards';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import {
  shapeParameter
} from '@recoil/shape';
import { selectedShapesState } from '@recoil/shapes/selected';

export const processWidthState = selector<number>({
  key: 'propertyPanelProcessWidthState',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'width'),
  set: ({ get, set }, value: number) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;
      if (
        !valueInMillimeter ||
        Number.isNaN(valueInMillimeter) ||
        valueInMillimeter < PROCESS_MIN_WIDTH
      ) {
        const newParams = {
          ...shape.parameters,
          width: PROCESS_MIN_WIDTH,
        };
        set(shapeParameter(shape.id), newParams);
      } else {
        const newParams = {
          ...shape.parameters,
          width: valueInMillimeter,
        };
        set(shapeParameter(shape.id), newParams);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
