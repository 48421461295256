import { UserMenu } from '@/components/NavigationBar/UserMenu';
import { projectNameSelector } from '@/modules/floorplan';
import { OverflowTypography } from '@common/components/OverflowTypography';
import { Stack } from '@mui/material';
import { memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { AppMode } from '../types/general';
import { TopBar } from './TopBar';
import { Logo } from '@/components/NavigationBar/Logo/Logo';

export const DashboardTopBarComponent = () => {
  const projectName = useRecoilValue(projectNameSelector);
  const title = useMemo(() => {
    const appName = 'DigitalTwin';

    if (projectName) {
      return `${appName} / ${projectName}`;
    }

    return appName;
  }, [projectName]);

  return (
    <TopBar sx={{ zIndex: 2 }}>
      <Stack
        gap={2}
        justifyContent='space-between'
        sx={{ alignItems: 'center', flexDirection: 'row', width: '100%' }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            height: '100%',
            width: '10%',
          }}
        >
          <Logo displayTooltip={false}/>
        </Stack>
        <OverflowTypography sx={{ fontSize: '14px', textAlign: 'center' }}>
          {title}
        </OverflowTypography>
        <Stack sx={{ width: '10%' }} alignItems='flex-end'>
          <UserMenu appMode={AppMode.FloorplanAdmin} />
        </Stack>
      </Stack>
    </TopBar>
  );
};

export const DashboardTopBar = memo(DashboardTopBarComponent);
