import { Vector2d } from 'konva/lib/types';
import { MathUtils, Vector2, Vector3 } from 'three';

import { BoundingBox } from '@/helpers/types';
import { convertPointToBottomLeftPosition } from '@/modules/artefacts/helpers/convert';
import { getMiddlePoint } from '@/modules/common/helpers/math';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';
import {
  getSegment,
  getSegmentAttachPoint,
  getSegmentAttachPointRelativeTo,
  getSegmentContainingPoint,
  getSegmentIdContainingPoint,
} from '@modules/angledHighways/helpers';
import { getBoxAttachPointRelativeTo, getShapeAttachPoint } from '@modules/common/helpers/shapes';
import { DistantConnection } from '@modules/common/types/connections';
import { isAngledHighwayShape, isHighwayShape } from '@modules/common/types/guards';
import { DTShape } from '@modules/common/types/shapes';
import { combineConnectionId, getSegmentId } from '@modules/connections/common/connectionId';
import { AreaShape, PositionShape } from '@recoil/shape';

export type ConnectionPosition = {
  rot: number;
  from: string;
  to: string;
  bubblePosition: Vector2d;
  fromPosition: Vector2d;
  toPosition: Vector2d;
};
export const updateConnectionPositioning = (
  existingConnection: DistantConnection,
  fromShape: DTShape,
  toShape: DTShape,
): DistantConnection => {
  if (fromShape.id === toShape.id) return null;

  const { from, to } = getAttachPoints(existingConnection, fromShape, toShape);

  return {
    ...existingConnection,
    fromPosition: from,
    toPosition: to,
    bubblePosition: calculateMidPoint(from, to),
    rot: calculateAngle(from, to),
  };
};

export const getAttachPoints = (
  existingConnection: DistantConnection,
  fromShape: DTShape,
  toShape: DTShape,
) => {
  let fromPoint = getShapeAttachPoint(fromShape as AreaShape | PositionShape);
  let toPoint = getShapeAttachPoint(toShape as AreaShape | PositionShape);

  if (isAngledHighwayShape(fromShape)) {
    const segmentId = getSegmentId(existingConnection.from);
    if (segmentId !== undefined) {
      const segment = getSegment(fromShape, segmentId);
      if (segment) {
        const newFromPoint = getSegmentAttachPointRelativeTo(
          segment,
          new Vector2(toPoint.x, toPoint.y),
        );

        if (newFromPoint) {
          fromPoint = newFromPoint;
        } else {
          fromPoint = getMiddlePoint(segment.points.start, segment.points.end).multiplyScalar(
            SHAPE_TO_CANVAS_SCALE,
          );
        }
      }
    }
  }

  if (isAngledHighwayShape(toShape)) {
    const segmentId = getSegmentId(existingConnection.to);
    if (segmentId !== undefined) {
      const segment = getSegment(toShape, segmentId);
      if (segment) {
        const newToPoint = getSegmentAttachPointRelativeTo(
          segment,
          new Vector2(fromPoint.x, fromPoint.y),
        );

        if (newToPoint) {
          toPoint = newToPoint;
        } else {
          toPoint = getMiddlePoint(segment.points.start, segment.points.end).multiplyScalar(
            SHAPE_TO_CANVAS_SCALE,
          );
        }
      }
    }
  }

  if (isHighwayShape(fromShape)) {
    const newFromPoint = getBoxAttachPointRelativeTo(
      fromShape.properties,
      new Vector2(toPoint.x, toPoint.y),
    );
    if (newFromPoint) fromPoint = newFromPoint;
  }

  if (isHighwayShape(toShape)) {
    const newToPoint = getBoxAttachPointRelativeTo(
      toShape.properties,
      new Vector2(fromPoint.x, fromPoint.y),
    );

    if (newToPoint) toPoint = newToPoint;
  }

  return {
    from: fromPoint,
    to: toPoint,
  };
};

export const getEndConnectionPosition = (
  fromShape: DTShape,
  toShape: DTShape,
  mousePosition: Vector2d,
  fromConnectionId: string,
): ConnectionPosition => {
  if (fromShape.id === toShape.id) return null;

  const { from, to, fromSegment, toSegment } = getAttachPointsAndSegments(
    fromShape,
    toShape,
    mousePosition,
    fromConnectionId,
  );

  return {
    from: combineConnectionId(fromShape.id, fromSegment),
    to: combineConnectionId(toShape.id, toSegment),
    fromPosition: from,
    toPosition: to,
    bubblePosition: calculateMidPoint(from, to),
    rot: calculateAngle(from, to),
  };
};

export const getAttachPointsAndSegments = (
  fromShape: DTShape,
  toShape: DTShape,
  mousePosition: Vector2d,
  fromConnectionId: string,
) => {
  let fromPoint = getShapeAttachPoint(fromShape as AreaShape | PositionShape);
  let toPoint = getShapeAttachPoint(toShape as AreaShape | PositionShape);
  let fromSegmentId: number | undefined;
  let toSegmentId: number;

  if (isAngledHighwayShape(fromShape)) {
    fromSegmentId = getSegmentId(fromConnectionId);
    if (fromSegmentId !== undefined) {
      const segment = getSegment(fromShape, fromSegmentId);
      if (segment) {
        const newFromPoint = getSegmentAttachPointRelativeTo(
          segment,
          new Vector2(toPoint.x, toPoint.y),
        );

        if (newFromPoint) {
          fromPoint = newFromPoint;
        } else {
          fromPoint = getMiddlePoint(segment.points.start, segment.points.end).multiplyScalar(
            SHAPE_TO_CANVAS_SCALE,
          );
        }
      }
    }
  }

  if (isAngledHighwayShape(toShape)) {
    toSegmentId = getSegmentIdContainingPoint(
      toShape,
      new Vector2(mousePosition.x, mousePosition.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE),
    );
    if (toSegmentId !== undefined) {
      const segment = getSegment(toShape, toSegmentId);
      if (segment) {
        const newToPoint = getSegmentAttachPointRelativeTo(
          segment,
          new Vector2(fromPoint.x, fromPoint.y),
        );

        if (newToPoint) {
          toPoint = newToPoint;
        } else {
          toPoint = getMiddlePoint(segment.points.start, segment.points.end).multiplyScalar(
            SHAPE_TO_CANVAS_SCALE,
          );
        }
      }
    }
  }

  if (isHighwayShape(fromShape)) {
    const newFromPoint = getBoxAttachPointRelativeTo(
      fromShape.properties,
      new Vector2(toPoint.x, toPoint.y),
    );

    if (newFromPoint) fromPoint = newFromPoint;
  }

  if (isHighwayShape(toShape)) {
    const newToPoint = getBoxAttachPointRelativeTo(
      toShape.properties,
      new Vector2(fromPoint.x, fromPoint.y),
    );

    if (newToPoint) toPoint = newToPoint;
  }

  return {
    from: fromPoint,
    fromSegment: fromSegmentId,
    to: toPoint,
    toSegment: toSegmentId,
  };
};

const calculateMidPoint = (fromPosition: Vector2d, toPosition: Vector2d): Vector2d => {
  const pointA = new Vector2(fromPosition.x, fromPosition.y);
  const pointB = new Vector2(toPosition.x, toPosition.y);
  let dir = pointB.clone().sub(pointA);
  const len = dir.length();
  dir = dir.normalize().multiplyScalar(len * 0.5);
  return pointA.clone().add(dir);
};

const calculateAngle = (fromPosition: Vector2d, toPosition: Vector2d): number => {
  const pointA = new Vector3(fromPosition.x, fromPosition.y, 0);
  const pointB = new Vector3(toPosition.x, toPosition.y, 0);
  const dir = pointB.clone().sub(pointA).normalize();
  const angle = MathUtils.radToDeg(dir.angleTo(new Vector3(1, 0, 0)));
  return (dir.y < 0 ? 360 - angle : angle) + 90;
};

export const getSourceAttachPoint = (shape: DTShape, point: Vector2d) => {
  if (isAngledHighwayShape(shape)) {
    return getSegmentAttachPoint(getSegmentContainingPoint(shape, point));
  }

  return getShapeAttachPoint(shape as AreaShape | PositionShape);
};

export const convertToBottomLeftCoordinateSystem = (connections: readonly DistantConnection[], boundingBox: BoundingBox) => connections.map((item) => toBottomLeftOrigin(item, boundingBox));

const toBottomLeftOrigin = (connection: DistantConnection, workspaceBoundingBox: BoundingBox): DistantConnection => {
  const from = convertPointToBottomLeftPosition(
    new Vector2(Math.round(connection.fromPosition.x * 10), Math.round(connection.fromPosition.y * 10)), workspaceBoundingBox);
  const to = convertPointToBottomLeftPosition(
    new Vector2(Math.round(connection.toPosition.x * 10), Math.round(connection.toPosition.y * 10)), workspaceBoundingBox);
  const bubble = convertPointToBottomLeftPosition(
    new Vector2(Math.round(connection.bubblePosition.x * 10), Math.round(connection.bubblePosition.y * 10)), workspaceBoundingBox);
  console.log(bubble)
  return {
    ...connection,
    fromPosition: new Vector2(from.x, from.y),
    toPosition: new Vector2(to.x, to.y),
    bubblePosition: new Vector2(bubble.x, bubble.y)
  }
};
