import { groupIdSelector, projectIdSelector } from '@/modules/floorplan';
import { ObjectDeleteContract } from '@/modules/visualization/class';
import { useDigitalTwinFleetTrackerAdaptor } from '@/modules/visualization/hooks/useDigitalTwinFleetTrackerAdaptor';
import { useFleetTrackerWebglApi } from '@/modules/visualization/hooks/useFleetTrackerWebglApi';
import { DeleteObject } from '@/modules/visualization/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

export function CompatibilityFix() {
  const { drawFloorPlan } = useDigitalTwinFleetTrackerAdaptor();
  const { floorPlanVersionId } = useParams();
  const { t } = useTranslation();
  const { sendMessage } = useFleetTrackerWebglApi();

  const fixOffset = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        // delete all safety zone line objects
        const deleteObject: DeleteObject = {
          ObjectName: 'safety*',
        };
        sendMessage(new ObjectDeleteContract([deleteObject]));

        // redraw layout
        const projectId = await snapshot.getPromise(projectIdSelector);
        const groupId = await snapshot.getPromise(groupIdSelector);
        drawFloorPlan(projectId, groupId, floorPlanVersionId, true);
      },
    [],
  );

  return (
    <Accordion defaultExpanded={false} elevation={1}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 2 }}>
        <Typography>{t('interface:visualization.properties.compatibility_fix')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '8px 0', px: 2 }}>
        <Stack spacing={2}>
          <Button onClick={fixOffset} sx={{ textTransform: 'none' }}>
            Fix layout offset
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
