
import { KonvaEventObject } from 'konva/lib/Node';
import { useCallback, useRef } from 'react';
import { Snapshot, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { useArtefacts } from '@/modules/artefacts';
import { ControlPoint } from '@/modules/common/types/shapes';
import { useConnections } from '@/modules/connections';
import { useWorkspaceStore } from '@/modules/workspace/store';
import {
  retreiveCPIdFromCPAnchorId,
  retreiveNeighbourIdsFromPotentialCPAnchorId,
  snapToClosestAxis
} from '@modules/angledHighways/helpers';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { useAutoSave } from '@modules/floorplan';
import {
  CANVAS_TO_SHAPE_SCALE,
  ElementName,
  getRelativeMousePosition,
} from '@modules/workspace/helpers/konva';
import { clampInsideWorkspace } from '@modules/workspace/helpers/shape';
import { KEYCODE, keyboardState } from '@recoil/input';
import shapeAtom from '@recoil/shape/atom';
import { sizeSelector } from '@recoil/workspace';
import { Vector2 } from 'three';
import { useControlPointCallbacks } from './useControlPointCallbacks';
import { useControlPointHistoryTracking } from './useControlPointHistoryTracking';

export const useSelectMouseHandlerWithPoints = () => {
  const { insertControlPoint, updateControlPointPos } = useControlPointCallbacks();
  const { trackControlPointHistory } = useControlPointHistoryTracking();
  const { save } = useAutoSave();
  const { updateConnections } = useConnections();
  const { update: updateArtefacts } = useArtefacts();
  
  const insertingControlPointMetaData = useRef<{
    newControlPointId: string;
    originalControlPoints: ControlPoint[];
    shapeId: string;
    width: number;
  } | null>(null);

  const updatingControlPointMetaData = useRef<{
    controlPointId: string;
    originalControlPoints: ControlPoint[];
    shapeId: string;
    width: number;
    hasMoved: boolean;
  } | null>(null);

  const controlPointUpdater = useCallback(
    async (
      e: KonvaEventObject<MouseEvent>,
      snapshot: Snapshot,
      highwayId: string,
      controlPointId: string,
      highwayWidth: number,
    ) => {
      let { x, y } = getRelativeMousePosition(e);
      const mousePosition = new Vector2(
        Math.round(x * CANVAS_TO_SHAPE_SCALE),
        Math.round(y * CANVAS_TO_SHAPE_SCALE),
      );

      let pos = mousePosition

      // snap position for edge controlpoints to neighbor point
      const pressedKey = await snapshot.getPromise(keyboardState);
      if (pressedKey === KEYCODE.SHIFT) { 
        const controlPoints = updatingControlPointMetaData.current.originalControlPoints
        const controlPoint = controlPoints.find(controlPoint => controlPoint.id === controlPointId)
        if (controlPoint.prev === null) {
          const referencePoint = controlPoints[1]
          pos = snapToClosestAxis(referencePoint.position, mousePosition);
        } else if (controlPoint.next === null) {
          const referencePoint = controlPoints[controlPoints.length - 2]
          pos = snapToClosestAxis(referencePoint.position, mousePosition);
        }
      }
      const workspaceSize = await snapshot.getPromise(sizeSelector);
      const clampedPos = clampInsideWorkspace(pos, workspaceSize, highwayWidth / 2);
      updateControlPointPos(highwayId, controlPointId, clampedPos);
    },
    [updateControlPointPos],
  );

  const onSelectMouseDownWithPointsShape = useRecoilCallback(
    ({ snapshot, set }) =>
      async (e: KonvaEventObject<MouseEvent>) => {
        const targetId = e.target.id();
        const targetName = e.target.name();

        if (targetName === ElementName.HIGHWAY_CONTROL_POINT) {
          // mouseDown on established control point

          const controlPointId = retreiveCPIdFromCPAnchorId(targetId);
          // const highwayInEditModeId = await snapshot.getPromise(highwayInEditModeIdState);
          const highwayInEditModeId = useWorkspaceStore.getState().shapeInEditModeIdState
          const highway = (await snapshot.getPromise(
            shapeAtom(highwayInEditModeId),
          )) as AngledHighwayShape;

          updatingControlPointMetaData.current = {
            shapeId: highwayInEditModeId,
            controlPointId,
            hasMoved: false,
            originalControlPoints: highway.properties.controlPoints,
            width: highway.parameters.width,
          };

          set(
            shapeAtom(highwayInEditModeId),
            (current: AngledHighwayShape): AngledHighwayShape => ({
              ...current,
              isDrawing: true,
            }),
          );
        } else if (targetName === ElementName.HIGHWAY_POTENTIAL_CONTROL_POINT) {
          // mouseDown on potential (unestablished) control point

          let { x, y } = getRelativeMousePosition(e);
          const position = new Vector2(
            Math.round(x * CANVAS_TO_SHAPE_SCALE),
            Math.round(y * CANVAS_TO_SHAPE_SCALE),
          );
          const shapeId = useWorkspaceStore.getState().shapeInEditModeIdState;
          const highway = (await snapshot.getPromise(shapeAtom(shapeId))) as AngledHighwayShape;
          const { prevCPId, nextCPId } = retreiveNeighbourIdsFromPotentialCPAnchorId(targetId);

          insertingControlPointMetaData.current = {
            newControlPointId: uuid(),
            originalControlPoints: highway.properties.controlPoints,
            width: highway.parameters.width,
            shapeId,
          };

          insertControlPoint(
            insertingControlPointMetaData.current.shapeId,
            insertingControlPointMetaData.current.newControlPointId,
            prevCPId,
            nextCPId,
            position,
          );

          set(
            shapeAtom(shapeId),
            (current: AngledHighwayShape): AngledHighwayShape => ({
              ...current,
              isDrawing: true,
            }),
          );
        }
      },
    [insertControlPoint],
  );

  const onSelectMouseMoveWithPointsShape = useRecoilCallback(
    ({ snapshot }) =>
      (e: KonvaEventObject<MouseEvent>) => {
        if (insertingControlPointMetaData.current?.newControlPointId) {
          controlPointUpdater(
            e,
            snapshot,
            insertingControlPointMetaData.current.shapeId,
            insertingControlPointMetaData.current.newControlPointId,
            insertingControlPointMetaData.current.width,
          );

          return;
        }

        if (updatingControlPointMetaData.current) {
          if (!updatingControlPointMetaData.current.hasMoved) {
            updatingControlPointMetaData.current = {
              ...updatingControlPointMetaData.current,
              hasMoved: true,
            };
          }

          controlPointUpdater(
            e,
            snapshot,
            updatingControlPointMetaData.current.shapeId,
            updatingControlPointMetaData.current.controlPointId,
            updatingControlPointMetaData.current.width,
          );
        }
      },
    [],
  );

  const onSelectMouseUpWithPointsShape = useRecoilCallback(
    ({ snapshot, set }) =>
      async (e: KonvaEventObject<MouseEvent>) => {
        if (insertingControlPointMetaData.current?.newControlPointId) {
          controlPointUpdater(
            e,
            snapshot,
            insertingControlPointMetaData.current.shapeId,
            insertingControlPointMetaData.current.newControlPointId,
            insertingControlPointMetaData.current.width,
          );

          set(
            shapeAtom(insertingControlPointMetaData.current.shapeId),
            (current: AngledHighwayShape): AngledHighwayShape => ({
              ...current,
              isDrawing: false,
            }),
          );

          const highway = (await snapshot.getPromise(
            shapeAtom(insertingControlPointMetaData.current.shapeId),
          )) as AngledHighwayShape;

          trackControlPointHistory(
            {
              shapeId: insertingControlPointMetaData.current.shapeId,
              value: highway.properties.controlPoints,
            },
            {
              shapeId: highway.id,
              value: insertingControlPointMetaData.current.originalControlPoints,
            },
          );

          updateConnections([highway.id])
          updateArtefacts([highway.id])
          save();
          insertingControlPointMetaData.current = null;
        } else if (updatingControlPointMetaData.current) {
          if (updatingControlPointMetaData.current.hasMoved) {
            controlPointUpdater(
              e,
              snapshot,
              updatingControlPointMetaData.current.shapeId,
              updatingControlPointMetaData.current.controlPointId,
              updatingControlPointMetaData.current.width,
            );

            set(
              shapeAtom(updatingControlPointMetaData.current.shapeId),
              (current: AngledHighwayShape): AngledHighwayShape => ({
                ...current,
                isDrawing: false,
              }),
            );

            const highway = (await snapshot.getPromise(
              shapeAtom(updatingControlPointMetaData.current.shapeId),
            )) as AngledHighwayShape;

            trackControlPointHistory(
              {
                shapeId: updatingControlPointMetaData.current.shapeId,
                value: highway.properties.controlPoints,
              },
              {
                shapeId: updatingControlPointMetaData.current.shapeId,
                value: updatingControlPointMetaData.current.originalControlPoints,
              },
            );

            updateConnections([highway.id])
            updateArtefacts([highway.id])
          }

          save();
          updatingControlPointMetaData.current = null;
        }
      },
    [controlPointUpdater, trackControlPointHistory, save, updateConnections, updateArtefacts],
  );

  const onDoubleClickWithPointsShape = useRecoilCallback(
    ({ set }) =>
      (e: KonvaEventObject<MouseEvent>) => {
        const targetId = e.target.id();
        useWorkspaceStore.getState().setShapeInEditModeIdState(targetId)
      },
    [],
  );

  return {
    insertingControlPointMetaData,
    updatingControlPointMetaData,
    onSelectMouseDownWithPointsShape,
    onSelectMouseMoveWithPointsShape,
    onSelectMouseUpWithPointsShape,
    onDoubleClickWithPointsShape,
  };
};
