import { LayerNames } from '@/modules/common/types/layers';
import { ProcessAreaOneEp, ShapeType } from '@/modules/common/types/shapes';
import { shapeGroupState } from '@/modules/shapeGroups';
import { unique } from '@modules/common/helpers/array';
import {
  activeFlowState,
  layoutAllFlowIds,
  layoutFlow,
  layoutFlowsSelector,
} from '@modules/flows/store/layout';
import {
  DEFAULT_FLOW_LOADS_AMOUNT,
  DEFAULT_FLOW_VEHICLE_LIMIT,
} from '@modules/flows/store/layout/constants';
import {
  invisibleElementSelector,
  layersShowSelector,
  removeInvisibleElementsSelector,
} from '@modules/layers';
import { Button } from '@mui/material';
import shapeAtom, { AreaShape, PositionShape, areaParameters } from '@recoil/shape';
import { toolButtonState } from '@recoil/tool';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';
import { NOTIFICATION_TYPES, showNotification } from '../../../../store/recoil/notification';
import shapesSelector from '../../../../store/recoil/shapes/shapesSelector';
import { useActiveArrowCleanUp } from '../../../workspace/hooks/useActiveArrowCleanUp';
import { FLOW_SOURCE_SHAPE_TYPES, getValidFlowTargetShapeTypes } from '../../consts';
import { FlowStopType, LayoutFlow } from '../../types';
import { useShapeDisabling } from './useShapeDisabling';
import { createProcessRelatedFlowsMap } from '@/modules/common/helpers/shapes';

export const useLayoutFlowsCallbacks = () => {
  const showNotificationFn = useSetRecoilState(showNotification);
  const { enableAllShapes, enableOnlyShapesOfTypes, enableOnlyShapesOfTypesAndVehicleTypes } =
    useShapeDisabling();
  const cleanUpActiveArrow = useActiveArrowCleanUp();

  const endActiveFlow = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(activeFlowState);
        cleanUpActiveArrow();
        enableAllShapes();
      },
    [enableAllShapes],
  );

  const createDefaultFlowName = useRecoilCallback(
    ({ snapshot }) =>
      async (sourceName: string, targetName: string): Promise<string> => {
        const baseCandidate = `${sourceName} - ${targetName}`;
        const flows = await snapshot.getPromise(layoutFlowsSelector);
        const takenNames = new Set([...flows.map((flow) => flow.name)]);

        if (!takenNames.has(baseCandidate)) return baseCandidate;

        let suffixCandidate = 1;
        let fallbackNameCandidate = `${baseCandidate} - ${suffixCandidate}`;
        while (takenNames.has(fallbackNameCandidate)) {
          suffixCandidate++;
          fallbackNameCandidate = `${baseCandidate} - ${suffixCandidate}`;
        }

        return fallbackNameCandidate;
      },
  );

  const startFlow = useRecoilCallback(
    ({ set, snapshot }) =>
      async (sourceId: string, type: FlowStopType) => {
        let sourceName = '';

        if (type === FlowStopType.AREA_GROUP)
          sourceName = (await snapshot.getPromise(shapeGroupState(sourceId)))?.name;
        else if (type === FlowStopType.AREA) {
          sourceName = (await snapshot.getPromise(shapeAtom(sourceId)))?.name;
        }

        set(activeFlowState, {
          id: uuid(),
          name: `${sourceName} - ?`,
          totalNumLoads: DEFAULT_FLOW_LOADS_AMOUNT,
          sourceName,
          intakeFlowStop: {
            id: sourceId,
            type,
          },
          deliveryFlowStop: null,
          vehicleLimit: DEFAULT_FLOW_VEHICLE_LIMIT,
        });
      },
    [],
  );

  const endFlow = useRecoilCallback(
    ({ set, snapshot }) =>
      async (deliveryId: string, type: FlowStopType) => {
        // store flow
        const activeFlow = await snapshot.getPromise(activeFlowState);

        let sourceName = '';
        let targetName = '';

        if (activeFlow.intakeFlowStop?.type === FlowStopType.AREA_GROUP)
          sourceName = (await snapshot.getPromise(shapeGroupState(activeFlow.intakeFlowStop.id)))?.name;
        else if (activeFlow.intakeFlowStop?.type === FlowStopType.AREA) {
          sourceName = (await snapshot.getPromise(shapeAtom(activeFlow.intakeFlowStop.id)))?.name;
        }

        if (type === FlowStopType.AREA_GROUP)
          targetName = (await snapshot.getPromise(shapeGroupState(deliveryId)))?.name;
        else if (type === FlowStopType.AREA) {
          targetName = (await snapshot.getPromise(shapeAtom(deliveryId)))?.name;
        }

        const newFlow: LayoutFlow = {
          ...activeFlow,
          name: await createDefaultFlowName(sourceName, targetName),
          deliveryFlowStop: {
            id: deliveryId,
            type,
          },
        };
        set(layoutFlow(newFlow.id), newFlow);
        set(layoutAllFlowIds, (prev) => [...prev, newFlow.id]);
        endActiveFlow();
      },
    [enableAllShapes, endActiveFlow],
  );

  const selectFlowTool = useRecoilCallback(
    ({ set }) =>
      async () =>
        set(toolButtonState, 'flow'),
    [],
  );

  const isValidSource = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string, flowSourceType: FlowStopType) => {
        const isGroup = flowSourceType === FlowStopType.AREA_GROUP;

        // shape type validity
        const source = isGroup
          ? await snapshot.getPromise(shapeGroupState(id))
          : await snapshot.getPromise(shapeAtom(id));

        if (!FLOW_SOURCE_SHAPE_TYPES.includes(source.type)) return false;

        // process flow amount limits
        if ([ShapeType.PROCESS_ONE_EP, ShapeType.PROCESS_ONE_EP_POSITION].includes(source.type)) {
          // TODO: how to handle process groups. Should they be supported ? if so, how is the appropriate operation time defined and how to retreive it
          if (isGroup) {
            setTimeout(() => {
              showNotificationFn({
                type: NOTIFICATION_TYPES.ERROR,
                message: t('errors:flow.process_group_unsupported'),
              });
            }, 100);

            return false;
          }

          const relatedFlowsMap = createProcessRelatedFlowsMap(
            [source as ProcessAreaOneEp],
            await snapshot.getPromise(layoutFlowsSelector),
          );

          const relatedFlows = relatedFlowsMap.get(source.name);
          if (relatedFlows && relatedFlows.outbound.length > 0) {
            setTimeout(() => {
              showNotificationFn({
                type: NOTIFICATION_TYPES.ERROR,
                message: t('errors:flow.process_flow_limit_reached'),
              });
            }, 100);

            return false;
          }
        }

        return true;
      },
    [],
  );

  const getActiveFlowValidTargetTypes = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const activeFlow = await snapshot.getPromise(activeFlowState);
        let sourceType: ShapeType = ShapeType.NONE;
        if (activeFlow.intakeFlowStop.type === FlowStopType.AREA)
          sourceType = (await snapshot.getPromise(shapeAtom(activeFlow.intakeFlowStop.id))).type;
        else
          sourceType = (await snapshot.getPromise(shapeGroupState(activeFlow.intakeFlowStop.id)))
            .type;

        return getValidFlowTargetShapeTypes(sourceType);
      },
    [],
  );

  const getActiveFlowValidTargetVehicleTypes = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const activeFlow = await snapshot.getPromise(activeFlowState);
        if (activeFlow.intakeFlowStop.type === FlowStopType.AREA) {
          const area = await snapshot.getPromise(areaParameters(activeFlow.intakeFlowStop.id));
          return area.supportedVehicleIds;
        }

        if (activeFlow.intakeFlowStop.type === FlowStopType.AREA_GROUP) {
          const group = await snapshot.getPromise(shapeGroupState(activeFlow.intakeFlowStop.id));
          const shapes = (await snapshot.getPromise(shapesSelector(group.children))) as (
            | AreaShape
            | PositionShape
          )[];
          const vehicleTypes = shapes.flatMap((item) => item.parameters.supportedVehicleIds);
          return unique(vehicleTypes);
        }
      },
    [],
  );

  const areVehicleTypesValid = useRecoilCallback(
    ({ snapshot }) =>
      async (
        sourceFlowType: FlowStopType,
        sourceId: string,
        targetFlowType: FlowStopType,
        targetId: string,
      ) => {
        let sourceVehicleType: string = null;
        let targetVehicleType: string = null;

        if (sourceFlowType === FlowStopType.AREA) {
          const sourceShapeParameters = await snapshot.getPromise(areaParameters(sourceId));
          sourceVehicleType = sourceShapeParameters.supportedVehicleIds.at(0);
        }

        if (sourceFlowType === FlowStopType.AREA_GROUP) {
          const group = await snapshot.getPromise(shapeGroupState(sourceId));
          const shapes = (await snapshot.getPromise(shapesSelector(group.children))) as (
            | AreaShape
            | PositionShape
          )[];
          sourceVehicleType = shapes.flatMap((item) => item.parameters.supportedVehicleIds).at(0);
        }

        if (targetFlowType === FlowStopType.AREA) {
          const shape = (await snapshot.getPromise(shapeAtom(targetId))) as
            | AreaShape
            | PositionShape;
          targetVehicleType = shape.parameters.supportedVehicleIds.at(0);
        }

        if (targetFlowType === FlowStopType.AREA_GROUP) {
          const group = await snapshot.getPromise(shapeGroupState(targetId));
          const shapes = (await snapshot.getPromise(shapesSelector(group.children))) as (
            | AreaShape
            | PositionShape
          )[];
          targetVehicleType = shapes.flatMap((item) => item.parameters.supportedVehicleIds).at(0);
        }

        return targetVehicleType === sourceVehicleType;
      },
    [],
  );

  const unHideFlow = useRecoilCallback(
    ({ set }) =>
      async (flowId: string) => {
        set(layersShowSelector(LayerNames.FLOWS), true);
        set(removeInvisibleElementsSelector, [flowId]);
      },
    [],
  );

  const isValidTarget = useRecoilCallback(
    ({ snapshot }) =>
      async (targetId: string, flowTargetType: FlowStopType) => {
        const isGroup = flowTargetType === FlowStopType.AREA_GROUP;

        const [existingFlows, activeFlow] = await Promise.all([
          snapshot.getPromise(layoutFlowsSelector),
          snapshot.getPromise(activeFlowState),
        ]);

        // Duplicated flow
        const duplicateFlow = existingFlows.find(
          (flow) =>
            flow.intakeFlowStop.id === activeFlow.intakeFlowStop.id &&
            flow.deliveryFlowStop.id === targetId,
        );
        if (duplicateFlow) {
          const [flowsLayerIsVisible, duplicateFlowIsNotExplicitlyHidden] = await Promise.all([
            snapshot.getPromise(layersShowSelector(LayerNames.FLOWS)),
            snapshot.getPromise(invisibleElementSelector(duplicateFlow.id)),
          ]);

          const showActionBtn = !flowsLayerIsVisible || !duplicateFlowIsNotExplicitlyHidden;

          setTimeout(() => {
            showNotificationFn({
              type: NOTIFICATION_TYPES.ERROR,
              message: t('errors:flow.duplicate_flow.drawing'),
              action: showActionBtn && (
                <Button
                  color='inherit'
                  size='small'
                  onClick={() => {
                    unHideFlow(duplicateFlow.id);
                  }}
                >
                  {t('common:unhide')}
                </Button>
              ),
            });
          }, 100);

          return false;
        }

        // Vehicle types
        if (
          !(await areVehicleTypesValid(
            activeFlow.intakeFlowStop.type,
            activeFlow.intakeFlowStop.id,
            flowTargetType,
            targetId,
          ))
        ) {
          setTimeout(() => {
            showNotificationFn({
              type: NOTIFICATION_TYPES.ERROR,
              message: t('errors:flow.different_vehicle_types'),
            });
          }, 100);

          return false;
        }

        // shape type validity
        const target = isGroup
          ? await snapshot.getPromise(shapeGroupState(targetId))
          : await snapshot.getPromise(shapeAtom(targetId));
        const targetType: ShapeType = target.type;
        const validTargetTypes = await getActiveFlowValidTargetTypes();
        if (!validTargetTypes.includes(targetType)) return false;

        // process flow amount limits
        if ([ShapeType.PROCESS_ONE_EP, ShapeType.PROCESS_ONE_EP_POSITION].includes(target.type)) {
          // TODO: how to handle process groups. Should they be supported ? if so, how is the appropriate operation time defined and how to retreive it
          if (isGroup) {
            setTimeout(() => {
              showNotificationFn({
                type: NOTIFICATION_TYPES.ERROR,
                message: t('errors:flow.process_group_unsupported'),
              });
            }, 100);

            return false;
          }

          const relatedFlowsMap = createProcessRelatedFlowsMap(
            [target as ProcessAreaOneEp],
            await snapshot.getPromise(layoutFlowsSelector),
          );

          const relatedFlows = relatedFlowsMap.get(target.name);
          if (relatedFlows && relatedFlows.inbound.length > 0) {
            setTimeout(() => {
              showNotificationFn({
                type: NOTIFICATION_TYPES.ERROR,
                message: t('errors:flow.process_flow_limit_reached'),
              });
            }, 100);

            return false;
          }
        }

        return true;
      },
    [getActiveFlowValidTargetTypes, showNotificationFn, areVehicleTypesValid, unHideFlow],
  );

  const enableTargetShapesExclusively = useRecoilCallback(
    () => async () => {
      const validTargetTypes = await getActiveFlowValidTargetTypes();
      const validVehicleTypes = await getActiveFlowValidTargetVehicleTypes();

      enableOnlyShapesOfTypesAndVehicleTypes(validTargetTypes, validVehicleTypes);
    },
    [
      getActiveFlowValidTargetTypes,
      enableOnlyShapesOfTypesAndVehicleTypes,
      getActiveFlowValidTargetVehicleTypes,
    ],
  );

  const enableSourceShapesExclusively = useCallback(
    () => enableOnlyShapesOfTypes(FLOW_SOURCE_SHAPE_TYPES),
    [enableOnlyShapesOfTypes],
  );

  return {
    startFlow,
    endActiveFlow,
    enableTargetShapesExclusively,
    enableSourceShapesExclusively,
    isValidTarget,
    isValidSource,
    selectFlowTool,
    endFlow,
  };
};
