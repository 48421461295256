import { useSetRecoilState } from 'recoil';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { t } from 'i18next';
import { useTypeGuard } from '@/modules/common/hooks/useTypeGuard';

export const useFloorplanTypeGuard = () => {
  const showNotificationFn = useSetRecoilState(showNotification);
  const { validateKeys } = useTypeGuard();

  const requiredKeys = [
    'id',
    'hasUnsavedChanges',
    'project',
    'groupId',
    'created',
    'lastUpdated',
    'additionalData',
    'version',
    'lastUpdatedBy',
  ];

  const isValidFloorplan = (floorplan: object) => {
    if (!floorplan) {
      return false;
    }

    if (!validateKeys(floorplan, requiredKeys)) {
      showNotificationFn({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('errors:floorplan.type_check'),
      });

      return false;
    }

    return true;
  };

  return { isValidFloorplan };
};
