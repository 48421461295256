import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { selector } from 'recoil';
import { allGroupIdsState, shapeGroupState } from '../atom';
import { isArea } from '../../common/helpers/shapes';

export const allAreaGroupIdsSelector = selector<string[]>({
  key: 'shapegroup/allAreaIds',
  get: ({ get }) => {
    const out = get(allGroupIdsState).filter((groupId) =>
      isArea(get(shapeGroupState(groupId)).type),
    );
    return out;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
