import { KonvaEventObject } from 'konva/lib/Node';
import { forwardRef } from 'react';
import { Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';

import { ElementName } from '@modules/workspace/helpers/konva';
import { draggableShapeSelector } from '@recoil/workspace/draggableShapesSelector';

type ProxyShapeProps = {
  id: string;
  onMouseEnter: (evt: KonvaEventObject<MouseEvent>) => void;
  onMouseLeave: (evt: KonvaEventObject<MouseEvent>) => void;
  x: number;
  y: number;
  width: number;
  height: number;
  angle?: number;
  offsetX?: number;
  offsetY?: number;
};
type Ref = (el: any) => void;

/* TRANSPARENT RECT FOR HANDLING EVENTS */
export const ShapeProxy = forwardRef<Ref, ProxyShapeProps>(
  (
    { id, onMouseEnter, onMouseLeave, x, y, width, height, angle = 0, offsetX = 0, offsetY = 0 },
    ref: Ref,
  ) => {
    const draggable = useRecoilValue(draggableShapeSelector(id));

    return (
      <Rect
        name={ElementName.SHAPE_PROXY}
        id={id}
        ref={ref}
        x={x}
        y={y}
        width={width}
        height={height}
        rotation={angle}
        offsetX={offsetX}
        offsetY={offsetY}
        strokeScaleEnabled={false}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDragMove={() => {}} // to remove konva warning
        draggable={draggable}
      />
    );
  },
);
