import { LayerNames } from '@/modules/common/types/layers';
import { layersShowSelector } from '@/modules/layers';
import { isArea, isDrawable, isPlaceable, isRoad } from '@modules/common/helpers/shapes';
import { ShapeType } from '@modules/common/types/shapes';
import { ToolState } from '@modules/common/types/tools';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import workspaceAtom from '../workspace/atom';
import toolAtom, { ToolAtom } from './atom';

const toolButtonState = selector<ToolAtom>({
  key: 'toolButtonState',
  get: ({ get }) => get(toolAtom),
  set: ({ set, get }, newValueIn) => {
    let newValue = newValueIn;
    // select cursor when user deselect any tool
    if (!newValue || newValue === 'cursor') {
      newValue = 'cursor';

      set(workspaceAtom, {
        ...get(workspaceAtom),
        previousShapeType: get(workspaceAtom).shapeType,
        shapeType: ShapeType.NONE,
        previousToolState: get(workspaceAtom).toolState,
        toolState: ToolState.SELECT,
      });

      // pan tool
    } else if (newValue === ToolState.PAN) {
      set(workspaceAtom, {
        ...get(workspaceAtom),
        previousShapeType: get(workspaceAtom).shapeType,
        shapeType: ShapeType.NONE,
        previousToolState: get(workspaceAtom).toolState,
        toolState: ToolState.PAN,
      });

      // for drawing tool (area/intake/storage/delivery/highway/highway_angled...)
    } else if (isDrawable(newValue)) {
      set(workspaceAtom, {
        ...get(workspaceAtom),
        previousShapeType: get(workspaceAtom).shapeType,
        shapeType: newValue as ShapeType,
        previousToolState: get(workspaceAtom).toolState,
        toolState: ToolState.DRAW,
      });

      if (isArea(newValue)) {
        set(layersShowSelector(LayerNames.AREAS), true);
      } else if (isRoad(newValue)) {
        set(layersShowSelector(LayerNames.HIGHWAYS), true);
      } else if (newValue === ShapeType.WALL) {
        set(layersShowSelector(LayerNames.WALLS), true);
      } else if (newValue === ShapeType.OBSTACLE) {
        set(layersShowSelector(LayerNames.OBSTACLES), true);
      }

      // for positions
    } else if (isPlaceable(newValue)) {
      set(workspaceAtom, {
        ...get(workspaceAtom),
        previousShapeType: get(workspaceAtom).shapeType,
        shapeType: newValue as ShapeType,
        previousToolState: get(workspaceAtom).toolState,
        toolState: ToolState.PLACE,
      });

      if (isArea(newValue)) {
        set(layersShowSelector(LayerNames.AREAS), true);
      }
    } else if (newValue === ToolState.FLOW) {
      set(workspaceAtom, {
        ...get(workspaceAtom),
        previousShapeType: get(workspaceAtom).shapeType,
        shapeType: ShapeType.NONE,
        previousToolState: get(workspaceAtom).toolState,
        toolState: ToolState.FLOW,
      });

      set(layersShowSelector(LayerNames.FLOWS), true);
    } else if (newValue === ToolState.DISTCON) {
      set(workspaceAtom, {
        ...get(workspaceAtom),
        previousShapeType: get(workspaceAtom).shapeType,
        shapeType: ShapeType.NONE,
        previousToolState: get(workspaceAtom).toolState,
        toolState: ToolState.DISTCON,
      });

      set(layersShowSelector(LayerNames.CONNECTIONS), true);
    }
    set(toolAtom, newValue);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default toolButtonState;
