import {
  unitConverterSelector,
  unitSelector,
  unitValueSelector,
} from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { highwayWidthSelector } from '@components/PropertiesPanel/store/highway';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useConnections } from '@modules/connections';
import { CONTEXT, contextState } from '@recoil/input';
import SelectedShapesIdsState from '@recoil/shapes/selected/selectedShapesIdsState';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useAutoSave } from '../../../modules/floorplan';
import { UserPreferenceName } from '../../../modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { ANGLED_HIGHWAY_MIN_WIDTH } from '@/modules/common/constants/shapes';

function HighwayWidth() {
  const { updateConnections } = useConnections();
  const [width, setWidth] = useRecoilState(highwayWidthSelector);
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation('interface');
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { save } = useAutoSave();

  const onChange = useRecoilCallback(
    ({ snapshot }) =>
      async (value: number) => {
        try {
          const valueInMillimeter = await snapshot.getPromise(
            unitConverterSelector(value as number),
          );
          if (valueInMillimeter < ANGLED_HIGHWAY_MIN_WIDTH) {
            throw new Error(`The minimum width for this shape type is ${ANGLED_HIGHWAY_MIN_WIDTH}`);
          }
          setWidth(value);
          await updateConnections(await snapshot.getPromise(SelectedShapesIdsState));
          save();
          updateUserPreference(UserPreferenceName.HIGHWAY_WIDTH, value);
        } catch (e) {
          return { error: e.message };
        }
      },
    [setWidth, updateUserPreference, save, updateConnections],
  );

  return (
    <NumberInput
      value={useRecoilValue(unitValueSelector(width))}
      unit={t(`interface:settings.units.${unit}`, unit)}
      sx={{ width: '100%' }}
      disabled={mode !== WorkspaceMode.EDITABLE}
      onChange={onChange}
      onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
    />
  );
}

export default HighwayWidth;
