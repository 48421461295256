// import { useArtefacts } from '@/modules/artefacts/common/hooks';
import { checkForShapeCollisions } from '@/modules/common/helpers/boundingBox';
import { useNewShape } from '@/modules/common/hooks/useNewShape';
import { useFlow } from '@/modules/flows/hooks/useFlow';
import { existingFlowsIdsSelector } from '@/modules/flows/store/layout';
import { useShapeGroup } from '@/modules/shapeGroups/hooks/useShapeGroup';
import { HistoryManager } from '@/store/recoil/history';
import { DTShape } from '@/store/recoil/shape';
import { deleteAreaIdsSelector } from '@/store/recoil/shapes/area';
import { deleteObstacleIdsSelector } from '@/store/recoil/shapes/obstacle';
import { deletePositionIdsSelector } from '@/store/recoil/shapes/positions';
import { deleteWallIdsSelector } from '@/store/recoil/shapes/wall';
import { useArtefacts } from '@modules/artefacts';
import { UndoRedoState, useConnections, useUndoRedoConnections } from '@modules/connections';
import { useRecoilCallback } from 'recoil';
import shapeAtom from '../../../store/recoil/shape/atom';
import { allShapesSelector } from '../../../store/recoil/shapes';
import { deleteHighwayIdsSelector } from '../../../store/recoil/shapes/highway';
import { deleteAngledHighwayIdsSelector } from '../../angledHighways';
import { allGroupIdsState, allGroupsSelector } from '../../shapeGroups';

type DeleteShapeData = {
  shapes: DTShape[];
  shapeGroupIds: string[];
  layoutFlowIds: string[];
  connections: UndoRedoState;
  deletedIds: string[];
};

export const useDeleteShape = () => {
  const { getRelatedFlowIdsOfShapes, deleteFlows } = useFlow();
  const { deleteGroups, findShapeGroupId } = useShapeGroup();
  const { deleteConnections } = useConnections();
  const { getUndoRedoState, restoreUndoRedoState } = useUndoRedoConnections();
  const { replaceShapes } = useNewShape();
  const { remove: deleteArtefacts } = useArtefacts();
  const { update: updateArtefacts } = useArtefacts();

  const undoRedoShapeDelete = useRecoilCallback(
    ({ set }) =>
      async (data: DeleteShapeData) => {
        await replaceShapes(data.shapes);
        updateArtefacts(data.deletedIds);

        if (data.shapeGroupIds.length > 0) {
          set(allGroupIdsState, data.shapeGroupIds);
        }

        await restoreUndoRedoState(data.connections);

        if (data.layoutFlowIds.length > 0) {
          HistoryManager.stopTracking();
          set(existingFlowsIdsSelector, data.layoutFlowIds);
          setTimeout(() => {
            HistoryManager.startTracking();
          }, 0);
        }
      },
    [restoreUndoRedoState, replaceShapes],
  );

  const deleteMultipleShapes = useRecoilCallback(
    ({ set, reset, snapshot }) =>
      async (deleteIds: string[]) => {
        // save state for undo (history tracking)
        const currentShapes = await snapshot.getPromise(allShapesSelector);
        const newShapes = currentShapes.filter((item) => !deleteIds.includes(item.id));

        // groups
        const currentGroups = await snapshot.getPromise(allGroupsSelector);
        const deleteGroupIds = (
          await Promise.all(deleteIds.map((shapeId) => findShapeGroupId(shapeId)))
        ).filter((id) => id !== null);
        const currentGroupIds = currentGroups.map((item) => item.id);
        const newGroupIds = currentGroupIds.filter((id) => !deleteGroupIds.includes(id));

        // flow ids
        const currentLayoutFlowIds = await snapshot.getPromise(existingFlowsIdsSelector);
        const layoutFlowIdsToDelete = await getRelatedFlowIdsOfShapes(deleteIds);
        const newLayoutFlowIds = currentLayoutFlowIds.filter(
          (id) => !layoutFlowIdsToDelete.includes(id),
        );

        // connections
        const { newConnectionState, oldConnectionState } = await getUndoRedoState(deleteIds);

        // overlapping shapes
        const overlappingShapeIds = checkForShapeCollisions(
          currentShapes.filter((item) => deleteIds.includes(item.id)),
          newShapes, 
        ).map(shape => shape.id)     

        const oldState: DeleteShapeData = {
          connections: oldConnectionState,
          shapes: currentShapes,
          shapeGroupIds: currentGroupIds,
          layoutFlowIds: currentLayoutFlowIds,
          deletedIds: deleteIds,
        };
        const newState: DeleteShapeData = {
          connections: newConnectionState,
          shapes: newShapes,
          shapeGroupIds: newGroupIds,
          layoutFlowIds: newLayoutFlowIds,
          deletedIds: [],
        };
        HistoryManager.track(`Delete shapes`, newState, oldState, undoRedoShapeDelete);
        HistoryManager.stopTracking();

        // remove association to shape
        deleteFlows(layoutFlowIdsToDelete);
        await deleteGroups(deleteGroupIds);
        await deleteConnections(deleteIds);
        deleteArtefacts(deleteIds);

        // remove ids from collection
        set(deleteAreaIdsSelector, deleteIds);
        set(deleteHighwayIdsSelector, deleteIds);
        set(deleteAngledHighwayIdsSelector, deleteIds);
        set(deletePositionIdsSelector, deleteIds);
        set(deleteWallIdsSelector, deleteIds);
        set(deleteObstacleIdsSelector, deleteIds);
        setTimeout(() => {
          HistoryManager.startTracking();
        }, 0);

        // reset shape atom
        deleteIds.forEach((id) => reset(shapeAtom(id)));

        // update artefacts for shapes that overlapped deleted shapes
        updateArtefacts(overlappingShapeIds)
      },
    [deleteConnections, deleteGroups, getUndoRedoState, deleteArtefacts, updateArtefacts],
  );

  return {
    deleteMultipleShapes,
  };
};
