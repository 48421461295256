import { Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';
import { useFloorPlanGroupApi } from '@modules/api/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FloorPlanGroup } from '@modules/floorplanAdmin/helpers/types';
import { useFloorPlanFile } from '@modules/floorplan';

export function DownloadFloorplanButton() {
  const { projectId } = useParams();
  const { fetchAll: fetchAllGroups } = useFloorPlanGroupApi();
  const { downloadFloorPlan } = useFloorPlanFile();
  const [selected, setSelected] = useState('');
  const [groups, setGroups] = useState<FloorPlanGroup[]>([]);

  const loadFloorPlanGroups = useCallback(
    async () => setGroups(await fetchAllGroups(projectId)),
    [fetchAllGroups, projectId],
  );
  const onChange = useCallback(
    async (e: SelectChangeEvent) => {
      const group = groups.find((item) => item.id === e.target.value);
      downloadFloorPlan(projectId, group.id, group.floorPlanId);
      setSelected('');
    },
    [downloadFloorPlan, groups, projectId],
  );

  useEffect(() => {
    loadFloorPlanGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Typography>Download selected floor plan</Typography>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Select onChange={onChange} value={selected}>
          {groups.map((item) => (
            <MenuItem value={item.id}> {item.name} </MenuItem>
          ))}
        </Select>
      </Grid>
    </>
  );
}
