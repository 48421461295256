import { Status } from '@modules/floorplanService/enum';
import { atom } from 'recoil';
import { FpsAtom } from '../types';

export const fpsAtom = atom<FpsAtom>({
  key: 'fpsAtom',
  default: {
    files: null,
    status: null,
    warnings: []
  },
});

export const validationStatus = atom<Status>({
  key: 'validationStatus',
  default: Status.Idle,
});
