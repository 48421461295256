import { ObjectValues } from '@/helpers/types';
import { ShapeType } from '../common/types/shapes';

export const hasFlowInteractivity = (type) =>
  [
    ShapeType.INTAKE,
    ShapeType.INTAKE_POSITION,
    ShapeType.DELIVERY,
    ShapeType.DELIVERY_POSITION,
    ShapeType.STORAGE,
    ShapeType.STORAGE_POSITION,
    ShapeType.PROCESS_ONE_EP,
    ShapeType.PROCESS_ONE_EP_POSITION,
  ].includes(type);

export const FLOW_SOURCE_SHAPE_TYPES: ShapeType[] = [
  ShapeType.INTAKE,
  ShapeType.INTAKE_POSITION,
  ShapeType.STORAGE,
  ShapeType.STORAGE_POSITION,
  ShapeType.PROCESS_ONE_EP,
  ShapeType.PROCESS_ONE_EP_POSITION,
];

const FLOW_TARGET_SHAPE_TYPES = {
  [ShapeType.DELIVERY]: ShapeType.DELIVERY,
  [ShapeType.DELIVERY_POSITION]: ShapeType.DELIVERY_POSITION,
  [ShapeType.PROCESS_ONE_EP]: ShapeType.PROCESS_ONE_EP,
  [ShapeType.PROCESS_ONE_EP_POSITION]: ShapeType.PROCESS_ONE_EP_POSITION,
} as const;
export type FlowTargetShapeTypes = ObjectValues<typeof FLOW_TARGET_SHAPE_TYPES>;

export const getValidFlowTargetShapeTypes = (type: ShapeType) => {
  switch (type) {
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
      return [
        ShapeType.DELIVERY,
        ShapeType.DELIVERY_POSITION,
        ShapeType.STORAGE,
        ShapeType.STORAGE_POSITION,
        ShapeType.PROCESS_ONE_EP,
        ShapeType.PROCESS_ONE_EP_POSITION,
      ];
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return [
        ShapeType.DELIVERY,
        ShapeType.DELIVERY_POSITION,
        ShapeType.STORAGE,
        ShapeType.STORAGE_POSITION,
        ShapeType.PROCESS_ONE_EP,
        ShapeType.PROCESS_ONE_EP_POSITION,
      ];
    default:
      break;
  }

  return [];
};
