import { ObjectValues } from '@helpers/types';
import { isArea } from '@modules/common/helpers/shapes';
import { AreaDirection, ShapeType } from '@modules/common/types/shapes';

export const AREA_SHAPE_TYPES = [
  ShapeType.DELIVERY,
  ShapeType.INTAKE,
  ShapeType.PROCESS_ONE_EP,
  ShapeType.CHARGING,
  ShapeType.PARKING,
  ShapeType.OBSTACLE,
  ShapeType.STORAGE,
];

export const POINTS_SHAPE_TYPES = [ShapeType.WALL, ShapeType.HIGHWAY_ANGLED, ShapeType.PROCESS_TWO_EP];

export const ROAD_SHAPE_TYPES = [ShapeType.HIGHWAY, ShapeType.HIGHWAY_ANGLED];

export const AREA_GAP_TYPE = {
  VEHICLE: 'vehicle',
  LOAD_POSITION: 'loadposition',
} as const;
export type AreaGap = ObjectValues<typeof AREA_GAP_TYPE>;

export const AREA_OVERFLOW = {
  CONTAIN: 'contain',
  ALLOW: 'allow',
} as const;
export type AreaOverflow = ObjectValues<typeof AREA_OVERFLOW>;

export const DISTCON_SOURCE_SHAPE_TYPES: string[] = [
  ShapeType.INTAKE,
  ShapeType.INTAKE_POSITION,
  ShapeType.DELIVERY,
  ShapeType.DELIVERY_POSITION,
  ShapeType.STORAGE,
  ShapeType.STORAGE_POSITION,
  ShapeType.PROCESS_ONE_EP,
  ShapeType.PROCESS_ONE_EP_POSITION,
  ShapeType.CHARGING,
  ShapeType.CHARGING_POSITION,
  ShapeType.PARKING,
  ShapeType.PARKING_POSITION,
  ShapeType.HIGHWAY,
  ShapeType.HIGHWAY_ANGLED,
];

export type DistconTargetShapeTypes = 
  | ShapeType.DELIVERY
  | ShapeType.DELIVERY_POSITION
  | ShapeType.INTAKE
  | ShapeType.INTAKE_POSITION
  | ShapeType.STORAGE
  | ShapeType.STORAGE_POSITION
  | ShapeType.PROCESS_ONE_EP
  | ShapeType.PROCESS_ONE_EP_POSITION
  | ShapeType.CHARGING
  | ShapeType.CHARGING_POSITION
  | ShapeType.PARKING
  | ShapeType.PARKING_POSITION
  | ShapeType.HIGHWAY

export const getValidDistconTargetShapeTypes = (type) => {
  if (isArea(type)) {
    return [ShapeType.HIGHWAY, ShapeType.HIGHWAY_ANGLED];
  }
  if (type === ShapeType.HIGHWAY || type === ShapeType.HIGHWAY_ANGLED) {
    return [
      ShapeType.INTAKE,
      ShapeType.INTAKE_POSITION,
      ShapeType.DELIVERY,
      ShapeType.DELIVERY_POSITION,
      ShapeType.STORAGE,
      ShapeType.STORAGE_POSITION,
      ShapeType.PROCESS_ONE_EP,
      ShapeType.PROCESS_ONE_EP_POSITION,
      ShapeType.CHARGING,
      ShapeType.CHARGING_POSITION,
      ShapeType.PARKING,
      ShapeType.PARKING_POSITION,
      ShapeType.HIGHWAY,
      ShapeType.HIGHWAY_ANGLED,
    ];
  }
  return [];
};

export const areaDirectionToAngle = (direction: AreaDirection): number => {
  const dir = direction as unknown as string;

  switch (dir) {
    case AreaDirection.DOWN:
      return 0;
    case AreaDirection.RIGHT:
      return 270;
    case AreaDirection.UP:
      return 180;
    case AreaDirection.LEFT:
      return 90;
    default:
      break;
  }
  return 0;
};

export const MIN_ZOOM_SCALE = 0.01;
export const MAX_ZOOM_SCALE = 100;
export const MAX_ZOOM_SCALE_CLAMPED = 2.5;

export const ZOOM_MULTIPLIER = 1.2;
export const ZOOM_SPACE = 0.1;
