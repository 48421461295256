import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { useCopyConnection, useRemoveConnections, useUpdateConnection } from '../../connections';
import { useCopyCrossings, useRemoveCrossings, useUpdateCrossings } from '../../crossings';
import {
  useCopyDistantConnection,
  useRemoveDistantConnection,
  useUpdateDistantConnection,
} from '../../distant';
import { CopyIdMapping } from '../types';

/**
 * Functions for managing all types of connections
 */
export const useConnections = () => {
  const { copyConnections: copyConnectionsInner } = useCopyConnection();
  const { copyDistantConnection } = useCopyDistantConnection();
  const { copyCrossings } = useCopyCrossings();
  const { updateConnections: updateConnectionsInner } = useUpdateConnection();
  const { updateDistantConnections } = useUpdateDistantConnection();
  const { updateCrossings } = useUpdateCrossings();
  const { removeCrossingsByShapes } = useRemoveCrossings();
  const { removeConnectionsByShapes } = useRemoveConnections();
  const { removeDistantConnectionsByShapes } = useRemoveDistantConnection();

  const copyConnections = useCallback(
    (idMapping: CopyIdMapping) =>
      Promise.all([
        copyConnectionsInner(idMapping),
        copyDistantConnection(idMapping),
        copyCrossings(idMapping),
      ]),
    [copyConnectionsInner, copyDistantConnection, copyCrossings],
  );

  const deleteConnections = useRecoilCallback(
    () =>
      async (shapeIds: string[]) => {
        await removeCrossingsByShapes(shapeIds);
        await removeConnectionsByShapes(shapeIds);
        await removeDistantConnectionsByShapes(shapeIds);
      },
    [removeConnectionsByShapes, removeCrossingsByShapes, removeDistantConnectionsByShapes],
  );

  const updateConnections = useCallback(
    (shapeIds: string[]) =>
      Promise.all([
        updateConnectionsInner(shapeIds),
        updateDistantConnections(shapeIds),
        updateCrossings(shapeIds),
      ]),
    [updateConnectionsInner, updateCrossings, updateDistantConnections],
  );

  return {
    copyConnections,
    deleteConnections,
    updateConnections,
  };
};
