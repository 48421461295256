import type { Duration } from '@/modules/common/helpers/date';

export enum SimulationPanel {
  LIST = 0,
  EDIT = 1,
  GROUP = 2,
}

export enum SimulationStatus {
  DRAFT = 0,
  SCHEDULED = 2,
  RUNNING = 3,
  COMPLETED = 4,
  ABORTED = 5,
  FAILED = 6,
  ABORTING = 7,
  DELETING = 8,
  PREPARING = 9,
  PARTIALLY_COMPLETED = 10,
  SAVING = 11,
}

export enum SimulationType {
  SINGLE = 0,
  GROUP = 1,
}

export type SimulationError = {
  code: string;
  type: SimulationErrorType;
  args: string[];
};

export enum SimulationErrorType {
  GENERAL = 'GENERAL',
  RUNTIME = 'RUNTIME',
  CONFIGURATION = 'CONFIGURATION',
}

export type Simulation = {
  created: Date;
  details: SimulationDetails;
  error?: string;
  errors: SimulationError[];
  floorPlanId: string;
  generatedFloorPlanId?: string;
  id: string;
  isSelected: boolean;
  name: string;
  results?: SimulationResults;
  simulationRunId?: string;
  status: SimulationStatus;
  trafficManagementDisabled: boolean;
  failOnNoRouteFound: boolean;
  type: SimulationType;
};

export type SimulationDetails = {
  vehicleTypes: SimulationDetailsVehicleType[];
  floorPlanVersion?: number;
};

export type SimulationDetailsVehicleType = {
  name: string;
  range: number[];
};

export type SimulationResults = {
  currentTime: Duration;
  endTime: Duration;
  dashboard?: {
    url: string;
    urlExpirationDate: Date;
  };
};

export type SimulationConfiguration = {
  duration: Duration;
  transportWindow: Duration;
  vehicleTypes: VehicleType[];
  flows: Flow[];
  dependentFlows: DependentFlow[];
  checkpointSets: CheckpointSet[];
  trafficManagementDisabled: boolean;
  failOnNoRouteFound: boolean;
};

export type SimulationDraft = {
  name: string;
  duration: Duration;
  transportWindow: Duration; // should always be equal to duration
  vehicleTypes: VehicleType[];
  flows: Flow[];
  dependentFlows: DependentFlow[];
  floorPlanId: string;
  version: number;

  definition?: any;
  dynamicSplines?: boolean;
  generatedFloorPlanId?: string;
  trafficManagementDisabled: boolean;
  failOnNoRouteFound: boolean;
  checkpointSets: CheckpointSet[];
};

export type VehicleType = {
  name: string;
  range: number[];
  loadTime: number;
  unloadTime: number;
  hue: number;
};

export type OrdersPerHour = {
  hour: number; // starting from 1, 2, 3, ...
  loadsCount: number;
};

export type Flow = {
  id: string;
  name: string;
  intakeName: string;
  deliveryName: string;
  ordersPerHours: OrdersPerHour[];
  loadsCount?: number; // should be removed (obsolete) when new Simulation.Api support orderprofile
  vehicleLimit: number;
};

export type DependentFlow = {
  name: string;
  flowFromName: string;
  flowToName: string;
  processingTime: number;
};

export type CheckpointSet = {
  maxVehiclesInSet: number;
  highways: string[];
};
