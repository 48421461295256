import { FloatingPanel } from '@/modules/common/components/FloatingPanel';
import { Grid, Typography } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import { OpenAllFloorplanButton } from './OpenAllFloorplanButton';
import { DownloadFloorplanButton } from './DownloadFloorPlanButton';
import { AutodeskViewer } from './AutodeskViewer';
import { useDebugStore } from '../store/useDebugStore';

export const DEBUG_DESCRIPTION_SIZE = 8;
export const DEBUG_ACTION_SIZE = 4;

export function DebugPanel() {
  const [open, setOpen] = useDebugStore(useShallow((state) => [state.open, state.setOpen]));

  return (
    <FloatingPanel open={open} onClose={() => setOpen(false)} title='Debug menu'>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
          <Typography variant='h6'> Description</Typography>
        </Grid>
        <Grid item xs={DEBUG_ACTION_SIZE}>
          <Typography variant='h6'> Action</Typography>
        </Grid>
        <OpenAllFloorplanButton />
        <DownloadFloorplanButton />
        <AutodeskViewer />
      </Grid>
    </FloatingPanel>
  );
}
