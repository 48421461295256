import { useSetRecoilState } from 'recoil';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { t } from 'i18next';
import { useTypeGuard } from '@/modules/common/hooks/useTypeGuard';

export const useUvtTypeGuard = () => {
  const showNotificationFn = useSetRecoilState(showNotification);
  const { validateKeys } = useTypeGuard();

  const requiredKeys = [
    'id',
    'category',
    'physics',
    'motion',
    'steering',
    'loadHandler',
    'layoutConstraints',
    'cstKollmorgenTemplates',
  ];

  const isValidUvtJson = (uvt: string): boolean => {
    if (!uvt) {
      return false;
    }

    try {
      const parsedJson = JSON.parse(uvt);
      if (!validateKeys(parsedJson.vehicleTypes[0], requiredKeys)) {
        showNotificationFn({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('errors:uvt.type_check'),
        });
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      showNotificationFn({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('errors:uvt.type_check'),
      });
      return false;
    }
  };

  return { isValidUvtJson };
};
