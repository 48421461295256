import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { ShapeType } from '@/modules/common/types/shapes';
import { ToolState } from '@/modules/common/types/tools';
import { SnapCollection } from '@/modules/snapping/types';
import { KEYCODE } from '@/store/recoil/input';
import { drawingIdSelector, shapeType, toolState } from '@/store/recoil/workspace';
import { snappingState } from '../store/state';
import { useSnappingCallbacks } from './useSnappingCallbacks';

export const useSnapping = () => {
  const wsToolState = useRecoilValue(toolState);
  const wsShapeType = useRecoilValue(shapeType);
  const setSnappingShape = useSetRecoilState(snappingState);
  const { getScalingSnapShape, getGuideLinesAsync, getSnapShape } = useSnappingCallbacks();

  const [guideLines, setGuideLines] = useState<SnapCollection[]>([]);
  const previousBoundingBox = useRef(null);

  const initSnappingValues = useRecoilCallback(
    ({ snapshot }) =>
      async (x: number, y: number) => {
        const drawingId = await snapshot.getPromise(drawingIdSelector);
        const guides = await getGuideLinesAsync([drawingId], true);
        setGuideLines(guides);

        previousBoundingBox.current = {
          x,
          y,
          width: 1,
          height: 1,
        };
      },
    [getGuideLinesAsync],
  );

  const canDisplaySnapping = useCallback(
    () =>
      wsToolState === ToolState.DRAW ||
      wsToolState === ToolState.PLACE ||
      wsToolState === ToolState.SELECT ||
      wsShapeType === ShapeType.WALL,
    [wsShapeType, wsToolState],
  );

  if (!canDisplaySnapping()) setSnappingShape(null);

  const removeSnappingShapeOnEscKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.code !== KEYCODE.ESCAPE) return;

      const shouldCancelSnapping = canDisplaySnapping() && guideLines;

      if (shouldCancelSnapping) {
        setSnappingShape(null);
        previousBoundingBox.current = null;
      }
    },
    [canDisplaySnapping, guideLines, setSnappingShape],
  );

  useEffect(() => {
    document.addEventListener('keydown', removeSnappingShapeOnEscKey);

    return () => {
      document.removeEventListener('keydown', removeSnappingShapeOnEscKey);
    };
  }, [removeSnappingShapeOnEscKey]);

  return {
    initSnappingValues,
    getScalingSnapShape,
    getGuideLinesAsync,
    getSnapShape,
    previousBoundingBox,
    setSnappingShape,
    guideLines,
    setGuideLines,
  };
};
