import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import { isUuid, merge } from '@/helpers/utils';
import { useArtefacts } from '@/modules/artefacts';
import { isPointsShape, isProcessAreaTwoEp } from '@/modules/common/types/guards';
import { ShapeGroup } from '@/modules/common/types/shapeGroup';
import { shapeGroupState } from '@/modules/shapeGroups';
import { useShapeGroup } from '@/modules/shapeGroups/hooks/useShapeGroup';
import { addCopy } from '@/store/recoil/floorplan/helper';
import { memoryAtom } from '@/store/recoil/memory';
import { DTShape } from '@/store/recoil/shape';
import shapeAtom from '@/store/recoil/shape/atom';
import { calculateShapesBoundingBox, getControlPointShapeDuplicate } from '@modules/common/helpers/shapes';
import { CopyIdMapping, useConnections, useUndoRedoConnections } from '@modules/connections';
import { useAutoSave } from '@modules/floorplan';
import { HistoryManager } from '@recoil/history';
import { allShapesSelector, shapesSelector } from '@recoil/shapes';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { SelectShapeOperation, useSelectedShape } from './useSelectedShape';

export const COPY_PASTE_OFFSET_DEFAULT = { x: 1000, y: 1000 };

export const useCopyPaste = () => {
  const { createGroupFromShapeIds } = useShapeGroup();
  const { copyConnections } = useConnections();
  const { getUndoRedoState, restoreUndoRedoState } = useUndoRedoConnections();
  const { select } = useSelectedShape();
  const { save } = useAutoSave();
  const { update: updateArtefacts } = useArtefacts();

  const duplicateShape = useCallback((shape: DTShape, offset: Vector2, overrides: any = {}) => {
    if (isPointsShape(shape) || isProcessAreaTwoEp(shape)) {
      return getControlPointShapeDuplicate(shape, offset);
    }

    return merge(
      {
        ...shape,
        name: addCopy(shape),
        id: uuid(),
        properties: {
          ...shape.properties,
          x: shape.properties.x + offset.x,
          y: shape.properties.y + offset.y,
        },
      },
      overrides,
    );
  }, []);

  const createShapeCopy = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        originalIds: string[],
        selectAfterCreation = true,
        offset: { x: number; y: number } = COPY_PASTE_OFFSET_DEFAULT,
        overrides: any = {},
      ): Promise<DTShape[]> => {
        const originShapes = await snapshot.getPromise(shapesSelector(originalIds));
        const newShapes: DTShape[] = [];
        const idMapping: CopyIdMapping = [];

        originShapes.forEach((originShape) => {
          const newShape = duplicateShape(originShape, new Vector2(offset.x, offset.y), overrides);
          idMapping.push({ newShapeId: newShape.id, oldShapeId: originShape.id });
          newShapes.push(newShape);
        });

        set(allShapesSelector, (shapes) => [...shapes, ...newShapes]);
        if (selectAfterCreation) {
          await select(
            SelectShapeOperation.OVERWRITE,
            idMapping.map((item) => item.newShapeId),
          );
        }

        await copyConnections(idMapping);
        updateArtefacts(newShapes.map((item) => item.id));
        return newShapes;
      },
    [duplicateShape, select, copyConnections, updateArtefacts],
  );

  const createShapeGroupCopy = useRecoilCallback(
    ({ snapshot }) =>
      async (
        originalGroupIds: string[],
        selectAfterCreation = true,
        offset: { x: number; y: number } = COPY_PASTE_OFFSET_DEFAULT,
      ): Promise<ShapeGroup[]> => {
        if (originalGroupIds.length === 0) return null;

        const originalGroups = await Promise.all(
          originalGroupIds.map((groupId) => snapshot.getPromise(shapeGroupState(groupId))),
        );
        const groupOfDuplicatedShapes = await Promise.all(
          originalGroups.map((group) => createShapeCopy(group.children, false, offset)),
        );
        const newShapeGroups = await Promise.all(
          groupOfDuplicatedShapes.map((values) => {
            const shapes = values as DTShape[];
            const shapeIds = shapes.map((shape) => shape.id);
            return createGroupFromShapeIds(shapes[0].type, shapeIds);
          }),
        );

        if (selectAfterCreation) {
          const newShapes = groupOfDuplicatedShapes.flat();
          const newShapeIds = newShapes.map((shape) => shape.id);
          select(SelectShapeOperation.OVERWRITE, newShapeIds);
        }

        return newShapeGroups;
      },
    [duplicateShape, createShapeCopy, createGroupFromShapeIds, select],
  );

  const copyToMemory = useRecoilCallback(
    ({ set }) =>
      (selectedShapesIdsState: any) => {
        set(memoryAtom, { shapeIds: selectedShapesIdsState });
      },
    [],
  );

  const copyToClipboard = useCallback((selectedShapesIds: any) => {
    navigator.clipboard.writeText(JSON.stringify(selectedShapesIds));
  }, []);

  const pasteFromMemory = useRecoilCallback(
    ({ snapshot, set }) =>
      async (canvasDestination: null | { x: number; y: number } = null) => {
        const memoryState = await snapshot.getPromise(memoryAtom);
        let offset = COPY_PASTE_OFFSET_DEFAULT;

        if (canvasDestination) {
          const shapes = await snapshot.getPromise(shapesSelector(memoryState.shapeIds));
          const origin = calculateShapesBoundingBox(shapes);
          offset = { x: canvasDestination.x - origin.x, y: canvasDestination.y - origin.y };
        }

        const pastedShapes = await createShapeCopy(memoryState.shapeIds, true, offset);
        const allShapes = await snapshot.getPromise(allShapesSelector);
        await save();

        const { newConnectionState, oldConnectionState } = await getUndoRedoState(
          pastedShapes.map((item) => item.id),
        );

        HistoryManager.track(
          `Duplicated shape`,
          { shapes: [...allShapes, ...pastedShapes], connections: oldConnectionState },
          { shapes: allShapes, connections: newConnectionState },
          ({ shapes, connections }) => {
            set(allShapesSelector, shapes);
            set(selectedShapesIdsState, (current) => (current.length === 0 ? current : []));
            restoreUndoRedoState(connections);
          },
        );
      },
    [createShapeCopy, save, getUndoRedoState, restoreUndoRedoState],
  );

  const pasteFromClipboard = useCallback(() => {
    navigator.clipboard.readText().then((text) => {
      try {
        const shapeIds = JSON.parse(text);
        // Check data type and structure and if it's a uuid
        if (Array.isArray(shapeIds) && shapeIds.length > 0 && isUuid(shapeIds[0])) {
          createShapeCopy(shapeIds);
        }
      } catch (e) {
        console.log('clipboard content not compatible');
      }
    });
  }, [createShapeCopy]);

  const swapShapeProperties = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id1: string, id2: string) => {
        const shape1 = await snapshot.getPromise(shapeAtom(id1));
        const shape2 = await snapshot.getPromise(shapeAtom(id2));

        set(shapeAtom(id1), { ...shape1, properties: shape2.properties });
        set(shapeAtom(id2), { ...shape2, properties: shape1.properties });
      },
    [],
  );

  return {
    duplicateShape,
    createShapeCopy,
    createShapeGroupCopy,
    copyToMemory,
    pasteFromMemory,
    copyToClipboard,
    pasteFromClipboard,
    swapShapeProperties,
  };
};
