import { Connection } from '@modules/common/types/connections';
import {
  AdditionalData,
  DTShapePersisted,
  Connection as FloorPlanConnection,
} from '@modules/common/types/floorPlan';
import { DTShape } from '@modules/common/types/shapes';
import { sortConnections } from '../../common/helpers';
import { findConnectionPosition } from './connections';

/**
 * Prepares connections state to be loaded into the app.
 */
export const prepareToLoad = (additionalData: AdditionalData): Connection[] => {
  const shapes = new Map<string, DTShapePersisted>();
  additionalData.shapes.forEach((item) => shapes.set(item.id, item));

  const newConnection: Connection[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (let connection of additionalData.connections) {
    const fromShape = shapes.get(connection.from);
    const toShape = shapes.get(connection.to);
    if (!fromShape?.properties || !toShape?.properties) continue;

    const fromShapeDT: DTShape = {
      ...fromShape,
      isDrawing: false,
      isTransforming: false,
      isLoading: false,
      disabled: false,
    };

    const toShapeDT: DTShape = {
      ...toShape,
      isDrawing: false,
      isTransforming: false,
      isLoading: false,
      disabled: false,
    };

    const position = findConnectionPosition(fromShapeDT, toShapeDT);
    if (!position) continue;

    newConnection.push({
      ...connection,
      ...position,
      usePivots: !!connection.usePivots,
    });
  }

  return newConnection;
};

/**
 * Prepares connections state to be saved
 */
export const prepareToSave = (connection: Connection[]): FloorPlanConnection[] => {
  const connections = connection.map((distantConnection) => ({
    from: distantConnection.from.split('.')[0],
    to: distantConnection.to.split('.')[0],
    inChecked: distantConnection.inChecked,
    outChecked: distantConnection.outChecked,
    usePivots: distantConnection.usePivots,
    id: distantConnection.id,
  }));

  return sortConnections(connections);
};
