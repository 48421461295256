import { AppSpecificShapeProperties, ShapeProperties } from '../types/shapes';

export const HIGHWAY_ROUTING_POINT_GROUP_MIN_GAP_DEFAULT = 500;
export const HIGHWAY_ROUTING_POINT_GROUP_MIN_GAP_MIN = 0;

export const HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_DEFAULT = 100;
export const HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_MIN = 0;

export const HIGHWAY_VEHICLE_LIMIT_DEFAULT = -1;

export const HIGHWAY_DEFAULT_GAP = 500;
export const HIGHWAY_DEFAULT_MARGIN = 500;
export const HIGHWAY_MIN_GAP = 50;
export const HIGHWAY_MIN_MARGIN = 50;
export const ANGLED_HIGHWAY_DEFAULT_WIDTH = 4000;
export const ANGLED_HIGHWAY_MIN_WIDTH = 1000;
export const WALL_MIN_WIDTH = 50
export const PROCESS_MIN_WIDTH = 1000

export const APP_SPECIFIC_SHAPE_PROPERTIES_DEFAULT: AppSpecificShapeProperties = {
  isDrawing: false,
  isLoading: false,
  isTransforming: false,
  disabled: false,
};

export const SHAPE_PROPERTIES_DEFAULT: ShapeProperties = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  r: 0,
};
