import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import MapIcon from '@mui/icons-material/Map';
import AreaIcon from './icons/AreaIcon.tsx';
import ChargeIcon from './icons/ChargeIcon';
import ChargingPositionIcon from './icons/ChargingPositionIcon.tsx';
import CursorIcon from './icons/CursorIcon';
import DeliveryPositionIcon from './icons/DeliveryPositionIcon.tsx';
import FlowIcon from './icons/FlowIcon';
import HandIcon from './icons/HandIcon';
import HandoverIcon from './icons/HandoverIcon';
import HighwayIcon from './icons/HighwayIcon';
import IntakeIcon from './icons/IntakeIcon';
import IntakePositionIcon from './icons/IntakePositionIcon.tsx';
import LinkIcon from './icons/LinkIcon';
import ObstracleIcon from './icons/ObstracleIcon';
import ParkingIcon from './icons/ParkingIcon';
import PositionIcon from './icons/PositionIcon.tsx';
import ProcessIcon from './icons/ProcessIcon';
import ReferenceIcon from './icons/ReferenceIcon';
import StackedLineChart from './icons/StackedLineChart';
import StoragePositionIcon from './icons/StoragePositionIcon.tsx';
import WallIcon from './icons/WallIcon';
import WallMenuIcon from './icons/WallMenuIcon';

const iconsMap = {
  cursor: <CursorIcon />,
  pan: <HandIcon />,
  dwg: <ReferenceIcon />,
  area: <AreaIcon />,
  'parking': <ParkingIcon />,
  'process': <ProcessIcon />,
  process_two_end_point: <ProcessIcon />,
  'handover': <HandoverIcon />,
  wall: <WallIcon />,
  obstacle: <ObstracleIcon />,
  object: <DataObjectIcon />,
  'agv bound': <DeliveryDiningIcon />,
  intake: <IntakeIcon />,
  storage: <StoragePositionIcon />,
  delivery: <DeliveryPositionIcon />,
  charging: <ChargeIcon />,
  'two-way': <CompareArrowsIcon />,
  'one-way': <ArrowRightAltIcon />,
  'man map': <MapIcon />,
  wallmenu: <WallMenuIcon />,
  highway: <HighwayIcon />,
  highway_angled: <StackedLineChart />,
  position: <PositionIcon />,
  intake_position: <IntakePositionIcon />,
  storage_position: <StoragePositionIcon />,
  delivery_position: <DeliveryPositionIcon />,
  charging_position: <ChargingPositionIcon />,
  flow: <FlowIcon />,
  distcon: <LinkIcon />,
};

const getIcon = (type) => iconsMap[type];

export default getIcon;
