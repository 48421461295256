import type { } from '@mui/lab/themeAugmentation';
import {
  createTheme,
  filledInputClasses,
  formControlClasses,
  inputAdornmentClasses,
  inputBaseClasses,
  paperClasses,
  typographyClasses,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import type { } from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: {
      main: string;
      light: string;
      lighter: string;
      dark: string;
      darker: string;
      darkest: string;
      grey: string;
      greyLight: string;
    };
    shades: {
      main: string;
      light: string;
      dark: string;
      shadow: string;
    };
    others: {
      medium: string;
      lighter: string;
      greenLight: string;
      orange: string;
      red: string;
    };
    status: {
      scheduled: string;
      scheduledLight: string;
      running: string;
      preparing: string;
    };
    areas: {
      intakeBg: string;
      intakeVehicle: string;
      intakeCarrier: string;
      delivery: string;
      deliveryVehicle: string;
      deliveryCarrier: string;
      parking: string;
      parkingVehicle: string;
      charging: string;
      chargingVehicle: string;
      storage: string;
      storageVehicle: string;
      storageCarrier: string;
      process: string;
      processVehicle: string;
      processCarrier: string;
      handover: string;
      handoverVehicle: string;
      handoverCarrier: string;
      highway: string;
      highwayVehicle: string;
      obstacle: string;
      wall: string;
      laneColor: string;
    };
    disabled: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: {
      main?: string;
      light?: string;
      lighter?: string;
      dark?: string;
      darker?: string;
      grey?: string;
      greyLight?: string;
    };
    shades?: {
      main?: string;
      light?: string;
      dark?: string;
      shadow?: string;
    };
    others?: {
      medium?: string;
      lighter?: string;
      greenLight?: string;
      orange?: string;
      red?: string;
    };
    status: {
      scheduled?: string;
      scheduledLight?: string;
      running?: string;
      preparing?: string;
    };
    areas: {
      intakeBg?: string;
      intakeVehicle?: string;
      intakeCarrier?: string;
      delivery?: string;
      deliveryVehicle?: string;
      deliveryCarrier?: string;
      parking?: string;
      parkingVehicle?: string;
      charging?: string;
      chargingVehicle?: string;
      storage?: string;
      storageVehicle?: string;
      storageCarrier?: string;
      process?: string;
      processVehicle?: string;
      processCarrier?: string;
      highway?: string;
      highwayVehicle?: string;
      obstacle?: string;
      wall?: string;
      laneColor?: string;
    };
    disabled?: PaletteOptions['primary'];
  }
}

const theme_palette = {
  palette: {
    primary: {
      main: '#2537F3',
      dark: '#0A0AA1',
      light: '#DFE5FC',
    },
    secondary: {
      main: '#F2A92C',
    },
    error: {
      main: '#D13A2F',
      light: '#FDF7F6',
    },
    warning: {
      main: '#FF7714',
      light: '#FFFBF5',
    },
    info: {
      main: '#59AFEF',
      light: '#F6FBFE',
    },
    success: {
      main: '#5B9B4F',
      light: '#F8FCF8',
    },
    neutral: {
      main: '#848484',
      light: '#C4C4C4',
      lighter: '#E8E8E8',
      dark: '#666666',
      darker: '#222222',
      darkest: '#1C1B1F',
      grey: '#F0F0F0',
      greyLight: '#F7F7F7',
    },
    disabled: {
      main: '#ABABAB',
    },
    shades: {
      main: 'rgba(0, 0, 0, 0.5)',
      light: '#FFFFFF',
      dark: '#000000',
      shadow: '#00000022',
    },
    others: {
      medium: '#99AFFF',
      lighter: '#F8F8FC',
      greenLight: '#38ff00',
      orange: '#E7AC3B',
      red: '#DF5A39',
    },
    status: {
      scheduled: '#59AFEF',
      scheduledLight: '#F6FBFE',
      running: '#5E6BF0',
      preparing: '#EF89D9',
    },
    areas: {
      intakeBg: '#0099FF',
      intakeVehicle: '#0099FF',
      intakeCarrier: '#0099FF',
      delivery: '#ffc107',
      deliveryVehicle: '#ffc107',
      deliveryCarrier: '#ffc107',
      parking: '#CEDDD0',
      parkingVehicle: '#CEDDD0',
      charging: '#777777',
      chargingVehicle: '#777777',
      storage: '#8bc34a',
      storageVehicle: '#8bc34a',
      storageCarrier: '#8bc34a',
      process: '#FED5A4',
      processVehicle: '#FEA42C',
      processCarrier: '#F37C3E',
      handover: '#C6EEF1',
      handoverVehicle: '#6BC3C9',
      handoverCarrier: '#6BC3C9',
      highway: '#D875FD',
      highwayVehicle: '#D875FD',
      obstacle: '#222222',
      wall: '#111111',
      laneColor: '#0000001A',
    },
  },
};

const theme_Typo = {
  typography: {
    fontFamily: 'Azeret Mono',
    fontSize: 10,
    allVariants: {
      color: theme_palette.palette.neutral.darker,
      fontFamily: 'Azeret Mono',
    },
    h1: {
      fontWeight: 600,
      fontSize: '72px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '32px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '18px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '14px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '12px',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '18px',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '18px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
    },
    button: {
      fontWeight: 500,
      fontSize: '12px',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 300,
      fontSize: '8px',
      color: theme_palette.palette.neutral.main,
    },
  },
};

const theme_base = createTheme(deepmerge(theme_palette, theme_Typo));

export const layerTheme = createTheme(
  deepmerge(theme_palette, {
    typography: {
      fontFamily: theme_Typo.typography.fontFamily,
      fontSize: 12,
      lineHeight: 24,
    },
    palette: {
      primary: {
        main: '#1C1B1F',
        light: theme_palette.palette.neutral.main,
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            height: '40px',
            paddingLeft: '8px',
            paddingRight: '12px',
            paddingTop: '0px',
            paddingBottom: '0px',
            border: '1px solid',
            '&:hover': {
              border: '1px solid',
              borderColor: theme_palette.palette.primary.main,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            [`& .${inputBaseClasses.root}`]: {
              backgroundColor: theme_base.palette.others.lighter,
              border: '1px solid transparent',
              borderRadius: 0,
              height: '36px',
              padding: '8px',
              boxSizing: 'border-box',
              fontSize: '12px',
            },

            [`& .${filledInputClasses.root}.${inputBaseClasses.root}.Mui-error`]: {
              borderColor: theme_base.palette.error.main,
            },

            [`& .${filledInputClasses.root}.${inputBaseClasses.root}.Mui-disabled`]: {
              backgroundColor: theme_base.palette.others.lighter,

              [`& .${inputAdornmentClasses.root} .${typographyClasses.root}`]: {
                color: theme_base.palette.disabled.main,
              },
            },

            [`& .${inputBaseClasses.root}:hover`]: {
              backgroundColor: theme_base.palette.others.lighter,
            },

            [`& .${filledInputClasses.focused}.${inputBaseClasses.focused}`]: {
              backgroundColor: theme_base.palette.others.lighter,
              borderColor: theme_base.palette.primary.main,

              [`.${filledInputClasses.input}`]: {
                backgroundColor: theme_base.palette.others.lighter,
                color: theme_base.palette.neutral.darker,
              },
            },

            [`& .${filledInputClasses.input}`]: {
              color: theme_base.palette.neutral.dark,
              fontSize: '12px',
              padding: 0,
            },

            [`& .${inputAdornmentClasses.root} .${typographyClasses.root}`]: {
              fontSize: '12px',
            },
          },
        },
      },
    },
  }),
);

export const groupsAccordionTheme = createTheme(
  deepmerge(theme_palette, {
    typography: {
      fontFamily: theme_Typo.typography.fontFamily,
      fontSize: 12,
      lineHeight: 24,
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            height: '40px',
            paddingLeft: '4px',
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid transparent',
            '&:hover': {
              borderBottomColor: theme_palette.palette.primary.main,
            },
          },
        },
      },
    },
  }),
);

export const toolbarTheme = createTheme(
  deepmerge(theme_palette, {
    typography: {
      fontFamily: theme_Typo.typography.fontFamily,
    },
    components: {
      MuiList: {
        styleOverrides: {
          root: {
            width: '48px',
            padding: 0,
            backgroundColor: theme_palette.palette.primary.main,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& .MuiDrawer-paper': {
              backgroundColor: theme_palette.palette.primary.main,
              color: theme_palette.palette.primary.main,
              width: '48px',
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { type: 'Selected' },
            style: {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },
          } as any,
          {
            props: { type: 'Deselected' },
            style: {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.neutral.darker,
            },
          } as any,
        ],
        styleOverrides: {
          root: {
            width: '100%',
            padding: '10px',
            height: '40px',
            justifyContent: 'start',
            backgroundColor: theme_palette.palette.shades.light,
            textTransform: 'none',
            fontFamily: 'Azeret Mono',
            fontWeight: 400,
            lineHeight: '21px',
            fontSize: '14px',
            color: theme_palette.palette.neutral.darker,
            borderRadius: '4px',
            '& span:not(.MuiButton-startIcon)': {
              padding: '8px 2px 8px 24px',
              textTransform: 'none',
            },
            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },
            '&:disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
          },
        },
      },
      MuiIconButton: {
        variants: [
          {
            props: { type: 'Selected' },
            style: {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
              '&:active, &:focus, &.active': {
                color: theme_palette.palette.primary.dark,
                backgroundColor: theme_palette.palette.primary.light,
              },
              '&:hover': {
                color: theme_palette.palette.neutral.darker,
                backgroundColor: theme_palette.palette.neutral.greyLight,
              },
            },
          } as any,
          {
            props: { type: 'Deselected' },
            style: {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.neutral.darker,
              '&:hover': {
                color: theme_palette.palette.neutral.darker,
                backgroundColor: theme_palette.palette.neutral.greyLight,
              },
            },
          } as any,
        ],
        styleOverrides: {
          root: {
            backgroundColor: theme_palette.palette.shades.light,
            color: theme_palette.palette.neutral.darker,
            fontSize: '24px',
            margin: '4px',
            borderRadius: '4px',
            border: 'none',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            stroke: theme_palette.palette.shades.light,
            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },
            '&:disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
            '&.submenu-open': {
              color: theme_palette.palette.shades.light,
              backgroundColor: theme_palette.palette.primary.main,
            },
          },
        },
      },
    },
  }),
);

export const propertiesPanelTheme = createTheme(
  deepmerge(theme_palette, {
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiTreeView: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
          label: {
            fontSize: '14px',
            textTransform: 'capitalize',
          },
        },
      },
    },
  }),
);

export const contextMenuTheme = createTheme(
  deepmerge(theme_palette, {
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 13,
            fontFamily: 'Azeret Mono',

            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },

            '&:active': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },

            '&disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
            '&.Mui-selected': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },
          },
        },
      },
    },
  }),
);

export const moreMenuTheme = createTheme(
  deepmerge(theme_palette, {
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '10px',
            fontFamily: 'Azeret Mono',
            color: theme_palette.palette.neutral.darker,
            minWidth: '240px',
            paddingTop: '10px',
            borderRadius: '4px',

            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },

            '&:active': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },

            '&disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },

            '&& svg': {
              marginRight: '18px',
            },
            '&.Mui-selected': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
            color: theme_palette.palette.neutral.darker,
            '&:active, &:focus, &.active': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },
            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },
            '&:disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
          },
        },
      },
    },
  }),
);

export const zoomTheme = createTheme(
  deepmerge(theme_palette, {
    typography: {
      fontFamily: theme_Typo.typography.fontFamily,
      fontSize: 14,
    },
    components: {
      MuiButton: {
        variants: [],
        styleOverrides: {
          root: {
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 300,
            color: theme_palette.palette.neutral.darker,
            '&.reset-zoom-btn': {
              width: '45px',
              height: '32px',
            },
            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },
            '&:disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: theme_palette.palette.shades.light,
            color: 'currentColor',
            fontSize: '24px',
            padding: 0,
            lineHeight: '21px',
            weight: 400,
            borderRadius: '4px',
            '& .MuiSvgIcon-root': {
              height: '24px',
              width: '24px',
            },
            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },
            '&:disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
            '&.zoom-to-fit-btn, &.zoom-in-icon-btn, &.zoom-out-icon-btn': {
              '& .MuiSvgIcon-root': {
                fill: 'currentColor',
              },
              width: '24px',
              height: '24px',
            },
            '&.visualize-selected-btn': {
              '& .MuiSvgIcon-root': {
                color: theme_palette.palette.primary.dark,
                backgroundColor: theme_palette.palette.primary.light,
                borderRadius: '4px',
              },
            },
          },
        },
      },
    },
  }),
);

export const setupPropertyPanelTheme = createTheme(
  deepmerge(theme_palette, {
    typography: {
      fontFamily: theme_Typo.typography.fontFamily,
      fontSize: 14,
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: theme_palette.palette.shades.light,
            color: 'currentColor',
            fontSize: '24px',
            padding: '16px',
            lineHeight: '21px',
            weight: 400,
            borderRadius: '4px',
            '& .MuiSvgIcon-root': {
              height: '24px',
              width: '24px',
            },
            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },
            '&:disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
          },
        },
      },
    },
  }),
);

export const theme = createTheme(
  deepmerge(theme_base, {
    ...theme_base,
    components: {
      MuiAccordion: {
        defaultProps: {
          disableGutters: true,
          square: true,
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',

            '&:before': {
              display: 'none',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,

            [`& .${typographyClasses.root}`]: {
              fontSize: '14px',
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: '10px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            color: theme_base.palette.neutral.lighter,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'primary',
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              color: theme_base.palette.primary.main,
              backgroundColor: theme_base.palette.shades.light,
            },
          },
          {
            props: { variant: 'secondary' },
            style: {
              color: theme_base.palette.shades.light,
              backgroundColor: theme_base.palette.primary.main,
            },
          },
          {
            props: { variant: 'text' },
            style: {
              border: 'none',
              color: theme_base.palette.neutral.main,
              backgroundColor: 'transparent',
            },
          },
        ],
        styleOverrides: {
          root: {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: 0,
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '24px',
            padding: '3px 18px',

            '&:hover': {
              borderColor: theme_base.palette.primary.dark,
              backgroundColor: theme_base.palette.primary.dark,
              color: theme_base.palette.shades.light,
            },
            '&:active': {
              borderColor: theme_base.palette.primary.dark,
              backgroundColor: theme_base.palette.primary.dark,
              color: theme_base.palette.shades.light,
            },
            '&:disabled': {
              borderColor: theme_base.palette.disabled.main,
              backgroundColor: theme_base.palette.disabled.main,
              color: theme_base.palette.shades.light,
            },
          },
          text: {
            '&:hover': {
              borderColor: theme_base.palette.primary.dark,
              backgroundColor: theme_base.palette.primary.dark,
              color: theme_base.palette.shades.light,
            },
            '&:disabled': {
              borderColor: theme_base.palette.disabled.main,
              backgroundColor: theme_base.palette.shades.light,
              color: theme_base.palette.disabled.main,

              '&.validationMsg .MuiTypography-root': {
                color: theme_base.palette.neutral.dark,
              },
            },
            '&.validationMsg': {
              '&:hover .MuiTypography-root': {
                color: theme_base.palette.primary.dark,
              },
              '& .MuiTypography-root': {
                color: theme_base.palette.primary.main,
              },
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderRadius: 0,

            '&.Mui-error': {
              borderColor: theme_base.palette.error.main,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            [`&.${paperClasses.root}`]: {
              borderRadius: 0,
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '16px',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
            color: theme_palette.palette.neutral.darker,
            '&:active, &:focus, &.active': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },
            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },
            '&:disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
            '& .MuiSvgIcon-root': {
              height: '24px',
              width: '24px',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            alignItems: 'baseline',
            color: theme_base.palette.shades.main,
            display: 'flex',

            [`& > .${formControlClasses.root}`]: {
              marginLeft: '1em',
            },

            [`& > .${inputBaseClasses.root}`]: {
              marginLeft: '1em',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontFamily: 'Azeret Mono',
            color: theme_palette.palette.neutral.darker,

            '&:hover': {
              color: theme_palette.palette.neutral.darker,
              backgroundColor: theme_palette.palette.neutral.greyLight,
            },

            '&:active': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,
            },

            '&disabled': {
              backgroundColor: theme_palette.palette.shades.light,
              color: theme_palette.palette.disabled.main,
            },
            '&.Mui-selected': {
              color: theme_palette.palette.primary.dark,
              backgroundColor: theme_palette.palette.primary.light,

              '&:hover': {
                color: theme_palette.palette.primary.dark,
                backgroundColor: theme_palette.palette.primary.light,
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: theme_base.palette.neutral.dark,
            fontSize: '14px',
            fontWeight: 400,
            padding: 0,
            minWidth: 'auto',

            '&:hover': {
              color: theme_base.palette.neutral.darker,
              fontWeight: 600,
            },
            '&.Mui-selected': {
              color: theme_base.palette.neutral.darker,
              fontWeight: 600,
              borderBottom: '4px solid transparent',
              borderTop: '4px solid transparent',

              '&:hover': {
                borderBottomColor: theme_base.palette.neutral.darker,
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            gap: '28px',
            justifyContent: 'space-between',
          },
          indicator: {
            backgroundColor: theme_base.palette.neutral.darker,
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error, &.Mui-error.Mui-focused': {
              backgroundColor: theme_base.palette.error.main,
              color: theme_base.palette.shades.light,
              margin: 0,
              zIndex: 2,
              fontSize: '10px',
              padding: '2px 5px',

              '&.Mui-error-floating': {
                top: '100%',
                left: 0,
                position: 'absolute',
                right: 0,
                fontSize: '10px',
                backgroundColor: theme_base.palette.error.main,
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            [`& .${inputBaseClasses.root}`]: {
              backgroundColor: theme_base.palette.others.lighter,
              border: '1px solid transparent',
              borderRadius: 0,
              height: '36px',
              padding: '8px',
              boxSizing: 'border-box',
              fontSize: '12px',
            },

            [`& .${filledInputClasses.root}.${inputBaseClasses.root}.Mui-error`]: {
              borderColor: theme_base.palette.error.main,
            },

            [`& .${filledInputClasses.root}.${inputBaseClasses.root}.Mui-disabled`]: {
              backgroundColor: theme_base.palette.others.lighter,

              [`& .${inputAdornmentClasses.root} .${typographyClasses.root}`]: {
                color: theme_base.palette.disabled.main,
              },
            },

            [`& .${inputBaseClasses.root}:hover`]: {
              backgroundColor: theme_base.palette.others.lighter,
            },

            [`& .${filledInputClasses.focused}.${inputBaseClasses.focused}`]: {
              backgroundColor: theme_base.palette.others.lighter,
              borderColor: theme_base.palette.primary.main,

              [`.${filledInputClasses.input}`]: {
                backgroundColor: theme_base.palette.others.lighter,
                color: theme_base.palette.neutral.darker,
              },
            },

            [`& .${filledInputClasses.input}`]: {
              color: theme_base.palette.neutral.dark,
              fontSize: '12px',
              padding: 0,
            },

            [`& .${inputAdornmentClasses.root} .${typographyClasses.root}`]: {
              fontSize: '12px',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&.MuiSelect-root': {
              order: `1px solid ${theme_base.palette.others.lighter}`,
              borderRadius: 0,
              fontSize: '11px',

              [`&.Mui-error`]: {
                borderColor: theme_base.palette.error.main,
              },
            },
            select: {
              backgroundColor: theme_base.palette.others.lighter,
              border: '1px solid transparent',
              color: theme_base.palette.neutral.dark,
              fontSize: '12px',
              padding: '8px 12px',
              boxSizing: 'border-box',
              height: '36px',
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            display: 'flex',
            backgroundColor: theme_base.palette.others.lighter,
            color: theme_base.palette.neutral.darker,
            borderRadius: 0,
            padding: '5px',
            height: '36px',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: 0,
            color: theme_base.palette.neutral.darker,
            flex: '1',
            fontSize: '12px',
            fontWeight: 500,

            '&:hover': {
              backgroundColor: 'transparent',
              color: theme_base.palette.neutral.darker,
            },

            '&.Mui-selected': {
              backgroundColor: theme_base.palette.shades.light,
              color: theme_base.palette.primary.dark,
            },

            '&.Mui-selected:hover': {
              backgroundColor: theme_base.palette.shades.light,
              color: theme_base.palette.neutral.darker,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            display: 'flex',
            backgroundColor: theme_base.palette.others.lighter,
            border: '1px solid transparent',
            borderRadius: 0,
            height: '36px',
            padding: '8px 12px',
            boxSizing: 'border-box',
            fontSize: '12px',
          },
        },
      },
      MuiTreeView: {
        styleOverrides: {
          root: {
            fontSize: '12px',
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          root: {
            fontSize: '12px',
          },
          label: {
            fontSize: '12px',
          },
        },
      },
    },
  }),
);
