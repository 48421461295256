import { FormControlLabel, Switch } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { useSimulationDraftCallbacks } from '@/modules/simulation/hooks';
import { failOnNoRouteFoundSelector } from '../../store/draft';

export const FailOnNoRouteFoundToggle = () => {
  const failOnNoRouteFound = useRecoilValue(failOnNoRouteFoundSelector);
  const { updateDraftSimulation } = useSimulationDraftCallbacks();

  const onToggleChange = useCallback(
    (e) => {
      updateDraftSimulation((state) => ({
        ...state,
        failOnNoRouteFound: !e.target.checked,
      }));
    },
    [updateDraftSimulation],
  );

  return (
    <Stack sx={{ pr: 2 }}>
      <FormControlLabel
        labelPlacement='start'
        sx={{ marginLeft: 0 }}
        control={
          <Switch
            value={failOnNoRouteFound}
            sx={{ marginLeft: 'auto' }}
            size='small'
            onChange={onToggleChange}
            checked={failOnNoRouteFound}
          />
        }
        label='Fail on no route found'
      />
    </Stack>
  );
};
